import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'paw-checkbox-control',
  templateUrl: './paw-checkbox-control.component.html',
  styleUrls: ['./paw-checkbox-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PawCheckboxControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PawCheckboxControlComponent,
      multi: true
    }
  ]
})
export class PawCheckboxControlComponent implements ControlValueAccessor, OnInit {
  @Input() questionId!: number;
  @Input() value = 0;
  @Input() title!: string;

  inverseAnswers = [5, 6, 7, 8, 9, 10, 13]; // ids of questions that needs to be inverted

  paws = [
    { text: 'trifft nicht zu', value: 1 },
    { text: 'trifft wenig zu', value: 2 },
    { text: 'weder noch', value: 3 },
    { text: 'trifft etwas zu', value: 4 },
    { text: 'trifft voll zu', value: 5 },
  ];
  pawColors = [
    { bgr: '#F6BFC2', border: '#FF6666'},
    { bgr: '#FFDAC0', border: '#FF8C3F'},
    { bgr: ' #FFF5DA', border: '#FED66B'},
    { bgr: '#E8E3AC', border: '#CEC34D'},
    { bgr: '#C3E1C7', border: '#669966'},
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.inverseAnswers.includes(this.questionId)) {
      this.paws = [
        { text: 'trifft nicht zu', value: 5 },
        { text: 'trifft wenig zu', value: 4 },
        { text: 'weder noch', value: 3 },
        { text: 'trifft etwas zu', value: 2 },
        { text: 'trifft voll zu', value: 1 },
      ];
    }
  }

  propagateChange = (_: any) => { };

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
   }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void { }

  public selectAnswer(value: number): void {
    this.value = value;
    this.propagateChange(this.value);
  }

  validate({value}: FormControl): any {
    return this.value < 1 && { inavlid: true };
  }
}
