<div class="">
    <h1 *ngIf="title" class="font-color-1 dog-progress-title font-poppins mb3">{{title}}</h1>

    <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="value-label mt-2 font-weight-medium">{{valueLabel}}</h3>
        <h3 class="value-percentage mb-0">{{value}}%</h3>
    </div>

    <div class="progress-container position-relative">
        <div class="progress-bar d-flex position-relative">
            <div class="progress" [style.width]="value + '%'"></div>
            <img class="progress-indicator" [ngStyle]="{'left': 'calc(' +value+ '% - 25px)'}"
                src="assets/img/progress-dog.png" />
        </div>
    </div>
</div>