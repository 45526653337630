<div class="custom-modal">
    <div class="cutsom-modal-header">
        <h4 class="m-0">{{data.title | translate}}</h4>
        <div class="close-btn">
            <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g id="close" transform="translate(-1265 -197)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(1265 197)" fill="#fed66b"/>
                    <path id="Path_1" data-name="Path 1" d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z" transform="translate(1278 209.984)" fill="#326291"/>
                </g>
            </svg>
        </div>
    </div>
    <div class="custom-modal-body">
        <p class="sub-text" *ngIf="data.revokeShare; else onlyMsg">{{data.message | translate : { dogName : data.dogName, sharedPersonName: data.sharedPersonName} }}</p>
        <ng-template #onlyMsg>
            <p class="sub-text">{{data.message | translate}}</p>
        </ng-template>
        <p class="sub-text" *ngIf="data.secondMessage">{{data.secondMessage | translate }}</p>
        <div class="row justify-content-center mt-4">
            <div class="col-auto">
                <button class="mb-3 px-18-btn btn-border-blue min-width-200-sm" (click)="onCancel()">
                    {{data.cancel | translate}}
                </button>
            </div>
            <div class="col-auto" *ngIf="data.secondarySubmit">
                <button class="theme-primary-btn mb-3 px-18-btn min-width-200-sm" (click)="onSecondaryAction()">
                    {{data.secondarySubmit | translate}}
                </button>
            </div>
            <div class="col-auto">
                <button class="theme-secondary-btn px-18-btn min-width-200-sm" (click)="onAction()">
                    {{data.submit | translate}}
                </button>
            </div>
        </div>
    </div>
</div>