import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService extends BaseApiService {

  constructor(protected http: HttpClient) {
    super(http);
    this.apiEndpoint = 'user';
  }

  increaseCount(advertisementId: string) {
    return this.makeRequest('GET', 'increaseCount/' + advertisementId, {});
  }

  viewAds(page: string) {
    return this.makeRequest('GET', 'viewAds',
      { page: page });
  }

  viewAdsBeforLogin(page: string) {
    return this.makeRequest('GET', 'viewAds/beforeLogin',
      { page: page });
  }
}
