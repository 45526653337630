import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ViewAllEmergencyDogsAction } from './emergency.action';
import { EmergencyModel } from '@core/models/emergency.model';
import { EmergencyService } from 'app/profile/shared/services/emergency.service';

export interface EmergencyStateModal {
    emergencydogs: any;
    error: any;
    loading: boolean;
}

const defaultState: EmergencyStateModal = {
    emergencydogs: undefined,
    error: undefined,
    loading: false,
};

@State<EmergencyStateModal>({
    name: 'emergencyDogs',
    defaults: defaultState
})

@Injectable()
export class EmergencyDogsState {

    @Selector()
    public static Emergencydogs(state: EmergencyStateModal): any {
        return state.emergencydogs;
    }

    constructor(
        private dogService: EmergencyService
    ) { }

    @Action(ViewAllEmergencyDogsAction)
    ViewEmergencyDogs(ctx: StateContext<EmergencyStateModal>, action: ViewAllEmergencyDogsAction): any {
        ctx.patchState({ loading: action.model ? true : false });
        return this.dogService.getViewEmergencyDogs()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            emergencydogs: response.data,
                            error: undefined,
                            loading: false,
                        })
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }
}
