<div class="bgr-color-2 p2 pb3 pb3">
    <div class="d-flex justify-content-end " >
        <button class="btn" mat-dialog-close>
            <svg class="ic_close" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 40 40">
                <g id="close" transform="translate(-1265 -197)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(1265 197)" fill="white"/>
                    <path id="Path_1" width="14px" height="14px" data-name="Path 1" d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z" transform="translate(1278 209.984)" fill="#326291"/>
                </g>
            </svg>
        </button>
    </div>
    <div class="d-flex justify-content-center">
        <h1 class="font-size-1 font-bold font-color-1 header-title text-center">Der grosse Persönlichkeitstest</h1>
    </div>
</div>