import { Injectable } from '@angular/core';
import { DogModel } from '@core/models/dog.model';
import { PermissionModel } from '@core/models/permissions.model';
import { ProductOptionsModel } from '@core/models/product-options.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DogDetailService } from '../../services/dog-detail.service';
import { UploadDogImageService } from '../../services/upload-dog-image.service';
import {
    LoadDogDetailAction, SetImageAction,
    AddDescriptionAction, SetDogId, AddChipNoAction, SetProfileVideoAction, DeleteProfileVideoAction, SetDogDetailLoaderState
} from './dog-detail.actions';

export interface DogDetailStateModel {
    dogDetail: DogModel | undefined;
    error?: any;
    loading: boolean;
    dogId: string | undefined;
    productPt: ProductOptionsModel;
    permissions: PermissionModel;
}

const defaultState: DogDetailStateModel = {
    dogDetail: undefined,
    error: undefined,
    loading: false,
    dogId: undefined,
    productPt: {} as ProductOptionsModel,
    permissions: {} as PermissionModel
};

@State<DogDetailStateModel>({
    name: 'dogDetail',
    defaults: defaultState
})
@Injectable()
export class DogDetailState {

    @Selector()
    public static getState(state: DogDetailStateModel): DogDetailStateModel {
        return state;
    }

    @Selector()
    public static dogDetail(state: DogDetailStateModel): DogModel | undefined {
        return state.dogDetail;
    }

    @Selector()
    public static loading(state: DogDetailStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: DogDetailStateModel): any {
        return state.error;
    }

    @Selector()
    public static dogId(state: DogDetailStateModel): any {
        return state.dogId;
    }

    @Selector()
    public static permissions(state: DogDetailStateModel): any {
        return state.permissions;
    }

    @Selector()
    public static productPt(state: DogDetailStateModel): ProductOptionsModel {
        return state.productPt;
    }
    constructor(
        private dogDetailService: DogDetailService,
        private imageService: UploadDogImageService,
        private toastr: ToastrNotificationService
    ) { }


    @Action(LoadDogDetailAction)
    getDogDetail(ctx: StateContext<DogDetailStateModel>, action: LoadDogDetailAction): any {
        ctx.patchState({ loading: action.loading ? true : false });
        return this.dogDetailService.getDogDetail(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const data = response.data as DogModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            dogDetail: data,
                            productPt: data?.personalityTestSummary?.personalityTestInfo?.productPt,
                            permissions: data.permissions as PermissionModel
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false,
                        dogDetail: undefined,
                        productPt: undefined
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    // ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetImageAction)
    setImage(ctx: StateContext<DogDetailStateModel>, action: SetImageAction): any {
        ctx.patchState({ loading: true });
        const { file, image, id } = action.model;
        return this.imageService.create(file, image, id)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const dogDetail = ctx.getState().dogDetail as DogModel;
                    const dogDetailOther = image === 0 ? {
                        ...dogDetail,
                        coverImage: response.data.coverImage
                    } as DogModel : {
                        ...dogDetail,
                        profilePhoto: response.data.profilePhoto
                    } as DogModel;

                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        dogDetail: dogDetailOther
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetProfileVideoAction)
    setProfileVideo(ctx: StateContext<DogDetailStateModel>, action: SetProfileVideoAction): any {
        ctx.patchState({ loading: true });
        const { file, id } = action.model;
        return this.dogDetailService.dogProfileVideoUpload(file, id)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const dogDetail = ctx.getState().dogDetail as DogModel;
                    const dogDetailOther = {
                        ...dogDetail,
                        videoLink: response.data
                    }
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        dogDetail: dogDetailOther
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(DeleteProfileVideoAction)
    deletProfileVideo(ctx: StateContext<DogDetailStateModel>, action: DeleteProfileVideoAction): any {
        ctx.patchState({ loading: true });
        return this.dogDetailService.dogDeleteVideoUpload(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const dogDetail = ctx.getState().dogDetail as DogModel;
                    const dogDetailOther = {
                        ...dogDetail,
                        videoLink: ''
                    };
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        dogDetail: dogDetailOther
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddDescriptionAction)
    addDogDescription(ctx: StateContext<DogDetailStateModel>, action: AddDescriptionAction): any {
        ctx.patchState({ loading: true });
        return this.dogDetailService.addDogDesc(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const dogDetail = ctx.getState().dogDetail as DogModel;
                        const dogDetailOther = {
                            ...dogDetail,
                            description: action.model.description
                        } as DogModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            dogDetail: dogDetailOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddChipNoAction)
    addChipNo(ctx: StateContext<DogDetailStateModel>, action: AddChipNoAction): any {
        ctx.patchState({ loading: true });
        const { chipNumber, id } = action.model;
        return this.dogDetailService.addChipNo(chipNumber, id)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const dogDetail = ctx.getState().dogDetail as DogModel;
                        const dogDetailOther = {
                            ...dogDetail,
                            chipNumber
                        } as DogModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            dogDetail: dogDetailOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetDogId)
    setDogId(ctx: StateContext<DogDetailStateModel>, action: SetDogId): any {
        ctx.patchState({ dogId: action.model });
    }

    @Action(SetDogDetailLoaderState)
    setLoaderState(ctx: StateContext<DogDetailStateModel>, action: SetDogDetailLoaderState): any {
        ctx.patchState({
            loading: action.model
        });
    }

    // @Action(SetAllNullUserAction)
    // setNull(ctx: StateContext<DogDetailStateModel>, action: SetAllNullUserAction): any {
    //     ctx.patchState(defaultState);
    // }
}
