import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProductOptionsModel } from '@core/models/product-options.model';
import { UserModel } from '@core/models/user.model';
import { SpinnerService } from '@core/services/spinner.service';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Select, Store } from '@ngxs/store';
import { environment } from 'environments/environment';
import { reject } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
import { GetAllProductAction, PurchaseProductsAction } from '../../../store/credit/credit.actions';
import { CreditState } from '../../../store/credit/credit.state';
import { LoadUserAction } from '../../../store/user/user.actions';
import { UserState } from '../../../store/user/user.state';

@Component({
  selector: 'app-credit-plans',
  templateUrl: './credit-plans.component.html',
  styleUrls: ['./credit-plans.component.scss']
})
export class CreditPlansComponent implements OnInit {
  @Input() subscriptionTypeData: any;
  @Select(CreditState.creditProducts) creditProducts$ !: Observable<ProductOptionsModel[]>;
  @Select(UserState.goodiesDiscount) goodieDiscount$ !: Observable<UserModel>;
  @Output() onGoodiesPurchase = new EventEmitter();
  public frontEndDomain = environment.frontEndDomain;

  creditProducts: Array<any> = [];
  goodiesDiscount: number = 0;
  selectedPlan: any;
  checkboxChecked: Boolean = false;
  private subscriptions: Subscription[] = [];
  submitBtnClicked: Boolean = false;

  constructor(
    private store: Store,
    private chatService: ChatService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private toastr: ToastrNotificationService
  ) { }

  async ngOnInit() {
    await this.getGoodiesDiscount();

    /**
     * API call for get credit plan data
     */
    this.store.dispatch(new GetAllProductAction()).subscribe(
      () => {
        this.getCreditpurchaseList();
      },
      (error: any) => { }
    );

  }

  /**
   * get credit purchase list from store
   */
  getCreditpurchaseList() {
    this.creditProducts$.subscribe((credits: ProductOptionsModel[]) => {
      if (credits) {
        let creditpurchasePlan: any = JSON.parse(JSON.stringify(credits));
        for (let plan of creditpurchasePlan) {
          if (this.goodiesDiscount > 0) {
            let discount = (plan?.oldPrice * this.goodiesDiscount) / 100;
            plan['discountedPrice'] = plan?.oldPrice - discount;
          }
        }
        this.creditProducts = creditpurchasePlan;
        if (this.selectedPlan == null) {
          this.selectedPlan = this.creditProducts[0];
        }

      }
    });
  }

  /**
   * goodies discount
   */
  getGoodiesDiscount() {
    return new Promise(resolve => {
      this.goodieDiscount$.subscribe((discount: any) => {
        if (discount) {
          this.goodiesDiscount = discount.discount;
          resolve('');
        }
      }, (error) => {
        reject('');
      });
    })
  }


  onPurchaseCredits(): void {
    // if (this.user.isCreditsButtonDisabled){
    //   return;
    // }
    let successUrl = `${environment.frontEndDomain}${this.router.url}`;
    console.log(successUrl);
    // if (successUrl.includes('person-test')) {
    //   successUrl = successUrl.replace('/person-test', '?personTestStart=true')
    // }
    if (this.subscriptionTypeData?.subType === 'matchingDogHumanTest') {
      successUrl = successUrl + '?action=refreshWhenMT';
    }
    const data = {
      product: this.selectedPlan?.sku,
      amount: this.goodiesDiscount > 0 ? this.selectedPlan?.discountedPrice : this.selectedPlan?.oldPrice,
      // successUrl: `${environment.frontEndDomain}/goodies`
      successUrl,
      moveToUrl: true
    };
    var windowReference: any = window.open();
    this.store.dispatch(new PurchaseProductsAction(data)).subscribe(
      () => {
        this.creditPurchasePaymentRespSocketCall();
        // this.spinner.show();
        this.submitBtnClicked = true;
        const link = this.store.selectSnapshot(CreditState.link);
        // (window as any).open(link, '_blank');
        windowReference.location = link;
      },
      () => { }
    )
  }



  openNewWindow(url: string): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
    link.remove();
  }



  /**
   * socket call for check credit payment status 
   */
  creditPurchasePaymentRespSocketCall() {
    const sub = this.chatService.listenEvent('purchasedGoodiesPaymentResponse').subscribe(
      (data: any) => {
        if (data?.isPurchasedGoodiesSuccess) {
          this.updateUserData()
          this.unsubscribeCalls();
          // this.spinner.hide();
          this.toastr.showSuccess('', 'Wuff, dein Kauf war erfolgreich.');
          this.onGoodiesPurchase.emit('');
        }
        this.submitBtnClicked = false;
      }
    )
    this.subscriptions.push(sub);
  }

  /**
   * update user data after purchase credit
   */
  updateUserData() {
    this.store.dispatch(new LoadUserAction());
  }


  /**
   * unsubscribe call of observable
   */
  unsubscribeCalls() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

}
