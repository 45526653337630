<div class="custom-modal">
  <!-- <div class="media"> -->
  <div class="cutsom-modal-header">
      <h4>
        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.FEEDBACK_FORM.TITLE' | translate }}
      </h4>
      <!-- <div class="p-4"> -->

      <div class="close-btn">
          <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
              viewBox="0 0 40 40">
              <g id="close" transform="translate(-1265 -197)">
                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(1265 197)"
                      fill="#fed66b" />
                  <path id="Path_1" data-name="Path 1"
                      d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                      transform="translate(1278 209.984)" fill="#326291" />
              </g>
          </svg>
      </div>
  </div>
  <div class="custom-modal-body">
    <div>
      <p class="text-center font-weight-medium fs-18 color-blue ">
        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.FEEDBACK_FORM.TEXT' | translate }}
      </p>
  
        <div class="row mt-4 text-center mr-0">
            <div class="col-md-12 d-flex justify-content-center">
                <button class="btn btn-theme warning-popup-btn mb-0 mr-3 btn-hover-set" (click)="onSubmit('yes')">
                  {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.FEEDBACK_FORM.YES' | translate }}
                </button>
                <!-- </div>
                    <div class="col-md-4 mt-3 mt-md-0 text-center"> -->
                <button class="btn btn-theme warning-popup-btn btn-fill-yellow mb-0" (click)="onSubmit('no')">              
                  {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.FEEDBACK_FORM.NO' | translate }}
                </button>
            </div>
        </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between align-items-baseline">
      <div>
        <p class="fs-16">
          {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.FEEDBACK_FORM.OTHER_TEXT' | translate }}
        </p>
      </div>    
      <div>
          <button class="btn btn-theme warning-popup-btn mb-0 mr-3 btn-hover-set" (click)="onSubmit('other')">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.FEEDBACK_FORM.OTHER' | translate }}
          </button>
      </div>
    </div>
  </div>
</div>