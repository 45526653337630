import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { ShareDogModel } from '@core/models/share-dog.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ShareDogService extends BaseApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'dog';
    }

    shareDog(model: ShareDogModel): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', 'share', model);
    }
}
