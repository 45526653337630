<div class="row credit-plans">
    <div class="col-12 mb-4 d-flex justify-content-between align-items-start">
        <div class="head-text">{{ 'SUBSCRIPTION.BUY_GOODIES.HEADER_TEXT' | translate}}</div>
        <!-- <div class="sub-header">Optionen für deine Anzeige</div> -->
        <button class="btn bg-white rounded-pill text-white font-weight-sbold fs-14 bg-326291 back-btn"
            (click)="onGoodiesPurchase.emit(''); submitBtnClicked = false">
            {{ 'SUBSCRIPTION.BUY_GOODIES.BUTTON_TEXT' | translate}}
        </button>
    </div>
    <div class="col-12 mb-3">
        <div class="credit-section mb-4 cursor-pointer" *ngFor="let credit of creditProducts"
            (click)="selectedPlan = credit;" [ngClass]="{'active': selectedPlan?.sku === credit?.sku}">
            <span class="credit-amount">
                <div class="amount">{{credit?.quantity}}</div>
                <div class="amount-desc">{{ 'SUBSCRIPTION.BUY_GOODIES.GOODIES' | translate}}</div>
            </span>
            <span class="credit-buy-amount">
                <span class="discounted-price-symbol" *ngIf="credit?.discountedPrice">{{credit?.currencySymbol}}</span>
                <span class="discounted-price">{{credit?.discountedPrice | number
                    :'1.2-2'}}</span>&nbsp;
                <span class="current-price" [ngClass]="{'text-strike-through': goodiesDiscount > 0}">
                    <span style="padding-right: 4px;">{{credit?.currencySymbol}}&nbsp;&nbsp;{{credit?.oldPrice | number
                        :'1.2-2' }}</span>
                </span>
            </span>
        </div>
        <hr class="mt-2" />
    </div>

    <div class="col-12 d-flex justify-content-center">
        <div class="checkbox--dog">
            <mat-checkbox class="form-check pl-0" [(ngModel)]="checkboxChecked">
                <label class="form-check-label"
                    [innerHTML]="'SUBSCRIPTION.TERMS_CONDITION_TEXT' | translate: { domainUrl: frontEndDomain}">
                </label>
            </mat-checkbox>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-center mt-3">
        <button [disabled]="!checkboxChecked || submitBtnClicked"
            class="max-width-310 btn btn-fill-blue fs-16 btn-no-text-hover" style="border-radius: 99px;"
            (click)="onPurchaseCredits()"><i class="fa fa-spinner fa-spin mr-1" *ngIf="submitBtnClicked"></i>{{
            'SUBSCRIPTION.BUY_GOODIES.PAY_CREDITS' | translate}}</button>
    </div>
    <div class="col-12 text-center text-success mt-3">
        {{ 'SUBSCRIPTION.BUY_GOODIES.CREDIT_PURCHASE_LINK' | translate}}
    </div>
    <div class="col-12 text-center mw-100 mx-auto resposive-image-width mt-3 mb-3">
        <img src="./assets/img/cards-logo.png" alt="Hundepflege " height="70">
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 1)" template="<img src='assets/img/loader-new.gif' alt='FairDogs_Loader'/>">
    </ngx-spinner>
</div>