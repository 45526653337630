<div class="content-container">
    <div class="edit-profile-picture-box info--popup w-100">
        <div class="media p-0 m-0">
            <div class="modal-body p-0">
                <form class="d-flex flex-column w-100" [formGroup]="formGroup">
                    <div class="form-row">
                        <div class="form-group r--dog-c col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label class="mb2 font-color-1 font-poppins" for=""
                                [ngClass]="{'error-label': (fg.select1.touched || formSubmitted) && fg.select1.invalid}">{{question1?.question}}</label>
                            <mat-radio-group id="select1" aria-label="Select an option" formControlName="select1">
                                <ng-container>
                                    <div *ngFor="let item of selectList1">
                                        <mat-radio-button class="form-check d-block ml-0 pl-0 mb2 color-dark-gray"
                                            [value]="item.answer">
                                            <div style="white-space: normal;">{{item.title}}</div>
                                        </mat-radio-button>
                                    </div>
                                </ng-container>
                            </mat-radio-group>

                        </div>
                        <ng-container *ngIf="(fg.select1.touched || formSubmitted) && fg.select1.invalid">
                            <p class="error-label" *ngIf="fg.select1.errors?.errMsg">{{fg.select1.errors?.errMsg}}</p>
                        </ng-container>
                    </div>

                    <div class="form-row">
                        <div class="form-group r--dog-c col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label class="mb2 font-color-1 font-poppins" for=""
                                [ngClass]="{'error-label': (fg.select2.touched || formSubmitted) && fg.select2.invalid}">{{question2?.question}}</label>
                            <mat-radio-group id="select2" aria-label="Select an option" class=""
                                formControlName="select2">
                                <ng-container *ngFor="let item of selectList12">
                                    <mat-radio-button
                                        class="form-check mobile-form-group mb2 font-poppins select-12 color-dark-gray"
                                        [value]="item.answer">{{item.title}}</mat-radio-button>
                                </ng-container>
                            </mat-radio-group>

                        </div>
                        <ng-container *ngIf="(fg.select2.touched || formSubmitted) && fg.select2.invalid">
                            <p class="error-label" *ngIf="fg.select2.errors?.errMsg">{{fg.gender.errors?.errMsg}}</p>
                        </ng-container>
                    </div>


                    <!--action : begin-->
                    <div class="text-center d-flex justify-content-center align-items-end">
                        <button class="btn border-color-dark-gray border-2 border-radius-1 bgr-white mr2"
                            (click)="back()">
                            <img class="" style="opacity: .5;" src="assets/img/arrow-left.png" width="17px"
                                height="14px" />
                        </button>
                        <button type="submit" [disabled]="!formGroup.valid" [ngClass]="{'disabled' : formGroup.invalid}"
                            class="btn-fill-blue mb-0 p1 primary-btn border-radius-lg pl4 pr4 font-poppins"
                            (click)="saveAndNext()"> Weiter</button>
                    </div>
                    <!--action : end-->
                    <div
                        class="d-flex flex-column justify-content-center align-items-center flex-grow-1 mt3 mb3 w-100 coming-soon-img">
                        <img class="step-image" src="assets/img/dogbehindman.png" />
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>