import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EditImageGallaryModel } from '@core/models/edit-image-gallary.model';
import { EditUserProfileModel } from '@core/models/edit-user-profile.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http);
    this.apiEndpoint = 'user';
  }

  getUser(): Observable<any> {
    const headers = new HttpHeaders({
      'cache-control': 'no-cache',
      Pragma: 'no-cache',
    });
    return this.makeRequest('GET', 'profile', {}, undefined, headers);
  }

  editUserProfile(model: EditUserProfileModel): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'profile', model);
  }

  searchUser(model: string): Observable<ResponseBeanModel> {
    return this.makeRequest('POST', 'search', { value: model });
  }

  shareDogAcceptOrReject(model: object): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'notification/accept', model);
  }

  firstVisitComplete(model: {
    isFirstVisit: boolean;
  }): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'profile', model);
  }

  registerviaDogTest(): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'isRegisterviaDogTest', {});
  }

  getAllCountries(): Observable<any> {
    return this.makeRequest('GET', 'countries', {});
  }

  getInitialCredit(model: { country: string; state: string }): Observable<any> {
    return this.makeRequest('POST', 'credit', model);
  }

  lifeStyleImageUpload(
    file: Array<Blob | File>
  ): Observable<ResponseBeanModel> {
    const formData = new FormData();
    file.forEach((f: File | Blob) => {
      formData.append('file', f);
    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest(
      'POST',
      'gallery/image',
      formData,
      undefined,
      headers
    );
  }

  lifeStyleVideoUpload(file: Blob | File): Observable<ResponseBeanModel> {
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest(
      'PATCH',
      'gallery/video',
      formData,
      undefined,
      headers
    );
  }

  lifeStyleDescription(model: {
    description: string;
  }): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'profile', model);
  }

  lifeStyleImageUpdate(
    model: EditImageGallaryModel
  ): Observable<ResponseBeanModel> {
    const formData = new FormData();
    formData.append('file', model.file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest(
      'PATCH',
      'gallery/image/' + model.id,
      formData,
      undefined,
      headers
    );
  }

  lifeStyleImageDelete(id: string): Observable<ResponseBeanModel> {
    return this.makeRequest('DELETE', 'gallery/image/' + id, {});
  }

  lifeStyleVideoDelete(): Observable<ResponseBeanModel> {
    return this.makeRequest('DELETE', 'gallery/video', {});
  }

  sessionTokenCheck(): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'sessionDetails', {});
  }

  sharePageVisited(model: {
    [key: string]: boolean;
  }): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'profile', model);
  }

  getChatListData(): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'getuserslist', {});
  }

  getUserAdsListData(model: string): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'getUsersAd/' + model, {});
  }

  getSubscriptionDiscount(model: string): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'getDiscount?category=' + model, {});
  }

  getGoodiesDiscount(model: string): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'getDiscount?category=' + model, {});
  }

  getAllUSersList(search: string = '', pageNo: number = 1, pageSize: number = 50): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'users', { search, pageNo, pageSize });
  }

  changeUserLoggedInByUserId(model: string): Observable<ResponseBeanModel> {
    return this.makeRequest('POST', 'changeUser/', { id: model });
  }

  checkChatPaymentStatusForUser(model: any): Observable<ResponseBeanModel> {
    return this.makeRequest('POST', 'chatPayment', { user: model });
  }

  getUserWithAds(model: { id: string }): Observable<any> {
    return this.makeRequest('GET', `public/${model.id}`, {});
  }
}
