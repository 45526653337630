import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, of } from 'rxjs';
import { AddDogForCareModel } from '../models/add-dog-for-care.model';
import { AddPersonForCareModel } from '../models/add-person-for-care.model';
import { EditImageForCare } from '../models/edit-image.model';
import { FeedbackFormModel } from '../models/feedback-form.model';
import { SearchFormModel } from '../models/search-form-model';
@Injectable({
  providedIn: 'root'
})
export class CareService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http);
    this.apiEndpoint = 'dog-care-taking';
  }

  addPersonForCare(model: AddPersonForCareModel): Observable<ResponseBeanModel> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('POST', 'person-create', model, undefined, headers);
  }

  addDogForCare(model: AddDogForCareModel): Observable<ResponseBeanModel> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('POST', 'dog-create', model, undefined, headers);
  }

  getSinglePublication(model: { id: string, type: string }): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'viewsinglepublication', { id: model.id, type: model.type });
  }

  editCareImage(model: EditImageForCare): Observable<ResponseBeanModel> {
    const formData = new FormData();
    formData.append('image', model.image as File);
    formData.append('id', model.id);
    formData.append('type', model.type);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('PATCH', 'image', formData, undefined, headers);
  }

  deleteCareImage(model: EditImageForCare): Observable<ResponseBeanModel> {
    return this.makeRequest('DELETE', 'delete-image', model);
  }

  getAllMyPublications(): Observable<ResponseBeanModel> {
    return this.makeRequest('GET', 'viewallpublication', {});
  }

  deleteMyPublication(model: { id: string, type: string }): Observable<ResponseBeanModel> {
    return this.makeRequest('DELETE', 'deletepublication', { id: model.id, type: model.type });
  }

  editPersonOrDogForCare(model: { data: any, id: string }): Observable<ResponseBeanModel> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('PATCH', 'edit/' + model.id, model.data, undefined, headers);
  }

  searchPersonOrDog(model: { qs: string, searchDataForm: SearchFormModel }): Observable<ResponseBeanModel> {
    return this.makeRequest('POST', 'searchPublication?' + model.qs, model.searchDataForm);
  }

  addToFavourite(model: { id: string, type: string, add: boolean }): Observable<ResponseBeanModel> {
    return this.makeRequest(model.add ? 'POST' : 'DELETE', 'favourite', { publicationId: model.id, type: model.type });
  }

  publishMyPublication(model: { id: string, type: string }): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'publish', model);
  }

  activationChangePublication(model: { id: string, type: string, value: boolean }): Observable<ResponseBeanModel> {
    return this.makeRequest('PATCH', 'activate', model);
  }

  feedbackFormSubmit(model: FeedbackFormModel): Observable<ResponseBeanModel> {
    return this.makeRequest('POST', 'adFeedback', model);
  }
}
