<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent h-48-px m-0 bg-blue-header">
        <mat-toolbar-row class="row ml-0 mr-0 dialog-wuffdog">

            <svg class="ic_close cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                (click)="matDialogRef.close();" viewBox="0 0 40 40" style="z-index: 9;">
                <g id="close" transform="translate(-1265 -197)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(1265 197)"
                        fill="#fed66b" />
                    <path id="Path_1" data-name="Path 1"
                        d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                        transform="translate(1278 209.984)" fill="#326291" />
                </g>
            </svg>

            <div class="col-12 ">
                <div class="main-dialog">
                    <div class="dog-image">
                        <img src="assets/img/dog_img_pay.png" class="Hundepension">
                    </div>
                    <div class="dialog-header">
                        <div class="title dialog-title">
                            <h2 *ngIf="subscriptionTypeData?.subType ===  'dogShareMatchingResult'">
                                {{'SUBSCRIPTION.DOG_MATCHING_MAIN_HEADER_TEXT' | translate}}
                            </h2>
                            <h2 *ngIf="subscriptionTypeData?.subType !=  'dogShareMatchingResult'">
                                {{'SUBSCRIPTION.MAIN_HEADER_TEXT' | translate}}
                            </h2>
                        </div>
                        <div class="dialog-subtitle">
                            <p style="font-size: 16px;" class="m-0" *ngIf="subscriptionTypeData?.subType ===  'dogShareMatchingResult'">
                                {{'SUBSCRIPTION.DOG_MATCHING_SUB_HEADER_TEXT' | translate}}</p>
                            <p class="m-0" *ngIf="subscriptionTypeData?.subType !=  'dogShareMatchingResult'">
                                {{'SUBSCRIPTION.MAIN_SUB_HEADER_TEXT' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="pb-0 m-0">
        <div class="row ml-0 mr-0">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-3 left-bar gooedies-title">
                <div class="mat-dialog-goodies">
                    <div class="dialog-goodies">
                        <div class="mat-dialog-img"><img src="assets/img/ic_bone_blue.svg" alt="Hundepflege"></div>
                        <div class="mat-dialog-num">{{ (user$ | async)?.credits ? (user$ | async)?.credits?.toFixed(2) :
                            0}}</div>
                    </div>
                    <div class="mat-dialog-text">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.TOTAL_USER_AVAILABLE_GOODIES' | translate}}</p>
                    </div>
                </div>


                <!-- *ngIf="subscriptionDiscount > 0 || goodiesDiscount > 0" -->
                <div class="mat-dialog-discount discount-block"
                    *ngIf="subscriptionDiscount > 0 && !showGoodiesPurchaseComponent">
                    <div class="mat-dialog-disc-text">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_HEADER_TEXT' | translate}}</p>
                    </div>
                    <div class="mat-dialog-discnum">
                        <div class="disc-block">
                            <div class="disc-val">
                                <span>
                                    <ng-container *ngIf="!showGoodiesPurchaseComponent">{{subscriptionDiscount}}
                                    </ng-container>

                                </span>
                            </div>
                            <div class="disc-label">
                                <span class="perc">%</span>
                                <span class="disc">Rabatt</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mat-dialog-disctext">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_VALID_DATE_TEXT' |
                            translate}}{{subscriptionDiscountExpiry | date:'d.MM.yy'}}</p>
                    </div> -->
                    <div class="mat-dialog-disctext">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_VALID_DATE_TEXT' |
                            translate}}{{subscriptionDiscountExpiry}}</p>
                    </div>
                </div>


                <div class="mat-dialog-discount discount-block"
                    *ngIf="goodiesDiscount > 0 && showGoodiesPurchaseComponent">
                    <div class="mat-dialog-disc-text">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_HEADER_TEXT' | translate}}</p>
                    </div>
                    <div class="mat-dialog-discnum">
                        <div class="disc-block">
                            <div class="disc-val">
                                <span>
                                    <ng-container *ngIf="showGoodiesPurchaseComponent">{{goodiesDiscount}}
                                    </ng-container>
                                </span>
                            </div>
                            <div class="disc-label">
                                <span class="perc">%</span>
                                <span class="disc">Rabatt</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mat-dialog-disctext">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_VALID_DATE_TEXT' |
                            translate}}{{goodiesDiscountExpiry | date:'d.MM.yy'}}</p>
                    </div> -->
                    <div class="mat-dialog-disctext">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_VALID_DATE_TEXT' |
                            translate}}{{goodiesDiscountExpiry}}</p>
                    </div>
                </div>

                <div class="mat-dialog-discount discount-block"
                    *ngIf="dogShareMatchingResultDiscount >= 0 && showGoodiesPurchaseComponent">
                    <div class="mat-dialog-disc-text">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_HEADER_TEXT' | translate}}</p>
                    </div>
                    <div class="mat-dialog-discnum">
                        <div class="disc-block">
                            <div class="disc-val">
                                <span>
                                    <ng-container
                                        *ngIf="showGoodiesPurchaseComponent">{{dogShareMatchingResultDiscount}}
                                    </ng-container>
                                </span>
                            </div>
                            <div class="disc-label">
                                <span class="perc">%</span>
                                <span class="disc">Rabatt</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mat-dialog-disctext">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_VALID_DATE_TEXT' |
                            translate}}{{goodiesDiscountExpiry | date:'d.MM.yy'}}</p>
                    </div> -->
                    <div class="mat-dialog-disctext">
                        <p> {{'SUBSCRIPTION.BUY_GOODIES.DISCOUNT.DISCOUNT_VALID_DATE_TEXT' |
                            translate}}{{dogShareMatchingResultDiscountExpiry}}</p>
                    </div>
                </div>


            </div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 p-4 component-load-section"
                [ngClass]="{'bg-blue-body': !showGoodiesPurchaseComponent}">
                <span>
                    <app-subscription-plans [subscriptionTypeData]="subscriptionTypeData"
                        (onActionChange)="manageChildActions($event)" (onSubmitResponse)="manageAPIResponse($event)"
                        [ngClass]="{'d-none': showGoodiesPurchaseComponent}">
                    </app-subscription-plans>
                    <!--  -->
                    <app-credit-plans [subscriptionTypeData]="subscriptionTypeData"
                        [ngClass]="{'d-none': !showGoodiesPurchaseComponent}" class="card-plans"
                        (onGoodiesPurchase)="showGoodiesPurchaseComponent = false">
                    </app-credit-plans>
                </span>
            </div>
        </div>
    </div>
    <!-- <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    </div> -->
</div>