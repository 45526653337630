import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
SwiperCore.use([Navigation, Thumbs]);
@Component({
  // tslint:disable-next-line: component-selector
  selector: '.app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})
export class GallerySliderComponent implements OnInit {
  thumbsSwiper: any;
  startIndex = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GallerySliderComponent>,
  ) { }

  ngOnInit(): void {
    // console.log('data 34 ', this.data);
    this.startIndex = this.data.startIndex;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
