import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { SpinnerService } from '@core/services/spinner.service';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';

@Component({
  selector: 'app-edit-cover-image',
  templateUrl: './edit-cover-image.component.html',
  styleUrls: ['./edit-cover-image.component.scss']
})
export class EditCoverImageComponent implements OnInit {
  public coverImageWeb!: string;
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public imageBase64: any = '';
  public title!: string;
  public aspectHeight!: number;
  public aspectWidth!: number;
  public roundCropper!: boolean;
  public isFileChanged = false;
  showError = false;
  notToCrop = [
    'assets/img/img_dog_placeholder_dog_profile.svg',
    'assets/img/img_person_placeholder_web.svg',
    'assets/img/img_banner_placeholder_web.svg',
    'assets/img/img_banner_placeholder_mobile.svg',
    'assets/img/banner_placeholder_web.svg',
    'assets/img/banner_placeholder_mobile.svg',
    'assets/img/img_dog_placeholder_square.svg',
    'assets/img/img_person_placeholder_square.svg'
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditCoverImageComponent>,
    private spinner: SpinnerService,
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    // this.user = this.data.user;
    this.spinner.hide();
    this.coverImageWeb = this.data.coverImageWeb;
    // console.log('47 typeof', this.data.coverImageWeb);
    if (!this.data.coverImageWeb) {
      this.spinner.hide();
    }
    this.title = this.data.title;
    this.aspectHeight = this.data.aspectHeight;
    this.aspectWidth = this.data.aspectWidth;
    this.roundCropper = this.data.roundCropper;
    // console.log('33', this.coverImageWeb);
  }

  // async setCropperImage(url: string): Promise<any>{
  //   const result: any = await fetch (url);
  //   const blob = await result.blob ();
  //   const reader = new FileReader ();
  //   reader.readAsDataURL (blob);
  //   reader.onload = () => {
  //   this.imageBase64 = reader.result;
  //   };
  // }

  onClose(): void {
    this.dialogRef.close();
  }

  // onFileChanged(eventOnChange: any): void {
  //   if (eventOnChange.target.files[0]) {
  //     this.isFileChanged = true;
  //     this.imageChangedEvent = eventOnChange.target.files[0];
  //     this.showError = false;
  //   }
  // }

  onFileChanged(eventOnChange: any): void {
    if (eventOnChange.target.files[0]) {
      if (eventOnChange.target.files[0].type === 'image/png' || eventOnChange.target.files[0].type === 'image/jpeg' || eventOnChange.target.files[0].type === 'image/jpg') {
        this.spinner.show();
        this.isFileChanged = true;
        this.showError = false;
        this.imageChangedEvent = eventOnChange.target.files[0];
      }
      else {
        this.isFileChanged = true;
        this.imageChangedEvent = null;
        this.croppedImage = null;
        this.loadImageFailed();
      }
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  cropperReady(): void {
    this.spinner.hide();
  }

  onCoverImageUpload(): void {
    if (this.croppedImage) {
      // notToCrop.includes - should crop any selected image again & again
      if (this.isFileChanged || (!this.notToCrop.includes(this.coverImageWeb))) {
        const croppedImage = base64ToFile(this.croppedImage);
        this.dialogRef.close({ croppedImage });
      } else {
        this.dialogRef.close();
      }
    }
    // const myFile = this.blobToFile(croppedImage, "my-image.png");
    // const myFile = new File([croppedImage], 'name2.png', {type: croppedImage.type});
    // console.log('croppedImage', croppedImage);
    // console.log('normal', this.imageChangedEvent)
    // const file = new File([croppedImage], this.imageChangedEvent.name, {lastModified: Date.now()});
    // console.log('file', file);

    // this.store.dispatch(new SetCoverImageAction(croppedImage, 0)).subscribe(
    //   () => {
    //     const user = this.store.selectSnapshot(UserState.user);
    //   },
    //   () => {
    //   }
    // );
  }
  loadImageFailed(): void {
    // this.toastr.showError("File not supported","");
    this.spinner.hide();
    this.showError = true;
  }
}
