import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImageGallaryModel } from '@core/models/image-gallary.model';
import { UserModel } from '@core/models/user.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GallerySliderComponent } from 'app/profile/components/profile-dogs/shared/components/gallery-slider/gallery-slider.component';
import { ConfirmDialogComponent } from 'app/profile/shared/components/confirm-dialog/confirm-dialog.component';
import { EditCoverImageComponent } from 'app/profile/shared/components/edit-cover-image/edit-cover-image.component';
import { SubscriptionParentComponent } from 'app/profile/shared/components/subscription/subscription-parent/subscription-parent.component';
import { UserInfoDialogComponent } from 'app/profile/shared/components/user-info-dialog/user-info-dialog.component';
import { DogService } from 'app/profile/shared/services/dogs.service';
import { GetSubscriptionPlanByType, SendIdsForManageSubscriptionOnPublish } from 'app/profile/shared/store/product/product.action';
import { ProductState } from 'app/profile/shared/store/product/product.state';
import { CheckChatPaymentForUSer, LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { UserState } from 'app/profile/shared/store/user/user.state';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { EditImageForCare } from '../../shared/models/edit-image.model';
import { GetPersonForCareModel } from '../../shared/models/get-person-for-care.model';
import { CareImageChange, CareImageDelete, GetAllMyPublications, GetSinglePublication, PublishMyPublication, SetActiveTabIndex, SetMainTabIndex, SetNullDataAction, SetPersonOrDogForm } from '../../shared/store/care.actions';
import { CareState } from '../../shared/store/care.state';
import { DogPreviewDialogComponent } from '../dog-preview-dialog/dog-preview-dialog.component';

@Component({
  selector: 'app-dog-person-sharing-person-preview-dialog',
  templateUrl: './person-preview-dialog.component.html',
  styleUrls: ['./person-preview-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PersonPreviewDialogComponent implements OnInit {

  personData!: GetPersonForCareModel;
  familyOptions: string[] = [];
  gardenTypeOptions: string[] = [];
  careTimingOptions: string[] = [];
  careTypeOptions: string[] = [];
  user!: UserModel;
  personAge!: number;
  mobileView = false;
  searchDataLength = 0;
  currentIndex!: number;
  searchData!: Array<GetPersonForCareModel>;
  isSubmitted = false;
  openedFrom = '';
  @ViewChild('topHeader', { static: true }) topHeader!: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DogPreviewDialogComponent>,
    private store: Store,
    private dogService: DogService,
    private dialog: MatDialog,
    private toastr: ToastrNotificationService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.user = this.store.selectSnapshot(UserState.user) as UserModel;
  }

  ngOnInit(): void {
    const innerWidth = window.innerWidth;
    if (innerWidth < 768) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }

    this.openedFrom = this.data.openedFrom;

    if (this.openedFrom === 'adds') {
      const data = this.store.selectSnapshot(UserState.userWiseAdsData);
      this.searchData = data?.filter((dt: any) => dt?.type === 'dog');
      let indx = this.searchData.findIndex(dt => dt?._id === this.data?._id);
      this.searchDataLength = this.searchData ? this.searchData.length : 0;
      this.currentIndex = indx !== -1 ? indx : 0;
      this.getPersonDetail();
    } else {
      this.searchData = this.store.selectSnapshot(CareState.searchData) as Array<GetPersonForCareModel> || [];
      this.searchDataLength = this.searchData ? this.searchData.length : 0;
      if (typeof this.data?.index !== undefined) {
        this.currentIndex = this.data.index;
      }
      this.getPersonDetail();
    }

    // console.log('70',this.personData)
  }

  userinfo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: this.personData?.user?._id,
      photo: this.personData?.user?.profilePhoto,
      userName: this.personData?.user?.userName,
      firstName: this.personData?.user?.firstName,
      lastName: this.personData?.user?.lastName,
      gender: this.personData?.user?.gender,
      birthdate: this.personData?.user?.birthDay,
      location: this.personData?.user?.location
    };

    // this.dialog.open(UserInfoDialogComponent, dialogConfig);
    this.dialog.open(UserInfoDialogComponent, {
      panelClass: 'user-wise-adds-show',
      disableClose: true,
      data: {
        id: this.personData?.user?._id,
        photo: this.personData?.user?.profilePhoto,
        userName: this.personData?.user?.userName,
        firstName: this.personData?.user?.firstName,
        lastName: this.personData?.user?.lastName,
      }
    }).afterClosed()
      .subscribe(response => {
      }
      )
  }

  getPersonDetail(): void {
    const personData = this.store.selectSnapshot(CareState.personData);
    if (personData) {
      this.personData = personData;
      this.setPersonFormOptions(personData);
      if (personData?.user?.birthDay) {
        const birthDate = moment(personData?.user?.birthDay.split('.').join('/'), 'DD/MM/YYYY');
        const today = moment();
        this.personAge = today.diff(birthDate, 'years');
      }
      this.topHeader.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setPersonFormOptions(personData: GetPersonForCareModel): void {
    const { family, gardenType, careTiming, careType } = personData;
    this.familyOptions = this.customGroupBy(family);
    this.gardenTypeOptions = this.customGroupBy(gardenType);
    this.careTimingOptions = this.customGroupBy(careTiming);
    this.careTypeOptions = this.customGroupBy(careType);
  }

  onClose(): void {
    this.dialogRef.close();
    this.store.dispatch(new SetNullDataAction());
    if (this.data?.afterAdd) {
      this.store.dispatch(new SetMainTabIndex(1));
    }
  }

  customGroupBy(myObj: { [key: string]: boolean | string }): any {
    return Object.keys(myObj).filter((key) => myObj[key]);
  }

  // onImageEdit(event: any): void {
  //   const file = event.target.files[0];
  //   if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif')) {
  //     this.store.dispatch(new CareImageChange({ image: file, id: this.personData._id, type: 'person' } as EditImageForCare)).subscribe(
  //       () => {
  //         this.personData = this.store.selectSnapshot(CareState.personData);
  //       },
  //       () => { }
  //     );
  //   }
  // }

  openCropImageDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      coverImageWeb: this.personData.image,
      title: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.IMAGE_CROP_TITLE',
      aspectWidth: 163,
      aspectHeight: 125,
      roundCropper: false
    };
    // console.log(dialogConfig.data);
    dialogConfig.panelClass = 'c--img-upload';
    const dialogRef = this.dialog.open(EditCoverImageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result?.croppedImage) {
        if (result?.croppedImage && (result?.croppedImage.type === 'image/png')) {
          this.store.dispatch(new CareImageChange({ image: result?.croppedImage, id: this.personData._id, type: 'person' } as EditImageForCare)).subscribe(
            () => {
              this.personData = this.store.selectSnapshot(CareState.personData);
            },
            () => { }
          );
        }
      }
    });
  }

  onImageDelete(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: 'PAGE.SHARED.PICTURE_GALLARY.CONFIRM_DIALOG_TITLE',
      message: 'PAGE.SHARED.PICTURE_GALLARY.CONFIRM_DIALOG_DESC_FIRST',
      cancel: 'PAGE.SHARED.PICTURE_GALLARY.CANCEL_BUTTON',
      submit: 'PAGE.SHARED.PICTURE_GALLARY.DELETE_BUTTON'
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reason) {
          this.store.dispatch(new CareImageDelete({ id: this.personData._id, type: 'person' } as EditImageForCare)).subscribe(
            () => {
              this.personData = this.store.selectSnapshot(CareState.personData);
            },
            () => { }
          );
        }
      }
    });
  }

  viewGallary(): void {
    // console.log('202', this.personData.imageGallery);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'full-screen-popup';
    const imageArray = cloneDeep(this.personData.imageGallery);
    imageArray.map((file: ImageGallaryModel) => {
      file.url = file.image;
      file.isImage = true;
    });
    dialogConfig.data = {
      startIndex: 0,
      onlyPreview: true,
      filesArray: imageArray,
    };

    const dialogRef = this.dialog.open(GallerySliderComponent, dialogConfig);
  }

  editData(): void {
    this.store.dispatch(new SetActiveTabIndex(0));
    this.dialogRef.close();
    this.store.dispatch(new SetPersonOrDogForm(false));
    this.store.dispatch(new SetMainTabIndex(0));
  }

  onNext(): void {
    // console.log('153 index', this.currentIndex);
    this.store.dispatch(new GetSinglePublication({
      id: this.searchData[this.currentIndex + 1]._id,
      type: 'person'
    })).subscribe(
      () => {
        this.currentIndex++;
        this.getPersonDetail();
      },
      () => { }
    );
  }

  onPrev(): void {
    // console.log('166 index', this.data.index, this.searchData);
    this.store.dispatch(new GetSinglePublication({
      id: this.searchData[this.currentIndex - 1]._id,
      type: 'person'
    })).subscribe(
      () => {
        this.currentIndex--;
        this.getPersonDetail();
      },
      () => { }
    );
  }

  publish(): void {
    if (this.isSubmitted) {
      return;
    }
    this.isSubmitted = true;
    if (this.personData.image || this.personData.user.profilePhoto) {
      this.store.dispatch(new PublishMyPublication({ id: this.personData._id, type: 'person' })).subscribe(
        () => {
          this.store.dispatch(new LoadUserAction());
          this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
            this.toastr.showSuccess(res, '');
          });
          if (this.data?.afterAdd) {
            this.isSubmitted = false;
            this.dialogRef.close();
            this.store.dispatch(new SetNullDataAction());
            this.store.dispatch(new SetMainTabIndex(1));
          } else {
            this.store.dispatch(new GetAllMyPublications()).subscribe(
              () => {
                this.isSubmitted = false;
                this.dialogRef.close();
              },
              () => {
                this.isSubmitted = false;
              }
            );
          }
        },
        () => { }
      );
    } else {
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.UPLOAD_PHOTO_ERROR').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
      this.isSubmitted = false;
    }
  }


  /**
 * show person sharing payment modal
 */
  async showPersonSharingPaymentPopup() {
    if (this.personData.image || this.personData.user.profilePhoto) {
      let dogData = JSON.parse(JSON.stringify(this.personData));
      let keyValMatchObj: any = {
        personNotOnHome: 'lpspecificaddisable',
        displayLocation: 'withoutResidence'
      }
      let existingPersonDetail: any = this.store.selectSnapshot(CareState.personData);
      await this.getPersonSharingSubscriptionPlan();
      const personSharingSubscriptionPlan: any = this.store.selectSnapshot(ProductState.getSubscriptionPlanByType);
      let selectedIds: any = [];
      for (const key of Object.keys(keyValMatchObj)) {
        if (dogData[key] === true) { // find on edit new selected plan ids
          let index = personSharingSubscriptionPlan.findIndex((plan: any) => plan?.subType === keyValMatchObj[key]);
          selectedIds.push(personSharingSubscriptionPlan[index]?._id);
        }
      }


      let bothArraySame: boolean = true;

      if (selectedIds?.length > 0) {
        bothArraySame = false;
      } else {
        bothArraySame = true;
      }

      if (!bothArraySame) {
        this.dialog.open(SubscriptionParentComponent, {
          panelClass: 'subscription-parent-dialog',
          disableClose: true,
          data: {
            type: 'personCare',
            subType: '',
            mode: 'add',
            selectedIds: selectedIds,
            dogOrPersonId: existingPersonDetail?._id
          }
        }).afterClosed()
          .subscribe(response => {
            if (response?.resp === 'success') {
              let obj = {
                _id: this.personData?._id,
                products: response?.productSelectedIds,
                type: response?.type,
                subType: response?.subType
              }
              this.store.dispatch(new SendIdsForManageSubscriptionOnPublish(obj)).subscribe( // send ids for publish change subscription ids
                () => {
                  this.publish();
                },
                () => { }
              );
            }
          }
          )

      } else {
        this.publish();
      }

    } else {
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.UPLOAD_PHOTO_ERROR').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
      this.isSubmitted = false;
    }
  }


  /**
   * API for get subscription plan 
   */
  getPersonSharingSubscriptionPlan() {
    return new Promise((resolve, reject) => {
      this.store.dispatch(new GetSubscriptionPlanByType({ type: 'personCare', subType: '' })).subscribe(
        () => {
          resolve('');
        },
        () => {
          reject('');
        }
      );
    })
  }

  openChatScreen(userData: any): void {
    this.dialogRef.close();
    localStorage.setItem('User', JSON.stringify(userData));

    var open = window.open('/chat');
    if (open == null || typeof (open) == 'undefined') {
      window.location.assign('/chat')
    }

    // window.open('/profile/chat', "myTab");
    // this.router.navigate(['/profile/chat'], { state: { User: userData } });
  }


  /**
   * manage to show chat page or chat make payment popup
   * @param userData 
   */
  manageChatFeature(userData: any) {
    this.openChatScreen(userData);
    /**
     * API call for check chat payment and get data from store
     */
    // this.store.dispatch(new CheckChatPaymentForUSer(userData?.user?._id)).subscribe(
    //   () => {
    //     const isPaidForChat: any = this.store.selectSnapshot(UserState.checkChatPaymentStatusForUser);

    //     if (isPaidForChat?.paidChat) {
    //       this.openChatScreen(userData);
    //     } else {
    //       this.showChatPaymentPopup(userData);
    //     }

    //   },
    //   (error: any) => {
    //     this.showChatPaymentPopup(userData); // TODO: remove it when this API works
    //   }
    // );
  }


  /**
   * show chat payment modal
   */
  showChatPaymentPopup(userData: any) {
    this.dialog.open(SubscriptionParentComponent, { // show chat subscription popup
      panelClass: 'subscription-parent-dialog',
      disableClose: true,
      data: {
        type: 'dogCare',
        subType: 'Kontaktaufnahme',
        userId: userData?.user?._id,

      }
    }).afterClosed()
      .subscribe(response => {
        if (response?.resp === 'success') {
          this.manageChatFeature(userData);
        }
      }
      )
  }

}
