<div class="dog-preview-modal container px-0" #topHeader>
    <div class="preview-person-or-dog">
        <div class="media">
            <div class="modal-body p-0" *ngIf="personData">
                <div>
                    <div class="bg-4CADCD position-relative min-height-empty">
                        <ng-container *ngIf="data.participant; else othersPublication">
                            <div class="text-center flex-wrap d-flex justify-content-center"
                                [ngClass]="{'d-flex': !mobileView}">
                                <!-- Use d-flex instead of d-block to show/hide in above line-->
                                <div class="resposive-btn-dialog mr-sm-3">
                                    <button
                                        class="btn bg-white rounded-pill color-blue-important font-weight-sbold fs-14 cus-my-20-btn"
                                        (click)="editData()">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.EDIT_BUTTON_HEADER' | translate }}
                                    </button>
                                </div>
                                <div class="resposive-btn-dialog" *ngIf="!personData.published">
                                    <!-- publish()   showPersonSharingPaymentPopup() -->
                                    <button
                                        class="btn rounded-pill color-blue-important font-weight-sbold fs-14 cus-my-20-btn bg-yellow"
                                        [ngClass]="{'disabled': isSubmitted}" (click)="showPersonSharingPaymentPopup()">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PUBLISH_BUTTON_HEADER' | translate }}
                                    </button>
                                </div>
                                <div class="resposive-btn-dialog" *ngIf="personData.published">
                                    <button
                                        class="btn bg-white rounded-pill text-white font-weight-sbold fs-14 cus-my-20-btn bg-326291"
                                        [ngClass]="{'disabled': isSubmitted}" (click)="onClose()">
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #othersPublication>
                            <h3
                                class="fs-32 mb-0 padd-right-resp text-white font-weight-bold text-left fsm-18 text-md-center py-3 cus-px-80">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.TITLE' | translate }}
                            </h3>
                        </ng-template>
                        <div class="position-absolute pos-vertical-align pos-right-20">
                            <svg class="ic_close cursor-pointer" (click)="onClose()" xmlns="http://www.w3.org/2000/svg"
                                width="40" height="40" viewBox="0 0 40 40">
                                <g id="close" transform="translate(-1265 -197)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                        transform="translate(1265 197)" fill="#fed66b" />
                                    <path id="Path_1" data-name="Path 1"
                                        d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                                        transform="translate(1278 209.984)" fill="#326291" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-md-5 col-xl-4 px-0">
                            <h2 class="fsm-22 text-white font-weight-sbold d-none pink-flag pink-main-heading mb-0">
                                Notfallbetreuung gesucht</h2>
                            <!-- Use d-inline-block instead of d-block to show/hide in above line-->
                            <div class="modal-left-div">
                                <div class="w-100 profile-modal-btn mt-5"
                                    *ngIf="!data.participant && (user._id !== personData.user._id)">
                                    <!-- openChatScreen(personData)   manageChatFeature(dogDetail) -->
                                    <button (click)="manageChatFeature(personData)"
                                        class="btn theme-primary-btn ml-auto btn-height-45 px-4 mb-0"><span
                                            class="d-inline-block chatbubbles-icon mr-2"><img
                                                src="assets/img/ion_chatbubbles-blue.svg"
                                                alt="FairDogs_ChatBlue"></span>Kontakt
                                        aufnehmen</button>
                                </div>
                                <div class="main-title-modal">
                                    <h5 class="fs-18 font-weight-sbold mb-1 color-blue text-center text-break">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.PROFILE_TEXT' |
                                        translate : {name: personData?.user?.userName ? personData?.user?.userName :
                                        (personData?.user?.firstName+'
                                        '+personData?.user?.lastName?.charAt(0)) } }}
                                    </h5>
                                    <p class="color-dark-gray fs-16 fsm-16 text-center" *ngIf="personAge">
                                        <ng-container [ngSwitch]="true">
                                            <ng-container *ngSwitchCase="personAge<14">
                                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.UNDER_AGE' |
                                                translate }} 14
                                            </ng-container>
                                            <ng-container *ngSwitchCase="personAge>=14 && personAge<18">
                                                14-18 {{'PAGE.YEAR' | translate }}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="personAge>=18 && personAge<30">
                                                18-30 {{'PAGE.YEAR' | translate }}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="personAge>=30 && personAge<50">
                                                30-50 {{'PAGE.YEAR' | translate }}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="personAge>=50 && personAge<60">
                                                50-60 {{'PAGE.YEAR' | translate }}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="personAge>=60 && personAge<75">
                                                60-75 {{'PAGE.YEAR' | translate }}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="personAge>=75">
                                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.ABOVE_AGE' |
                                                translate }} 75
                                            </ng-container>
                                        </ng-container>
                                    </p>
                                </div>
                                <div class="text-center position-relative">
                                    <div class="title-profile-img">
                                        <img class="w-100"
                                            [src]="personData?.user?.profilePhoto ? personData?.user?.profilePhoto : 'assets/img/img_person_placeholder_web.svg'"
                                            alt="FairDogs_Hundehalter">
                                    </div>

                                    <div *ngIf="(!data.participant && openedFrom !== 'adds' && (searchData.length && searchData[currentIndex].distance >= 0)); else valuetrue "
                                        class=" d-flex justify-content-center pb-4 bottom-cta-result km-main card-action-bottom">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <p class="m-0 color-gray fs-14 text-nowrap">
                                                    <span class="d-inline-block mr-1">
                                                        <ng-container
                                                            *ngIf="searchData[currentIndex].distance > 1; else meterBlock">
                                                            {{ searchData[currentIndex].distance | number: "1.0-0" }}km
                                                        </ng-container>
                                                        <ng-template #meterBlock>
                                                            {{ searchData[currentIndex].distance * 1000 | number:
                                                            "1.0-0" }}m
                                                        </ng-template>
                                                    </span>
                                                    {{ "PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.FROM_YOU" | translate
                                                    }}

                                                </p>
                                            </div>
                                        </div>

                                        <div *ngIf="false" class="status-symbol status-offline">
                                            <p class="m-0 status-dot-dynamic fs-14">offline</p>
                                        </div>
                                        <div class="text-truncate ml-1 mw-100"
                                            *ngIf="!searchData[currentIndex]?.displayLocation">
                                            <p class=" m-0 color-gray fs-14 text-nowrap d-flex flex-nowrap"
                                                [ngClass]="{'content-seperate': !searchData[currentIndex]?.displayLocation}">
                                                <ng-container
                                                    *ngIf=" (searchData[currentIndex].user?.location?.city || searchData[currentIndex].user?.location?.state) && searchData[currentIndex].user?.location?.countryName; else elseBlock ">
                                                    <ng-container *ngIf="searchData[currentIndex].user?.location?.city">
                                                        <span class="text-truncate location-width">| {{
                                                            searchData[currentIndex].user?.location?.city}}</span>,
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="searchData[currentIndex].user?.location?.state">
                                                        <span class="pl-1 text-truncate location-width">{{
                                                            searchData[currentIndex].user?.location?.state}}</span>,
                                                    </ng-container>
                                                    <span class="pl-1">{{
                                                        searchData[currentIndex].user?.location?.countryName }}</span>
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    <span>{{ searchData[currentIndex].user?.country }}</span>
                                                </ng-template>
                                            </p>
                                        </div>
                                    </div>
                                    <ng-template #valuetrue>
                                        <div
                                            class=" d-flex justify-content-center pb-4 bottom-cta-result km-main card-action-bottom">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <p class="m-0 color-gray fs-14 text-nowrap">
                                                        <span class="d-inline-block mr-1">
                                                            <ng-container
                                                                *ngIf="personData.distance > 1; else meterBlock">
                                                                {{ personData?.distance | number: "1.0-0" }}km
                                                            </ng-container>
                                                            <ng-template #meterBlock>
                                                                {{ personData.distance * 1000 | number: "1.0-0" }}m
                                                            </ng-template>
                                                        </span>
                                                        {{ "PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.FROM_YOU" |
                                                        translate }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div *ngIf="false" class="status-symbol status-offline">
                                                <p class="m-0 status-dot-dynamic fs-14">offline</p>
                                            </div>
                                            <div class="text-truncate ml-1 mw-100" *ngIf="!personData?.displayLocation">
                                                <p class=" m-0 color-gray fs-14 text-nowrap d-flex flex-nowrap"
                                                    [ngClass]="{'content-seperate': !personData?.displayLocation}">
                                                    <ng-container
                                                        *ngIf=" (personData?.user?.location?.city || personData?.user?.location?.state) && personData?.user?.location?.countryName; else elseBlock ">
                                                        <ng-container *ngIf="personData?.user?.location?.city">
                                                            <span class="text-truncate location-width">| {{
                                                                personData?.user?.location?.city}}</span>,
                                                        </ng-container>
                                                        <ng-container *ngIf="personData?.user?.location?.state">
                                                            <span class="pl-1 text-truncate location-width">{{
                                                                personData?.user?.location?.state}}</span>,
                                                        </ng-container>
                                                        <span class="pl-1">{{ personData?.user?.location?.countryName
                                                            }}</span>
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        <span>{{ personData?.user?.country }}</span>
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <ng-container *ngIf="mobileView && !data.participant">
                                        <div role="button" class="dialog-arrow-left-sm dialog-slider-arrow-sm"
                                            [ngClass]="{'disabled': this.currentIndex===0}">
                                            <img (click)="this.currentIndex !== 0 && onPrev()"
                                                src="assets/img/ic_arrow_right-blue.svg" alt="FairDogs_ArrowRight">
                                        </div>
                                        <div role="button" class="dialog-arrow-right-sm dialog-slider-arrow-sm"
                                            [ngClass]="{'disabled': (this.currentIndex+1)===this.searchDataLength}">
                                            <img (click)="(this.currentIndex+1) !== this.searchDataLength && onNext()"
                                                src="assets/img/ic_arrow_right-blue.svg" alt="FairDogs_ArrowRight">
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="img-title-below">
                                    <h6 class="font-weight-sbold fs-16 color-gray text-center mt-3">
                                        “
                                        <ng-container
                                            *ngIf="personData?.reason?.reasonOption!=='other'; else otherReasonBlock">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.PERSON_FORM.FORTH.THIRD.OPTION.'+
                                            personData.reason.reasonOption.toUpperCase() | translate }}
                                        </ng-container>
                                        <ng-template #otherReasonBlock>
                                            {{personData?.reason?.other}}
                                        </ng-template>”
                                    </h6>
                                </div>
                                <!-- <div class="profile-modal-btn border-cus-bttom">
                                    <button class="btn theme-primary-btn ml-auto btn-height-45 px-5">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.GO_TO_PROFILE' |
                                        translate }}
                                    </button>
                                </div> -->
                                <div class="form-row mx-0 mt-3">
                                    <div class="w-100">
                                        <div class="d-flex align-items-center mb-0">
                                            <div class="list-img-common">
                                                <img src="assets/img/list-star.svg" alt="FairDogs_Star">
                                            </div>
                                            <div>
                                                <h6 class="font-weight-medium sub-title-cus mb-0">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.EXPERIENCE'
                                                    | translate }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="form-group d-flex px-0 col-12 align-items-center mb-1">
                                            <mat-slider class="mb-md-2" [max]="20" [min]="1" [step]="1"
                                                [value]="personData.whenWithDog" [disabled]="true"
                                                aria-labelledby="example-name-label">
                                            </mat-slider>
                                        </div>
                                        <div *ngIf="personData.experience" class="w-100">
                                            <span class="fs-16 text-white font-weight-sbold span-info">
                                                {{personData.experience}} {{'PAGE.YEAR' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="w-100" *ngIf="personData.homeType">
                                        <div class="d-flex align-items-center mb-3 mt-1">
                                            <div class="list-img-common">
                                                <img src="assets/img/list-home.svg" alt="Hundeübernachtung">
                                            </div>
                                            <div>
                                                <h6 class="font-weight-medium sub-title-cus mb-0">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.MY_HOME' |
                                                    translate }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div *ngIf="personData.homeType" class="w-100">
                                            <span class="fs-16 text-white font-weight-sbold span-info">
                                                {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.PERSON_FORM.THIRD.SECOND.OPTION.'+personData.homeType.toUpperCase()|
                                                translate}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="d-flex align-items-center mb-3 mt-1">
                                            <div class="list-img-common">
                                                <img src="assets/img/garden-leaf.svg" alt="Ferienhundesitter">
                                            </div>
                                            <div>
                                                <h6 class="font-weight-medium sub-title-cus mb-0">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.GARDEN' |
                                                    translate}}
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="w-100">
                                            <ng-container *ngIf="gardenTypeOptions.length; else NoGarden">
                                                <span class="fs-16 text-white font-weight-sbold span-info"
                                                    *ngFor="let item of gardenTypeOptions">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.'+item.toUpperCase()
                                                    | translate}}
                                                </span>
                                            </ng-container>
                                            <ng-template #NoGarden>
                                                <span class="fs-16 text-white font-weight-sbold span-info">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.NO_GARDEN'
                                                    | translate}}
                                                </span>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="d-flex align-items-center mb-3 mt-1">
                                            <div class="list-img-common">
                                                <img src="assets/img/list-user.svg" alt="Hundesharing">
                                            </div>
                                            <div>
                                                <h6 class="font-weight-medium sub-title-cus mb-0">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.FAMILY' |
                                                    translate }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div *ngIf="familyOptions.length" class="w-100">
                                            <span class="fs-16 text-white font-weight-sbold span-info"
                                                *ngFor="let item of familyOptions">
                                                {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.PERSON_FORM.THIRD.FIRST.OPTION.'+item.toUpperCase()
                                                | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="text-center w-100 mt-3 cus-mb-40-res">
                                    <button (click)="openChatScreen(personData)" class="btn theme-primary-btn ml-auto btn-height-45 px-5 mb-0 font-weight-bold">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.CONTACT_BUTTON' |
                                        translate }}
                                    </button>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-7 col-xl-8 bg-4CADCD-10 px-0">
                            <div class="modal-right-div">
                                <div class="white-box-top">
                                    <ng-container *ngIf="personData.dogNumber">
                                        <h6 class="font-weight-medium sub-title-cus cus-mb-10">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.DOG_NUMBER_TEXT' |
                                            translate }}
                                        </h6>
                                        <div class="w-100">
                                            <span class="fs-16 text-white font-weight-sbold span-info">
                                                {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.PERSON_FORM.FIRST.FIRST.OPTION.'+personData.dogNumber.toUpperCase()
                                                | translate}}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="personData.careTiming">
                                        <h6 class="font-weight-medium sub-title-cus cus-mb-10">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.CARE_TYPE_TEXT' |
                                            translate }}
                                        </h6>
                                        <div class="w-100">
                                            <ng-container *ngIf="careTimingOptions?.length">
                                                <span class="fs-16 text-white font-weight-sbold span-info"
                                                    *ngFor="let item of careTimingOptions">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.PERSON_FORM.FIRST.SECOND.OPTION.'+item.toUpperCase()
                                                    | translate}}
                                                </span>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="personData.careType">
                                        <h6 class="font-weight-medium sub-title-cus cus-mb-10">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.CARE_TIME_TEXT' |
                                            translate }}
                                        </h6>
                                        <div class="w-100">
                                            <ng-container *ngIf="careTypeOptions?.length">
                                                <span class="fs-16 text-white font-weight-sbold span-info"
                                                    *ngFor="let item of careTypeOptions">
                                                    <ng-container *ngIf="item!=='other';else otherOption">
                                                        {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.PERSON_FORM.SECOND.FIRST.OPTION.'+item.toUpperCase()
                                                        | translate}}
                                                    </ng-container>
                                                    <ng-template #otherOption>
                                                        {{personData.careType.other}}
                                                    </ng-template>
                                                </span>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="white-box-bottom paw-background-image">
                                    <div class="quote-white-box">
                                        <img src="assets/img/quote-profile.svg" alt="FairDogs_Hundehalter">
                                    </div>
                                    <p class="fs-16 color-gray white-space-break">
                                        {{personData?.motivation}}
                                    </p>
                                    <div class="profile-status row mx-0 align-items-center">
                                        <div class="prof-width-left col-3 px-0">
                                            <div class="border-radius-50 overflow-hidden profile-status-img">
                                                <img [src]="personData?.user?.profilePhoto ? personData?.user?.profilePhoto : 'assets/img/img_person_placeholder_web.svg'"
                                                    alt="FairDogs_Hundehalter" (click)="userinfo()">
                                            </div>
                                        </div>
                                        <!-- <div class="prof-width-left col-3 px-0" *ngIf="personData?.user?.profilePhoto">
                                            <div class="border-radius-50 overflow-hidden profile-status-img">
                                                <img [src]="personData?.user?.profilePhoto ? personData?.user?.profilePhoto : 'assets/img/img_person_placeholder_web.svg'"
                                                alt="Image">
                                            </div>
                                        </div> -->
                                        <div class="prof-width-right col-9 px-0">
                                            <p class="status-text text-overflow-ellipsis text-break">
                                                <ng-container *ngIf="personData?.user?.userName; else userNameNull">
                                                    {{ personData?.user?.userName ? personData?.user?.userName : '' }}
                                                </ng-container>
                                                <ng-template #userNameNull>
                                                    {{personData?.user?.firstName + ' ' +
                                                    personData?.user?.lastName?.charAt(0)+ '.'}}
                                                </ng-template>
                                            </p>
                                            <!-- <div class="status-dynamic">
                                                <p class="mb-0">offline</p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="d-flex flex-wrap flex-xl-nowrap justify-content-center align-items-center px-4 custom-mb">
                                    <div class="width-sm-responsive">
                                        <div>
                                            <ng-container *ngIf="personData.image; else elseBlock">
                                                <div class="three-view-show box-shadow-profile mr-md-0 mr-auto mr-lg-3">
                                                    <div>
                                                        <div class="dog-details-box position-relative">
                                                            <button *ngIf="data.participant" mat-icon-button
                                                                [matMenuTriggerFor]="menu"
                                                                class="btn btn-toggle-custom p-0"
                                                                aria-label="Example icon-button with a menu">
                                                                <img src="assets/img/more.svg" alt="FairDogs_More">
                                                            </button>
                                                            <mat-menu #menu="matMenu" xPosition="before"
                                                                class="dog-gallery-drop">
                                                                <!-- <input #fileInputEdit (change)="onImageEdit($event)" 
                                                                    type="file"
                                                                    accept="image/x-png,image/bmp,image/jpeg,image/gif"
                                                                    class="cover-image-input"> -->
                                                                <button mat-menu-item (click)="openCropImageDialog()">
                                                                    <img class="mr-2" src="assets/img/ic_edit-white.svg"
                                                                        alt="FairDogs_Edit"><span>{{
                                                                        'PAGE.SHARED.PICTURE_GALLARY.UPDATE' |
                                                                        translate}}</span>
                                                                </button>
                                                                <button *ngIf="!personData.published" mat-menu-item
                                                                    (click)="onImageDelete()">
                                                                    <img class="mr-2"
                                                                        src="assets/img/ic_delete_white.svg"
                                                                        alt="FairDogs_Delete"><span>{{
                                                                        'PAGE.SHARED.PICTURE_GALLARY.DELETE' |
                                                                        translate}}</span>
                                                                </button>
                                                            </mat-menu>
                                                            <div class="overflow-hidden list-view-img">
                                                                <img class="w-100" [src]="personData.image"
                                                                    alt="FairDogs_Hundehalter">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                <div *ngIf="data.participant && !personData.published">
                                                    <div class="if-no-img mr-md-0 mr-auto mr-lg-3">
                                                        <div class="dog-details-box">
                                                            <div class="overflow-hidden list-view-img">
                                                                <!-- <input #fileInputOther (change)="onImageEdit($event)"
                                                                    type="file"
                                                                    accept="image/png,image/bmp,image/jpeg,image/gif"
                                                                    class="cover-image-input"> -->
                                                                <img matTooltip="{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.IMAGE_TOOLTIP' | translate }}"
                                                                    class="cursor-pointer"
                                                                    [src]="'assets/img/img_upload_placefolder.svg'"
                                                                    alt="FairDogs_ImageUpload"
                                                                    (click)="openCropImageDialog()">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="profile-modal-btn mb-0 ml-0 ml-lg-4"
                                        *ngIf="personData.imageGallery.length">
                                        <button
                                            class="btn theme-primary-btn ml-auto btn-height-45 px-5 mb-0 font-weight-bold"
                                            (click)="viewGallary()">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.VIEW_GALLARY' |
                                            translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!data.participant">
        <div role="button" class="dialog-arrow-left dialog-slider-arrow d-none d-md-block"
            [ngClass]="{'disabled': this.currentIndex===0}">
            <img (click)="this.currentIndex !== 0 && onPrev()" src="assets/img/ic_arrow_right.svg"
                alt="FairDogs_ArrowRight">
        </div>
        <div role="button" class="dialog-arrow-right dialog-slider-arrow d-none d-md-block"
            [ngClass]="{'disabled': (this.currentIndex+1)===this.searchDataLength}">
            <img (click)="(this.currentIndex+1) !== this.searchDataLength && onNext()"
                src="assets/img/ic_arrow_right.svg" alt="FairDogs_ArrowRight">
        </div>
    </ng-container>
</div>