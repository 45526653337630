import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticatedGuard } from './guard/authenticated.guard';
import { UnauthenticatedGuard } from './guard/unauthenticated.guard';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OptionLanguagePipe } from './pipe/option-language.pipe';
// import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatToastComponent } from './components/chat-toast.component';
import { TranslateCut } from './pipe/translate-cut.pipe';
import { SentryErrorLogger } from './services/sentry-logger.service';

@NgModule({
  declarations: [
    OptionLanguagePipe,
    ChatToastComponent,
    TranslateCut,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({ 
      // maxOpened: 1,
    }),
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    NgSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatRadioModule,
    DragDropModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSelectModule,
    // NgxSliderModule,
    DragDropModule,
    OptionLanguagePipe,
    TranslateCut,
    // NgxGalleryModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ClipboardModule,
    MatCardModule,
    MatSliderModule,
    MatProgressBarModule,
    MatTooltipModule
  ],
  providers: [
    SentryErrorLogger,
    AuthenticatedGuard,
    UnauthenticatedGuard,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    MatDatepickerModule
  ],
})
export class AppCoreModule { }
