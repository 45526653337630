import { Injectable } from '@angular/core';
import { AddDogModel } from '@core/models/add-dog';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { DogModel } from '@core/models/dog.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DogService } from '../../../shared/services/dogs.service';
import {
    GetAllDogsAction, SetFilterOptionAction, SetFilteredDogAction,
    SetSortedDataAction, ResetFilteredDogAction, SearchDogAction, AddRipDogAction, HideDogAction, DeleteDogAction, MoveRipFalse, GetDogsFavoritesCountById
} from './dog.actions';
import { AddRipDogModel } from '@core/models/add-rip-dog.model';
export interface DogStateModel {
    dogs?: DogModel[];
    filteredDogs?: DogModel[];
    ripDogs?: DogModel[];
    // accessToken?: string;
    filterText: string;
    error?: any;
    filterOptions: any;
    loading: boolean;
    deletedDogs: number;
    shareMyDogsData?: DogModel[];
    shareRipDogsData?: DogModel[];
    moveToRip: boolean;
    favoriteCountById: number;
}

const defaultState: DogStateModel = {
    dogs: undefined,
    filteredDogs: undefined,
    filterText: '',
    ripDogs: undefined,
    // accessToken: undefined,
    error: undefined,
    filterOptions: undefined,
    loading: false,
    deletedDogs: 0,
    shareMyDogsData: undefined,
    shareRipDogsData: undefined,
    moveToRip: false,
    favoriteCountById: 0
};

@State<DogStateModel>({
    name: 'userDogs',
    defaults: defaultState
})
@Injectable()
export class DogState {

    @Selector()
    public static getState(state: DogStateModel): DogStateModel {
        return state;
    }

    @Selector()
    public static dogs(state: DogStateModel): DogModel[] | undefined {
        return state.dogs;
    }

    @Selector()
    public static ripDogs(state: DogStateModel): DogModel[] | undefined {
        return state.ripDogs;
    }

    @Selector()
    public static filteredDogs(state: DogStateModel): DogModel[] | undefined {
        return state.filteredDogs?.filter(p => {
            return p.name.toUpperCase().includes((state.filterText).toUpperCase());
        });
    }

    @Selector()
    public static filterText(state: DogStateModel): string | undefined {
        return state.filterText;
    }

    @Selector()
    public static filterOptions(state: DogStateModel): any | undefined {
        return state.filterOptions;
    }

    @Selector()
    public static deletedDogs(state: DogStateModel): number {
        return state.deletedDogs;
    }

    @Selector()
    public static shareMyDogsData(state: DogStateModel): any | undefined {
        return state.shareMyDogsData;
    }

    @Selector()
    public static shareRipDogsData(state: DogStateModel): DogModel[] | undefined {
        return state.shareRipDogsData;
    }

    @Selector()
    public static moveToRip(state: DogStateModel): boolean {
        return state.moveToRip;
    }

    @Selector()
    public static loading(state: DogStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: DogStateModel): any {
        return state.error;
    }

    @Selector()
    public static selectedDogsFavoritesCount(state: DogStateModel): number {
        return state.favoriteCountById;
    }

    constructor(
        private dogService: DogService
    ) { }

    @Action(GetAllDogsAction)
    getAllDogs(ctx: StateContext<DogStateModel>, action: GetAllDogsAction): any {
        ctx.patchState({ loading: action.model ? true : false });
        return this.dogService.getAllDogs()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        // console.log('101 response dog', response);
                        const general = response.data.general;
                        const rip = response.data.rip;
                        const dogData = this.sortingDogs(general);
                        const ripData = this.sortingDogs(rip);

                        ctx.patchState({
                            dogs: dogData,
                            filteredDogs: dogData,
                            ripDogs: ripData,
                            error: undefined,
                            loading: false,
                            deletedDogs: response?.data?.deletedDogs,
                            shareMyDogsData: response?.data?.shareDogData?.general,
                            shareRipDogsData: response?.data?.shareDogData?.rip
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    sortingDogs(dogs: any): any {
        dogs?.sort((a: DogModel, b: DogModel) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        });
        return dogs;
    }

    @Action(AddRipDogAction)
    addRipDog(ctx: StateContext<DogStateModel>, action: AddRipDogAction): any {
        ctx.patchState({ loading: true });
        const { ripDog, id } = action.model;
        return this.dogService.ripDogAdd(ripDog as AddRipDogModel, id)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            moveToRip: true,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(HideDogAction)
    hideDog(ctx: StateContext<DogStateModel>, action: HideDogAction): any {
        ctx.patchState({ loading: true });
        return this.dogService.hideDog(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(DeleteDogAction)
    deleteDog(ctx: StateContext<DogStateModel>, action: DeleteDogAction): any {
        ctx.patchState({ loading: true });
        return this.dogService.deleteDog(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetFilterOptionAction)
    setFilterOption(ctx: StateContext<DogStateModel>, action: SetFilterOptionAction): any {
        ctx.patchState({
            filterOptions: action.model
        });
    }

    @Action(SetFilteredDogAction)
    setFilterDogs(ctx: StateContext<DogStateModel>, action: SetFilteredDogAction): any {
        ctx.patchState({
            filteredDogs: action.model,
        });
    }

    @Action(SearchDogAction)
    searchText(ctx: StateContext<DogStateModel>, action: SearchDogAction): any {
        ctx.patchState({
            filterText: action.model
        });
    }

    @Action(SetSortedDataAction)
    sorting(ctx: StateContext<DogStateModel>, action: SetSortedDataAction): any {
        ctx.patchState({
            filteredDogs: ctx.getState().filteredDogs,
        });
    }

    @Action(ResetFilteredDogAction)
    resetFilterDogs(ctx: StateContext<DogStateModel>, action: ResetFilteredDogAction): any {
        ctx.patchState({
            filteredDogs: ctx.getState().dogs,
            filterOptions: undefined
        });
    }

    @Action(MoveRipFalse)
    moveRipfalse(ctx: StateContext<DogStateModel>, action: MoveRipFalse): any {
        ctx.patchState({
            moveToRip: false
        });
    }


    @Action(GetDogsFavoritesCountById)
    getSelectedDogsFavoritesCount(ctx: StateContext<DogStateModel>, action: GetDogsFavoritesCountById): any {
        // ctx.patchState({ loading: true });
        return this.dogService.getSelectedDogsFavoritesCount(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            // loading: false,
                            favoriteCountById: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

}
