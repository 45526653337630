<div class="test-dialog-content" *ngIf="showUIEle" [ngClass]="{'d-none': !showPPTModal}">
    <div class="sticky-test-header" [ngClass]="{'d-none': !showPPTModal}">
        <app-person-test-main-header></app-person-test-main-header>
        <app-person-progress-bar [value]="calculatePercentage()"></app-person-progress-bar>
        <div class="d-flex fur-hund-container">
            <div class="fur-hund-position"></div>
            <div class="mr3 d-flex furPerson p1 justify-content-center align-items-center">
                <img class="mr1" src="assets/img/accounticon.png" width="15px" height="15px" />
                <div class="fur-hund-title font-color-white">für Hundefreunde</div>
            </div>
        </div>
    </div>

    <div #furhundbody class="fur-hund-body" [ngClass]="{'d-none': !showPPTModal}">
        <ng-container [ngSwitch]="currentPage$ | async">
            <div #switchContent class="switch-content">
                <app-people-test-info-popup *ngSwitchCase="1"
                    (paymentPopupToggle)="showPPTModal= $event == true ? false:true"></app-people-test-info-popup>
                <app-person-test-page-one *ngSwitchCase="2"></app-person-test-page-one>
                <app-person-test-page-two *ngSwitchCase="3"></app-person-test-page-two>
                <app-person-test-page-three *ngSwitchCase="4"></app-person-test-page-three>
                <app-person-test-page-four *ngSwitchCase="5"></app-person-test-page-four>
                <app-person-test-page-five *ngSwitchCase="6"></app-person-test-page-five>
            </div>
        </ng-container>
    </div>

</div>
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 1)" template="<img src='assets/img/loader-new.gif' />">
</ngx-spinner> -->