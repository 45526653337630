import { Injectable } from '@angular/core';
import { DogModel } from '@core/models/dog.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DocumentModel } from '@core/models/document.model';
import { ProductService } from '../../services/product.service';
import { CheckFilterPaymentForDog, CheckFilterPaymentForPerson, GetBookingHistoryData, GetProductDetailAction, GetPurchaseHistoryData, GetSubscriptionPlanByType, GetUserCurrentProductPaymentsData, PurchasePlanUsingGoodies, SendIdsForManageSubscriptionOnPublish } from './product.action';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
export interface ProductStateModel {
    products: any;
    error?: any;
    loading: boolean;
    subscriptionPlan: any;
    dogFilterPaymentStatus: Boolean,
    personFilterPaymentStatus: Boolean
    purchaseHistoryData: any;
    bookingHistoryData: any;
    activePayments: any[];
}

const defaultState: ProductStateModel = {
    products: undefined,
    error: undefined,
    loading: false,
    subscriptionPlan: undefined,
    dogFilterPaymentStatus: false,
    personFilterPaymentStatus: false,
    purchaseHistoryData : [],
    bookingHistoryData : [],
    activePayments: [],
};

@State<ProductStateModel>({
    name: 'products',
    defaults: defaultState
})
@Injectable()
export class ProductState {

    @Selector()
    public static getState(state: ProductStateModel): ProductStateModel {
        return state;
    }

    @Selector()
    public static loading(state: ProductStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: ProductStateModel): any {
        return state.error;
    }

    @Selector()
    public static getSubscriptionPlanByType(state: ProductStateModel): Array<any> {
        return state.subscriptionPlan;
    }

    @Selector()
    public static checkFilterPaymentForDog(state: ProductStateModel): Boolean {
        return state.dogFilterPaymentStatus;
    }

    @Selector()
    public static checkFilterPaymentForPerson(state: ProductStateModel): Boolean {
        return state.personFilterPaymentStatus;
    }

    @Selector()
    public static getPurchaseHistoryData(state: ProductStateModel): Boolean {
        return state.purchaseHistoryData;
    }

    @Selector()
    public static getBookingHistoryData(state: ProductStateModel): Boolean {
        return state.bookingHistoryData;
    }

    @Selector()
    public static getActivePayments(state: ProductStateModel): any[] {
        return state.activePayments;
    }


    constructor(
        private productService: ProductService,
        private toastr: ToastrNotificationService,
    ) { }

    @Action(GetProductDetailAction)
    getDocument(ctx: StateContext<ProductStateModel>, action: GetProductDetailAction): any {
        ctx.patchState({ loading: true });
        return this.productService.getProductDetail()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        if (response.success) {
                            ctx.patchState({
                                products: response.data,
                                error: undefined,
                                loading: false,
                            });
                        }
                        // const dogDetail = ctx.getState().dogDetail as DogModel;
                        // const dogDetailOther = {
                        //     ...dogDetail,
                        //     description: action.model.description
                        // } as DogModel;
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    // get subscription plan by type
    @Action(GetSubscriptionPlanByType)
    getSubscriptionPlanByType(ctx: StateContext<ProductStateModel>, action: GetSubscriptionPlanByType): any {
        // ctx.patchState({ loading: true });
        return this.productService.getSubscriptionPlanByType(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            subscriptionPlan: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        // loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(CheckFilterPaymentForDog)
    checkFilterPaymentForDog(ctx: StateContext<ProductStateModel>, action: CheckFilterPaymentForDog): any {
        ctx.patchState({ loading: true });
        return this.productService.checkFilterPaymentForDog()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {

                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            dogFilterPaymentStatus: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(CheckFilterPaymentForPerson)
    checkFilterPaymentForPerson(ctx: StateContext<ProductStateModel>, action: CheckFilterPaymentForPerson): any {
        ctx.patchState({ loading: true });
        return this.productService.checkFilterPaymentForPerson()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {

                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            personFilterPaymentStatus: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(PurchasePlanUsingGoodies)
    purchasePlanUsingGoodies(ctx: StateContext<ProductStateModel>, action: PurchasePlanUsingGoodies): any {
        // ctx.patchState({ loading: true });
        return this.productService.purchasePlanUsingGoodies(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        this.toastr.showSuccess('', response?.message || '');
                        ctx.patchState({
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    this.toastr.showHttpError(err);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(SendIdsForManageSubscriptionOnPublish)
    sendIdsForManageSubscriptionOnPublish(ctx: StateContext<ProductStateModel>, action: SendIdsForManageSubscriptionOnPublish): any {
        return this.productService.sendIdsForManageSubscriptionOnPublish(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        this.toastr.showSuccess('', response?.message || '');
                        ctx.patchState({
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    this.toastr.showHttpError(err);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetPurchaseHistoryData)
    getPurchaseHistoryData(ctx: StateContext<ProductStateModel>, action: GetPurchaseHistoryData): any {
        // ctx.patchState({ loading: true });
        return this.productService.purchaseHistoryData(action.page, action.length)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            purchaseHistoryData: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetBookingHistoryData)
    getBookingHistoryData(ctx: StateContext<ProductStateModel>, action: GetBookingHistoryData): any {
        // ctx.patchState({ loading: true });
        return this.productService.bookingHistoryData(action.page, action.length)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            bookingHistoryData: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetUserCurrentProductPaymentsData)
    getUserCurrentProductPaymentsData(ctx: StateContext<ProductStateModel>, action: GetUserCurrentProductPaymentsData): any {
        return this.productService.getUserCurrentProductPaymentsData()
        .pipe(
            tap((response: ResponseBeanModel) => {
                if (response.success) {
                    ctx.patchState({
                        activePayments: response.data
                    });
                }
            }),
            catchError((err) => {
                ctx.patchState({
                    error: err,
                    loading: false
                });
                return throwError(err);
            })
        );
    }

}
