import { Injectable } from '@angular/core';
import { AddDogModel } from '@core/models/add-dog';
import { CountryStateModel } from '@core/models/country-state.model';
import { DogDropDownModel } from '@core/models/dog-dropdown.model';
import { EditUserProfileModel } from '@core/models/edit-user-profile.model';
import { ImageGallaryModel } from '@core/models/image-gallary.model';
import { NotificationsModel } from '@core/models/notifications.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { SearchUserListModel } from '@core/models/search-user-list.model';
import { ShareDogModel } from '@core/models/share-dog.model';
import { UserModel } from '@core/models/user.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CareService } from 'app/profile/components/dog-person-relation/components/dog-sharing/shared/services/care.service';
import { cloneDeep } from 'lodash';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DogService } from '../../../shared/services/dogs.service';
import { UserService } from '../../../shared/services/user.service';
import { AdvertisementService } from '../../services/advertisement.service';
import { NotificationService } from '../../services/notification.service';
import { ProductService } from '../../services/product.service';
import { ShareDogService } from '../../services/share-dog.service';
import { UploadUserImageService } from '../../services/upload-user-image.service';
import { ChatListModel } from '../models/chat-list.model';
import {
    ALLBreedDogAction,
    AddDogAction,
    AddToFavouriteForAllAds,
    ChangeChatInitiatedStatus,
    ChangeKeyValue,
    ChangeLoggedInUserData,
    CheckChatPaymentForUSer,
    DogShareAcceptReject,
    EditUserProfileAction,
    FirstVisitComplete,
    GetAllCountries,
    GetAllUsersData,
    GetChatListData,
    GetDogShareMatchingResultDiscount,
    GetGoodiesDiscount,
    GetInitialCredit,
    GetSubscriptionDiscount,
    GetUserAdsListData,
    HeaderValueChange,
    IncreaseAdvertisementCountAction,
    LanguageChangeAction,
    LanguagesEnumType,
    LifeStyleDescription,
    LifeStyleImageDelete,
    LifeStyleImageUpdate,
    LifeStyleImageUpload,
    LifeStyleVideoDelete,
    LifeStyleVideoUpload,
    LoadUserAction,
    ReadNotificationAction,
    SearchUserAction,
    SessionTokenCheck,
    SetActiveDogAction,
    SetImageAction,
    SetLoaderState,
    SetRedDot,
    ShareDogAction,
    SharePageVisited,
    UpdateDogAction,
    UpdateDogDropDownAction,
    ViewAdvertisementAction,
    ViewAdvertisementActionBeforeLogin,
    RegisterviaDogTest,
    GetUserWithAdsAction
} from './user.actions';
import { IAllUsersList } from '../../interfaces/user.interface';
export interface UserStateModel {
    user?: UserModel;
    dogs?: DogDropDownModel[];
    accessToken?: string;
    error?: any;
    loading: boolean;
    activeDog?: any;
    notifications?: Array<NotificationsModel>;
    language: LanguagesEnumType;
    breed?: [];
    searchedUser?: Array<SearchUserListModel>;
    isFirstVisit: boolean;
    countries?: Array<any>;
    chatListData: Array<ChatListModel>;
    redDot: boolean;
    chatInitiated: boolean;
    userWiseAdsData: any;
    subscriptionDiscount: undefined;
    advertisement: any;
    count: any;
    goodiesDiscount: undefined;
    dogShareMatchingResultDiscount: undefined;
    AllUsersList: IAllUsersList,
    changedLoggedInUser: any;
    chatPaymentStatus: Boolean;
    updateProfileFlag: number;
    diaryDogs: any;
    publicUser?: any;
}

const defaultState: UserStateModel = {
    user: undefined,
    dogs: undefined,
    accessToken: undefined,
    error: undefined,
    loading: false,
    language: 'en',
    activeDog: undefined,
    notifications: undefined,
    breed: undefined,
    searchedUser: undefined,
    isFirstVisit: true,
    countries: undefined,
    chatListData: [],
    redDot: false,
    chatInitiated: true,
    userWiseAdsData: [],
    subscriptionDiscount: undefined,
    goodiesDiscount: undefined,
    dogShareMatchingResultDiscount: undefined,
    advertisement: [],
    count: undefined,
    AllUsersList: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        users: [],
    },
    changedLoggedInUser: undefined,
    chatPaymentStatus: false,
    updateProfileFlag: 0,
    diaryDogs: [],
    publicUser: undefined,
};

@State<UserStateModel>({
    name: 'profileUserMain',
    defaults: defaultState
})
@Injectable()
export class UserState {

    @Selector()
    public static getState(state: UserStateModel): UserStateModel {
        return state;
    }

    @Selector()
    public static user(state: UserStateModel): UserModel | undefined {
        return state.user;
    }

    @Selector()
    public static dogs(state: UserStateModel): DogDropDownModel[] | undefined {
        return state.dogs;
    }

    @Selector()
    public static diaryDogs(state: UserStateModel): DogDropDownModel[] | undefined {
        return state.diaryDogs;
    }

    @Selector()
    public static breeds(state: UserStateModel): any | undefined {
        return state.breed;
    }

    @Selector()
    public static language(state: UserStateModel): LanguagesEnumType {
        return state.language;
    }

    @Selector()
    public static searchedUser(state: UserStateModel): undefined | Array<any> {
        return state.searchedUser;
    }

    @Selector()
    public static notifications(state: UserStateModel): undefined | Array<NotificationsModel> {
        return state.notifications;
    }

    @Selector()
    public static loading(state: UserStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: UserStateModel): any {
        return state.error;
    }

    @Selector()
    public static activeDog(state: UserStateModel): any {
        return state.activeDog;
    }

    @Selector()
    public static isFirstVisit(state: UserStateModel): any {
        return state.isFirstVisit;
    }

    @Selector()
    public static countries(state: UserStateModel): undefined | Array<CountryStateModel> {
        return state.countries;
    }

    @Selector()
    public static chatListData(state: UserStateModel): Array<ChatListModel> {
        return state.chatListData;
    }

    @Selector()
    public static redDot(state: UserStateModel): boolean {
        return state.redDot;
    }

    @Selector()
    public static chatInitiated(state: UserStateModel): boolean {
        return state.chatInitiated;
    }

    @Selector()
    public static userWiseAdsData(state: UserStateModel): Array<any> {
        return state.userWiseAdsData;
    }

    @Selector()
    public static subscriptionDiscount(state: UserStateModel): any {
        return state.subscriptionDiscount;
    }

    @Selector()
    public static goodiesDiscount(state: UserStateModel): any {
        return state.goodiesDiscount;
    }

    @Selector()
    public static dogShareMatchingResultDiscount(state: UserStateModel): any {
        return state.dogShareMatchingResultDiscount;
    }

    @Selector()
    public static advertisement(state: UserStateModel): any {
        return state.advertisement;
    }

    @Selector()
    public static count(state: UserStateModel): any {
        return state.count;
    }

    @Selector()
    public static AllUsersDataForMasterAccess(state: UserStateModel): IAllUsersList  {
        return state.AllUsersList;
    }


    @Selector()
    public static changeUSerLoggedIn(state: UserStateModel): Array<any> {
        return state.changedLoggedInUser;
    }

    @Selector()
    public static checkChatPaymentStatusForUser(state: UserStateModel): Boolean {
        return state.chatPaymentStatus;
    }

    @Selector()
    public static updateProfileFlag(state: UserStateModel): any {
        return state.updateProfileFlag;
    }


    constructor(
        private imageService: UploadUserImageService,
        private userService: UserService,
        private advertisementService: AdvertisementService,
        private dogService: DogService,
        private notification: NotificationService,
        private shareDogService: ShareDogService,
        private toastr: ToastrNotificationService,
        private careService: CareService,
        private productService: ProductService
    ) { }

    @Action(SetLoaderState)
    setLoaderState(ctx: StateContext<UserStateModel>, action: SetLoaderState): any {
        ctx.patchState({
            loading: action.model
        });
    }

    @Action(SetImageAction)
    setImage(ctx: StateContext<UserStateModel>, action: SetImageAction): any {
        ctx.patchState({ loading: true });
        return this.imageService.create(action.file, action.image)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const user = ctx.getState().user as UserModel;
                    const userOther = action.image === 0 ? {
                        ...user,
                        coverImage: response.data.coverImage
                    } as UserModel : {
                        ...user,
                        profilePhoto: response.data.profilePhoto
                    } as UserModel;

                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        user: userOther
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    // @Action(SetProfileImageAction)
    // profileImage(ctx: StateContext<UserStateModel>, action: SetProfileImageAction): any {
    //     ctx.patchState({ loading: true });
    //     return this.imageService.create(action.file, action.image)
    //         .pipe(
    //             tap((response: ResponseBeanModel) => {
    //                 const user = ctx.getState().user as UserModel;
    //                 const userOther = {
    //                     ...user,
    //                     profilePhoto: response.data.profilePhoto
    //                 } as UserModel;
    //                 ctx.patchState({
    //                     error: undefined,
    //                     loading: false,
    //                     user: userOther
    //                 });
    //             }),
    //             catchError((err) => {
    //                 ctx.patchState({
    //                     error: err,
    //                 });
    //                 return throwError(err);
    //             }),
    //             finalize(() => {
    //                 ctx.patchState({ loading: false });
    //             })
    //         );
    // }

    sortingDogs(): any {
        return (a: DogDropDownModel, b: DogDropDownModel) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        };
    }

    @Action(LoadUserAction)
    loadUser(ctx: StateContext<UserStateModel>, action: LoadUserAction): any {
        ctx.patchState({ loading: action.model ? true : false });
        return this.userService.getUser()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const sharedData = response.data?.dogs.filter((d: DogDropDownModel) => d.isShared && d.category.name !== 'rip');
                    const ownerData = response.data?.dogs.filter((d: DogDropDownModel) => !d.isShared && d.category.name !== 'rip');
                    ownerData?.sort(this.sortingDogs());
                    sharedData?.sort(this.sortingDogs());
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        user: response.data,
                        dogs: ownerData?.concat(sharedData),
                        notifications: response.data?.notification,
                        diaryDogs: response.data?.dogs
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(EditUserProfileAction)
    editUserProfile(ctx: StateContext<UserStateModel>, action: EditUserProfileAction): any {
        ctx.patchState({ loading: true });
        return this.userService.editUserProfile(action.model as EditUserProfileModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    ctx.patchState({
                        error: undefined,
                        // loading: false,
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    // ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetActiveDogAction)
    setActiveDog(ctx: StateContext<UserStateModel>, action: SetActiveDogAction): any {
        ctx.patchState({
            activeDog: action.model
        });
    }

    @Action(AddDogAction)
    addDog(ctx: StateContext<UserStateModel>, action: AddDogAction): any {
        ctx.patchState({ loading: true });
        return this.dogService.dogAdd(action.model as AddDogModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    this.toastr.showSuccess('', response.message as string);
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ALLBreedDogAction)
    getAllBreed(ctx: StateContext<UserStateModel>, action: ALLBreedDogAction): any {
        // ctx.patchState({ loading: true });
        return this.dogService.getAllBreeds()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    // console.log('response.data 236', response.data);
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        breed: response.data
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(UpdateDogAction)
    updateDog(ctx: StateContext<UserStateModel>, action: UpdateDogAction): any {
        ctx.patchState({ loading: true });
        return this.dogService.dogUpdate(action.id, action.model as AddDogModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const dogs = ctx.getState().dogs;
                    const otherDogs = dogs?.map((a: any) => ({ ...a }));
                    otherDogs?.map((dog: any) => {
                        if (dog._id === action.id) {
                            dog.name = action.model.name;
                        }
                    });
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        dogs: otherDogs
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(UpdateDogDropDownAction)
    updateDogDropDown(ctx: StateContext<UserStateModel>, action: UpdateDogDropDownAction): any {
        const dogs = ctx.getState().dogs;

        const otherDogs = dogs?.map((a: any) => ({ ...a }));
        otherDogs?.map((dog: any) => {
            if (dog._id === action.model.id) {
                dog.profilePhoto = action.model.profilePhoto;
            }
        });
        ctx.patchState({
            dogs: otherDogs
        });
    }

    @Action(SearchUserAction)
    searchuser(ctx: StateContext<UserStateModel>, action: SearchUserAction): any {
        return this.userService.searchUser(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            searchedUser: response.data as SearchUserListModel[]
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ReadNotificationAction)
    readNotification(ctx: StateContext<UserStateModel>, action: ReadNotificationAction): any {
        return this.notification.readNotification(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ShareDogAction)
    shareDogAction(ctx: StateContext<UserStateModel>, action: ShareDogAction): any {
        ctx.patchState({ loading: true });
        return this.shareDogService.shareDog(action.model as ShareDogModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    // console.log('391 response', response);
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                        this.toastr.showSuccess('', response.message as string);
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(DogShareAcceptReject)
    dogShareAcceptReject(ctx: StateContext<UserStateModel>, action: DogShareAcceptReject): any {
        ctx.patchState({ loading: true });
        return this.userService.shareDogAcceptOrReject(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LifeStyleImageUpload)
    lifeStyleImageUpload(ctx: StateContext<UserStateModel>, action: LifeStyleImageUpload): any {
        ctx.patchState({ loading: true });
        const { file } = action.model;
        return this.userService.lifeStyleImageUpload(file)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    // console.log('440 response', response);
                    const imageArray = ctx.getState().user?.imageGallery as ImageGallaryModel[];
                    const oldImageArray = [...imageArray];
                    const newImageArray = oldImageArray?.concat(response.data as ImageGallaryModel[]);
                    const userOther = {
                        ...ctx.getState().user,
                        imageGallery: newImageArray
                    } as UserModel;
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        user: userOther
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LifeStyleVideoUpload)
    lifeStyleVideoUpload(ctx: StateContext<UserStateModel>, action: LifeStyleVideoUpload): any {
        ctx.patchState({ loading: true });
        const { file } = action.model;
        return this.userService.lifeStyleVideoUpload(file)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const userDetail = ctx.getState().user as UserModel;
                        // console.log('477 response', response.data);
                        const userDetailOther = {
                            ...userDetail,
                            videoLink: response.data
                        };
                        // console.log('481 videoLink', userDetailOther);
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            user: userDetailOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LifeStyleDescription)
    lifeStyleDescription(ctx: StateContext<UserStateModel>, action: LifeStyleDescription): any {
        ctx.patchState({ loading: true });
        return this.userService.lifeStyleDescription(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    const userDetail = ctx.getState().user as UserModel;
                    const userDetailOther = {
                        ...userDetail,
                        description: action.model.description
                    } as UserModel;
                    // console.log('510 userDetailOther', userDetailOther);
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        user: userDetailOther
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LifeStyleImageUpdate)
    lifeStyleImageUpdate(ctx: StateContext<UserStateModel>, action: LifeStyleImageUpdate): any {
        ctx.patchState({ loading: true });
        const { file } = action.model;
        return this.userService.lifeStyleImageUpdate(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {

                    if (response.success) {
                        const { id } = action.model;
                        const imageArray = ctx.getState()?.user?.imageGallery as ImageGallaryModel[];
                        const updateImageArray = [...imageArray];
                        const index = updateImageArray.findIndex((image: ImageGallaryModel) => image._id === id);
                        updateImageArray[index] = response.data;
                        const userOther = {
                            ...ctx.getState().user,
                            imageGallery: updateImageArray
                        } as UserModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            user: userOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LifeStyleImageDelete)
    lifeStyleImageDelete(ctx: StateContext<UserStateModel>, action: LifeStyleImageDelete): any {
        ctx.patchState({ loading: true });
        return this.userService.lifeStyleImageDelete(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const id = action.model;
                        const imageArray = ctx.getState().user?.imageGallery as ImageGallaryModel[];
                        const updatedArray = imageArray?.filter(image => image._id !== id);
                        const userOther = {
                            ...ctx.getState().user,
                            imageGallery: updatedArray
                        } as UserModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            user: userOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LifeStyleVideoDelete)
    lifeStyleVideoDelete(ctx: StateContext<UserStateModel>, action: LifeStyleVideoDelete): any {
        ctx.patchState({ loading: true });
        return this.userService.lifeStyleVideoDelete()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const userDetail = ctx.getState().user as UserModel;
                        const userDetailOther = {
                            ...userDetail,
                            videoLink: ''
                        };
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            user: userDetailOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(LanguageChangeAction)
    languageChange(ctx: StateContext<UserStateModel>, action: LanguageChangeAction): any {
        ctx.patchState({
            language: action.model
        });
    }

    @Action(FirstVisitComplete)
    firstVisitComplete(ctx: StateContext<UserStateModel>, action: FirstVisitComplete): any {
        ctx.patchState({ loading: false });
        return this.userService.firstVisitComplete({ isFirstVisit: false })
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const userDetail = ctx.getState().user as UserModel;
                        const userDetailOther = {
                            ...userDetail,
                            isFirstVisit: false
                        } as UserModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            user: userDetailOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(RegisterviaDogTest)
    registerviaDogTest(ctx: StateContext<UserStateModel>): any {
        ctx.patchState({ loading: false });
        return this.userService.registerviaDogTest()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const userDetail = ctx.getState().user as UserModel;
                        
                        const userDetailOther = {
                            ...userDetail,
                            registerViaDogTests: response.data?.registerViaDogTests
                        } as UserModel;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            user: userDetailOther
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(GetAllCountries)
    getAllCountries(ctx: StateContext<UserStateModel>, action: GetAllCountries): any {
        // ctx.patchState({ loading: true });
        return this.userService.getAllCountries()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            countries: response.data,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetInitialCredit)
    getInitialCredit(ctx: StateContext<UserStateModel>, action: GetInitialCredit): any {
        // ctx.patchState({ loading: true });
        return this.userService.getInitialCredit(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SessionTokenCheck)
    sessionTokenCheck(ctx: StateContext<UserStateModel>, action: SessionTokenCheck): any {
        return this.userService.sessionTokenCheck()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SharePageVisited)
    sharePageVisited(ctx: StateContext<UserStateModel>, action: SharePageVisited): any {
        return this.userService.sharePageVisited(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    // ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetChatListData)
    getChatListData(ctx: StateContext<UserStateModel>, action: GetChatListData): any {
        return this.userService.getChatListData()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            chatListData: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetRedDot)
    setRedDot(ctx: StateContext<UserStateModel>, action: SetRedDot): any {
        ctx.patchState({
            redDot: action.model
        });
    }

    @Action(ChangeChatInitiatedStatus)
    changeChatInitiatedStatus(ctx: StateContext<UserStateModel>, action: SetRedDot): any {
        ctx.patchState({
            chatInitiated: action.model
        });
    }

    @Action(ChangeKeyValue)
    ChangeKeyValue(ctx: StateContext<UserStateModel>, action: ChangeKeyValue): any {
        const userDetail = ctx.getState().user as UserModel;
        // console.log('477 response', response.data);
        const userDetailOther = {
            ...userDetail,
            [action.model.key]: action.model.value
        };
        // console.log('824', userDetailOther);
        ctx.patchState({
            user: userDetailOther,
            error: undefined,
            loading: false,
        });
    }

    @Action(GetUserAdsListData)
    getUserAdsListData(ctx: StateContext<UserStateModel>, action: GetUserAdsListData): any {
        ctx.patchState({ loading: true });
        return this.userService.getUserAdsListData(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            userWiseAdsData: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(GetAllUsersData)
    getAllUSersList(ctx: StateContext<UserStateModel>, action: GetAllUsersData): any {
        // ctx.patchState({ loading: true });
        return this.userService.getAllUSersList(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            AllUsersList: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(ChangeLoggedInUserData)
    changeUserLoggedInByUserId(ctx: StateContext<UserStateModel>, action: ChangeLoggedInUserData): any {
        // ctx.patchState({ loading: true });
        return this.userService.changeUserLoggedInByUserId(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            changedLoggedInUser: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(AddToFavouriteForAllAds)
    AddToFavouriteForAllAds(ctx: StateContext<UserStateModel>, action: AddToFavouriteForAllAds): any {
        ctx.patchState({ loading: true });
        return this.careService.addToFavourite({ id: action.model.id, type: action.model.type, add: action.model.add })
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const deepCopyInsideData = cloneDeep(ctx.getState().userWiseAdsData) as any;
                        deepCopyInsideData[deepCopyInsideData.findIndex((i: any) => i._id === action.model.id)].favourite = action.model.add;
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            userWiseAdsData: deepCopyInsideData
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }



    @Action(GetSubscriptionDiscount)
    getSubscriptionDiscount(ctx: StateContext<UserStateModel>, action: GetSubscriptionDiscount): any {
        ctx.patchState({ loading: true });
        // console.log('992');

        return this.userService.getSubscriptionDiscount(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            subscriptionDiscount: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ViewAdvertisementAction)
    viewAds(ctx: StateContext<UserStateModel>, action: ViewAdvertisementAction): any {
        ctx.patchState({ loading: true });
        // console.log('992');
        // console.log(action.model);

        return this.advertisementService.viewAds(action.model as string)
            .pipe(
                tap((response: any) => {
                    if (response.success) {
                        ctx.patchState({
                            advertisement: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    @Action(ViewAdvertisementActionBeforeLogin)
    viewAdsBeforeLogin(ctx: StateContext<UserStateModel>, action: ViewAdvertisementActionBeforeLogin): any {
        ctx.patchState({ loading: true });
        // console.log('992');
        // console.log(action.model);

        return this.advertisementService.viewAdsBeforLogin(action.model as string)
            .pipe(
                tap((response: any) => {
                    if (response.success) {
                        ctx.patchState({
                            advertisement: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(IncreaseAdvertisementCountAction)
    increaseCount(ctx: StateContext<UserStateModel>, action: IncreaseAdvertisementCountAction): any {
        ctx.patchState({ loading: true });
        // console.log('992');
        // console.log(action.model);

        return this.advertisementService.increaseCount(action.model as string)
            .pipe(
                tap((response: any) => {
                    if (response.success) {
                        ctx.patchState({
                            count: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetGoodiesDiscount)
    getGoodiesDiscount(ctx: StateContext<UserStateModel>, action: GetGoodiesDiscount): any {
        ctx.patchState({ loading: true });
        // console.log(action, "1120");
        return this.userService.getSubscriptionDiscount(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            goodiesDiscount: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetDogShareMatchingResultDiscount)
    dogShareMatchingResultDiscount(ctx: StateContext<UserStateModel>, action: GetDogShareMatchingResultDiscount): any {
        ctx.patchState({ loading: true });
        const payload = {type : "dogCare", subType: action.model}
        return this.userService.getSubscriptionDiscount(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            dogShareMatchingResultDiscount: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }


    /**
 * check chat payment done or not user wise
 * @param ctx 
 * @param action 
 * @returns 
 */
    @Action(CheckChatPaymentForUSer)
    checkChatPaymentStatusForUser(ctx: StateContext<UserStateModel>, action: CheckChatPaymentForUSer): any {
        ctx.patchState({ loading: true });
        return this.userService.checkChatPaymentStatusForUser(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        
                        ctx.patchState({
                            error: undefined,
                            // loading: false,
                            chatPaymentStatus: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(HeaderValueChange)
    headerValueChange(ctx: StateContext<UserStateModel>, action: HeaderValueChange): any {
        ctx.patchState({
            updateProfileFlag: ctx.getState().updateProfileFlag + 1
        });
    }

    @Action(GetUserWithAdsAction)
    getSingleProfileWithAds(ctx: StateContext<UserStateModel>, action: GetUserWithAdsAction): any {
        return this.userService.getUserWithAds(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            publicUser: response.data,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    // ctx.patchState({ loading: false });
                })
            );
    }

}
