import { EditImageGallaryModel } from '@core/models/edit-image-gallary.model';
import { EditUserProfileModel } from '@core/models/edit-user-profile.model';
import { ShareDogModel } from '@core/models/share-dog.model';
import { GetUserWithAdsModel } from '../models/chat-list.model';


export enum LanguagesEnum { 'en', 'de' }
export type LanguagesEnumType = keyof typeof LanguagesEnum;
export class LoadUserAction {
    static readonly type = '[User] Load User';
    constructor(public model = true) { }
}

export class SetAllNullUserAction {
    static readonly type = '[User] Set Null User';
    constructor() { }
}

export class SetImageAction {
    static readonly type = '[User] Set Image';
    constructor(public file: Blob | File, public image: number) { }
}
export class EditUserProfileAction {
    static readonly type = '[User] Edit User Profile';
    constructor(public model: EditUserProfileModel) { }
}
export class SetActiveDogAction {
    static readonly type = '[User] Set Active Dog';
    constructor(public model: any) { }
}

export class AddDogAction {
    static readonly type = '[User] Add Dog';
    constructor(public model: any) { }
}

export class UpdateDogAction {
    static readonly type = '[User] Update Dog';
    constructor(public id: string, public model: any) { }
}

export class ALLBreedDogAction {
    static readonly type = '[User] All Breeds Dog';
    constructor() { }
}
export class LanguageChangeAction {
    static readonly type = '[User] Language Change';
    constructor(public model: LanguagesEnumType) { }
}
export class UpdateDogDropDownAction {
    static readonly type = '[User] Dog DropDown';
    constructor(public model: any) { }
}

export class SearchUserAction {
    static readonly type = '[User] search user';
    constructor(public model: string) { }
}

export class ShareDogAction {
    static readonly type = '[User] share dog';
    constructor(public model: ShareDogModel) { }
}

export class ReadNotificationAction {
    static readonly type = '[User] Read Notification';
    constructor(public model: Array<string>) { }
}

export class DogShareAcceptReject {
    static readonly type = '[User] Sharedog Accept reject';
    constructor(public model: object) { }
}
export class FirstVisitComplete {
    static readonly type = '[User] First Visit complete';
    constructor() { }
}

export class RegisterviaDogTest {
    static readonly type = '[User] Register Via Dog Test';
    constructor() { }
}

export class GetInitialCredit {
    static readonly type = '[User] Get Initial Credits';
    constructor(public model: { country: string, state: string }) { }
}
export class GetAllCountries {
    static readonly type = '[User] Get All Countries';
    constructor() { }
}

export class LifeStyleImageUpload {
    static readonly type = '[User] Lifestyle Image Upload';
    constructor(public model: { file: Array<Blob | File> }) { }
}

export class LifeStyleVideoUpload {
    static readonly type = '[User] Lifestyle Video Upload';
    constructor(public model: { file: Blob | File }) { }
}

export class LifeStyleDescription {
    static readonly type = '[User] Lifestyle Description';
    constructor(public model: { description: string }) { }
}

export class LifeStyleImageUpdate {
    static readonly type = '[User] Lifestyle Image Update';
    constructor(public model: EditImageGallaryModel) { }
}

export class LifeStyleImageDelete {
    static readonly type = '[User] Lifestyle Image Delete';
    constructor(public model: string) { }
}
export class LifeStyleVideoDelete {
    static readonly type = '[User] Lifestyle Video Delete';
    constructor() { }
}

export class SessionTokenCheck {
    static readonly type = '[User] Session Check';
    constructor() { }
}

export class SetLoaderState {
    static readonly type = '[User] Set Loader State';
    constructor(public model: boolean) { }
}

export class SharePageVisited {
    static readonly type = '[User] Share Page Visited';
    constructor(public model: { [key: string]: boolean }) { }
}
export class GetChatListData {
    static readonly type = '[User] Get Chat List Data';
    constructor() { }
}

export class SetRedDot {
    static readonly type = '[User] Set Red Point';
    constructor(public model: boolean) { }
}

export class ChangeChatInitiatedStatus {
    static readonly type = '[User] Change ChangeChatInitiated Status';
    constructor(public model: boolean) { }
}

export class ChangeKeyValue {
    static readonly type = '[User] Change Key Value';
    constructor(public model: { key: string, value: any }) { }
}

export class GetUserAdsListData {
    static readonly type = '[User] Get User Ads List Data';
    constructor(public model: string) { }
}

export class ViewAdvertisementAction {
    static readonly type = '[Advertisement] Get Advertisement';
    constructor(public model: string) { }
}

export class ViewAdvertisementActionBeforeLogin {
    static readonly type = '[Advertisement] Get Advertisement before login';
    constructor(public model: string) { }
}

export class IncreaseAdvertisementCountAction {
    static readonly type = '[Advertisement] Increase Advertisement Count';
    constructor(public model: any) { }
}

export class GetAllUsersData {
    static readonly type = '[User] Get All Users List Data';
    constructor(public model: string) { }
}


export class ChangeLoggedInUserData {
    static readonly type = '[User] Change logged in user data';
    constructor(public model: string) { }
}
export class AddToFavouriteForAllAds {
    static readonly type = '[User] Add To Favourite from adds';
    constructor(public model: { id: string, type: string, add: boolean, insideData?: boolean, index?: number }) {
    }
}

export class GetSubscriptionDiscount {
    static readonly type = '[User] Get User subscription discount';
    constructor(public model: string) { }
}

export class GetGoodiesDiscount {
    static readonly type = '[User] Get User goodies discount';
    constructor(public model: string) { }
}

export class GetDogShareMatchingResultDiscount {
    static readonly type = '[User] Get Dog Share Matching Result discount';
    constructor(public model: string) { }
}

export class CheckChatPaymentForUSer {
    static readonly type = '[Chat] check chat payment for user';
    constructor(public model: { user: string }) {
    }
}

export class HeaderValueChange {
    static readonly type = '[Chat] header value change';
    constructor() {
    }
}

export class GetUserWithAdsAction {
    static readonly type = '[User] Get User With Ads';
    constructor(public model: GetUserWithAdsModel) {
    }
}