import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadDogImageService extends BaseApiService {

  constructor(protected http: HttpClient) {
    super(http);
    this.apiEndpoint = 'dog';
}

  public create(file: Blob | File, image: number, id: string): Observable<any> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('image', String(image));
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'multipart/form-data'
  // });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('PATCH', 'image/' + id, formData, undefined, headers);
  }
}
