import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from 'app/auth/services/auth.service';
import { IncrementPersonTestPage } from 'app/person-test/store/person-test.actions';


@Component({
  selector: 'app-person-test-page-one',
  templateUrl: './person-test-page-one.component.html',
  styleUrls: ['./person-test-page-one.component.scss']
})
export class PersonTestPageOneComponent implements OnInit {

  isAuthenticated = false;

  constructor(
    private store: Store,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  saveAndNext(): void {
    this.store.dispatch(new IncrementPersonTestPage(1));
  }

  back(): void {
    this.store.dispatch(new IncrementPersonTestPage(-1));
  }

}
