<div class="content-container d-flex flex-column">
    <div class="header-text font-color-1 text-center mb2">In wieweit treffen folgende Aussagen auf dich zu?</div>
    <form #form="ngForm" class="d-flex flex-column flex-grow-1 mb-0" (ngSubmit)="saveAndNext(form.value)">
        <paw-checkbox-control *ngFor="let item of questions" [questionId]="item.questionId" [title]="item.question"
            name="{{'questionId' + item.questionId}}" ngDefaultControl [(ngModel)]="item.result"></paw-checkbox-control>

        <!--action : begin-->
        <div class="text-center d-flex justify-content-center align-items-end mt-auto pt-3 mt-3">
            <button class="btn border-color-dark-gray border-2 border-radius-1 bgr-white mr2"
                (click)="back(form.value)">
                <img class="" style="opacity: .5;" src="assets/img/arrow-left.png" width="17px" height="14px" />
            </button>
            <button type="submit" [disabled]="form.invalid" [ngClass]="{'disabled' : form.invalid}"
                class="btn-fill-blue mb-0 p1 primary-btn border-radius-lg pl4 pr4"> Weiter</button>
        </div>
        <!--action : end-->
    </form>
</div>