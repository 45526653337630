<div class="custom-modal">
    <!-- <div class="media"> -->
    <div class="cutsom-modal-header">
        <h4>{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_HEADER_TEXT' | translate}}</h4>
        <!-- <div class="p-4"> -->

        <div class="close-btn">
            <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                viewBox="0 0 40 40">
                <g id="close" transform="translate(-1265 -197)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(1265 197)"
                        fill="#fed66b" />
                    <path id="Path_1" data-name="Path 1"
                        d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                        transform="translate(1278 209.984)" fill="#326291" />
                </g>
            </svg>
        </div>
    </div>
    <div class="custom-modal-body">
        <!-- <div *ngIf="data?.dogPaymentSubscriptionKeys?.emergencyCare === false"> -->
        <ng-container
            *ngIf="data?.dogPaymentSubscriptionKeys?.anonymousAd === true || data?.dogPaymentSubscriptionKeys?.dogNotOnHome === true || data?.personPaymentSubscriptionKeys?.personNotOnHome === true || data?.dogPaymentSubscriptionKeys?.displayLocation === true || data?.personPaymentSubscriptionKeys?.displayLocation">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_BODY_TEXT1' | translate}}
            <span class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)"
                *ngIf="data?.dogPaymentSubscriptionKeys?.anonymousAd">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.THIRD.THIRD.TITLE'
                | translate }}</span><span
                *ngIf="data?.dogPaymentSubscriptionKeys?.anonymousAd && data?.dogPaymentSubscriptionKeys?.dogNotOnHome">
                ,&nbsp;</span>
            <!-- <span class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)"
            *ngIf="data?.dogPaymentSubscriptionKeys?.emergencyCare">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.THIRD.FORTH.TITLE'
            | translate }}</span> -->
            <!-- <span *ngIf="data?.dogPaymentSubscriptionKeys?.anonymousAd && data?.dogPaymentSubscriptionKeys?.dogNotOnHome ">
            ,&nbsp;</span> -->
            <span class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)"
                *ngIf="data?.dogPaymentSubscriptionKeys?.dogNotOnHome || data?.personPaymentSubscriptionKeys?.personNotOnHome">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.THIRD.FIFTH.TITLE'
                | translate }}</span><span
                *ngIf="(data?.dogPaymentSubscriptionKeys?.dogNotOnHome || data?.personPaymentSubscriptionKeys?.personNotOnHome) && (data?.dogPaymentSubscriptionKeys?.displayLocation || data?.personPaymentSubscriptionKeys?.displayLocation)">
                ,&nbsp;</span>
            <span class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)"
                *ngIf="data?.dogPaymentSubscriptionKeys?.displayLocation || data?.personPaymentSubscriptionKeys?.displayLocation">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.THIRD.SEVENTH.TITLE'
                | translate }}</span>
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_BODY_TEXT2' | translate}}
        </ng-container>

        <!-- </div> -->
        <div class="mt-2" *ngIf="data?.dogPaymentSubscriptionKeys?.emergencyCare">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_TEXT1_EMERGENCY' | translate}}
            <span class="font-weight-bold"
                style="color: rgba(0, 0, 0, 0.6)">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.THIRD.FORTH.TITLE'
                | translate }}</span>
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_TEXT2_EMERGENCY' | translate}}
        </div>

        <ng-container class="mt-2" 
            *ngIf="!data?.dogPaymentSubscriptionKeys?.emergencyCare && !data?.dogPaymentSubscriptionKeys?.anonymousAd && !data?.dogPaymentSubscriptionKeys?.dogNotOnHome && !data?.personPaymentSubscriptionKeys?.personNotOnHome && !data?.dogPaymentSubscriptionKeys?.displayLocation && !data?.personPaymentSubscriptionKeys?.displayLocation">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_BODY_TEXT1_FOR_NOPRODUCTS' | translate}}
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_BODY_TEXT2' | translate}}
        </ng-container>
        
        <div class="row mt-4 text-center mr-0">
            <div class="col-md-12 d-flex justify-content-center">
                <button class="btn btn-theme warning-popup-btn btn-fill-yellow mb-0 mr-3" (click)="onCancel()" style="width: 155px;">
                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_CANCEL_TEXT' | translate}}

                </button>
                <!-- </div>
                    <div class="col-md-4 mt-3 mt-md-0 text-center"> -->
                <button class="btn btn-theme warning-popup-btn btn-yes mb-0" (click)="onSubmit()"
                    style="width: auto;">
                    <ng-container
                        *ngIf="data?.dogPaymentSubscriptionKeys?.anonymousAd === true || data?.dogPaymentSubscriptionKeys?.dogNotOnHome === true || data?.personPaymentSubscriptionKeys?.personNotOnHome === true || data?.dogPaymentSubscriptionKeys?.displayLocation === true || data?.personPaymentSubscriptionKeys?.displayLocation === true">
                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_SUBMIT_TEXT' | translate}}
                    </ng-container>
                    <ng-container
                        *ngIf="data?.dogPaymentSubscriptionKeys?.anonymousAd === false && data?.dogPaymentSubscriptionKeys?.dogNotOnHome === false && data?.personPaymentSubscriptionKeys?.personNotOnHome === false && data?.dogPaymentSubscriptionKeys?.displayLocation === false && data?.personPaymentSubscriptionKeys?.displayLocation === false">
                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_SUBMIT_TEXT_FOR_NOPRODUCTS' | translate}}
                    </ng-container>
                    <ng-container
                        *ngIf="data?.dogPaymentSubscriptionKeys?.emergencyCare === true && !data?.dogPaymentSubscriptionKeys?.anonymousAd && !data?.dogPaymentSubscriptionKeys?.dogNotOnHome && !data?.personPaymentSubscriptionKeys?.personNotOnHome && !data?.dogPaymentSubscriptionKeys?.displayLocation && !data?.personPaymentSubscriptionKeys?.displayLocation">
                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.WARNING_SUBMIT_TEXT_EMERGENCY' | translate}}
                    </ng-container>


                </button>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
</div>