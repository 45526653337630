import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DogTestResultsModel } from '../models/dog-test-results.model';

@Injectable({
  providedIn: 'root'
})
export class DogPersonalityTestService extends BaseApiService {
  constructor(
    protected http: HttpClient
  ) {
    super(http);
    this.apiEndpoint = 'dogPersonalityTest'; // PATH to controller or microservice
  }

  setTestSatisfaction(id: string, model: any): Observable<any> {
    return this.makeRequest('PATCH', 'test/' + id, model);
  }
  setDemoTest(id: string, model: any): Observable<any> {
    return this.makeRequest('PATCH', 'test/' + id, model);
  }

  setDogSatisfaction(id: string, model: any): Observable<any> {
    return this.makeRequest('PATCH', 'test/' + id, model);
  }

}
