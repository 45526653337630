<div class="edit-profile-picture-box">
    <div class="media">
        <div class="modal-body">
            <div class="d-flex">
                <h4>{{title | translate}}</h4>
                <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                    viewBox="0 0 40 40">
                    <g id="close" transform="translate(-1265 -197)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                            transform="translate(1265 197)" fill="#fed66b" />
                        <path id="Path_1" data-name="Path 1"
                            d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                            transform="translate(1278 209.984)" fill="#326291" />
                    </g>
                </svg>
            </div>
            <div class="col-12">
                <div class="position-relative ">
                    <image-cropper [imageFile]="imageChangedEvent" [imageURL]="coverImageWeb+'?not-from-cache-please'"
                        (imageCropped)="imageCropped($event)" [maintainAspectRatio]="true"
                        [aspectRatio]="aspectWidth / aspectHeight" [roundCropper]="roundCropper"
                        (cropperReady)="cropperReady()" format="png" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                    <!-- <div *ngIf="showError" class="text-danger">
                        File not supported
                    </div> -->
                    <div class="edit-user-profile-btn">
                        <input #fileInput (change)="onFileChanged($event)" type="file"
                            accept="image/x-png,image/bmp,image/jpeg" class="cover-image-input">
                        <button (click)="fileInput.click()" class="btn btn-fill-yellow ">
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="14"
                                viewBox="0 0 16 14">
                                <path id="Union_1" data-name="Union 1"
                                    d="M-2001,44a2,2,0,0,1-2-2V34a2,2,0,0,1,2-2h1.5l.5-1c.265-.523.448-1,1-1h6c.552,0,.705.418,1,1l.5,1h1.5a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2Zm2-6a4,4,0,0,0,4,4,4,4,0,0,0,4-4,4,4,0,0,0-4-4A4,4,0,0,0-1999,38Zm1,0a3,3,0,0,1,3-3,3,3,0,0,1,3,3,3,3,0,0,1-3,3A3,3,0,0,1-1998,38Z"
                                    transform="translate(2003 -30)" fill="#326291" />
                            </svg>
                            Foto wählen</button>
                    </div>
                </div>

                <button [mat-dialog-close]="true" class="btn-speichern  btn-fill-blue"
                    (click)="onCoverImageUpload()">Speichern</button>
            </div>
        </div>
    </div>
</div>