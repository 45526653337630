import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddDogModel } from '@core/models/add-dog';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import ageMatrix from 'assets/data/age-matrix.json';
import { AddRipDogModel } from '@core/models/add-rip-dog.model';

@Injectable({ providedIn: 'root' })
export class DogService extends BaseApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'dog';
    }

    getAllDogs(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'view', {});
    }

    getAllBreeds(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'breed', {});
    }

    getViewEmergencyDogs(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'emergency-dogs', {});
    }

    dogAdd(model: AddDogModel): Observable<ResponseBeanModel> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.makeRequest('POST', 'create', model, undefined, headers);
    }

    dogUpdate(id: string, model: AddDogModel): Observable<ResponseBeanModel> {
        return this.makeRequest('PATCH', 'profile/' + id, model);
    }

    ripDogAdd(model: AddRipDogModel, id: string): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', 'ripDog/' + id, model);
    }

    hideDog(model: { id: string, isShared: boolean }): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', (model.isShared ? 'hideSharedDog/' : 'hideDog/') + model.id, {});
    }

    deleteDog(model: { id: string, isShared: boolean }): Observable<ResponseBeanModel> {
        return this.makeRequest('DELETE', (model.isShared ? 'sharedinfo/' : 'deleteDog/') + model.id, {});
    }

    getSelectedDogsFavoritesCount(model: any): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', ('favDogCount'), { dog: model });
    }

    calculateDogAgeFromMatrix(birthdayInputValue: any, sizeInputValue: string, ripDate = ''): any {
        let dogAgeInHumanAge = {};
        const b = moment(birthdayInputValue);
        // const monthDiff = moment().diff(b, 'months');
        let monthDiff;
        // console.log('53', ripDate);
        if (ripDate) {
            monthDiff = moment(ripDate, 'DD/MM/YYYY').diff(b, 'months');
        } else {
            monthDiff = moment().diff(b, 'months');
        }
        let year = Math.floor(monthDiff / 12);
        let month = monthDiff % 12;
        if (month === 0 && year === 1) {
            year = year - 1;
            month = monthDiff;
        } else if (month === 0) {
            year = year - 1;
            month = 12;
        }
        const yearIdx = year;
        const yearValues = ageMatrix.data[yearIdx];
        const monthIdx = month > 0 ? month - 1 : month;
        if (yearValues && yearValues[monthIdx]) {
            const monthValues = yearValues[monthIdx];
            switch (sizeInputValue) {
                case 'xs':
                    if (monthValues[0].includes(',')) {
                        const parts = monthValues[0].split(',').map(Number);
                        // const age = parts[0] + ' Jahre ' + parts[1] + ' Monat';
                        dogAgeInHumanAge = {
                            year: parts[0],
                            month: parts[1]
                        };
                    } else {
                        dogAgeInHumanAge = {
                            year: parseInt(monthValues[0], 10),
                        };
                    }
                    break;
                case 's':
                    if (monthValues[0].includes(',')) {
                        const parts = monthValues[0].split(',').map(Number);;
                        // const age = parts[0] + ' Jahre ' + parts[1] + ' Monat';
                        dogAgeInHumanAge = {
                            year: parts[0],
                            month: parts[1]
                        };
                    } else {
                        dogAgeInHumanAge = {
                            year: parseInt(monthValues[0], 10),
                        };
                    }
                    break;
                case 'm':
                    if (monthValues[1].includes(',')) {
                        const parts = monthValues[1].split(',').map(Number);;
                        // const age = parts[0] + ' Jahre ' + parts[1] + ' Monat';
                        dogAgeInHumanAge = {
                            year: parts[0],
                            month: parts[1]
                        };
                    } else {
                        dogAgeInHumanAge = {
                            year: parseInt(monthValues[1], 10),
                        };
                    }

                    break;
                case 'l':
                    if (monthValues[2].includes(',')) {
                        const parts = monthValues[2].split(',').map(Number);;
                        // const age = parts[0] + ' Jahre ' + parts[1] + ' Monat';
                        dogAgeInHumanAge = {
                            year: parts[0],
                            month: parts[1]
                        };
                    } else {
                        dogAgeInHumanAge = {
                            year: parseInt(monthValues[2], 10),
                        };
                    }

                    break;
                case 'xl':

                    if (monthValues[3].includes(',')) {
                        const parts = monthValues[3].split(',').map(Number);;
                        dogAgeInHumanAge = {
                            year: parts[0],
                            month: parts[1]
                        };
                    } else {
                        dogAgeInHumanAge = {
                            year: parseInt(monthValues[3], 10),
                        };
                    }

                    break;
                default:
                    break;
            }
        }
        return dogAgeInHumanAge;
    }

    updateShareDogPermission(model: any): Observable<ResponseBeanModel> {
        const recordID = model.id;
        delete model.id;
        return this.makeRequest('PATCH', 'sharedinfo/' + recordID, model);
    }

    revokeShareDogPermission(id: string): Observable<ResponseBeanModel> {
        return this.makeRequest('DELETE', 'sharedinfo/' + id, {});
    }



}
