<div class="content-container">
    <div class="d-flex flex-column">
        <p class="font-size-16  mb3">Beantworte die Fragen so, wie du JETZT und MEISTENS privat bist.
            Dieser Test ist eine Momentaufnahme.</p>

        <p class="font-size-16  mb3">Es gibt keine richtigen oder falschen Antworten, auch keine bessere
            Eigenschaft. Jede Eigenschaft hat Vor- und Nachteile und für jeden Typ gibt es einen passenden Hund.</p>

        <p class="font-size-16 ">Antworte zügig ohne viel zu grübeln. Der Test dauert drei bis
            fünf Minuten.</p>
        <!--action : begin-->
        <div class="d-flex justify-content-center align-items-end">
            <button class="btn border-color-dark-gray border-2 border-radius-1 bgr-white mr2" (click)="back()">
                <img class="" style="opacity: .5;" src="assets/img/arrow-left.png" width="17px" height="14px" />
            </button>
            <button class="btn-fill-blue mb-0 p1 primary-btn border-radius-lg pl3 pr3" (click)="saveAndNext()">
                Weiter</button>
        </div>
        <!--action : end-->
        <div
            class="d-flex flex-column justify-content-center align-items-center flex-grow-1 mt3 mb3 w-100 coming-soon-img">
            <img src="assets/img/girlwithmoredogs.png" />
        </div>
    </div>
</div>