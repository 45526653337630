import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseApiService {

  constructor(protected http: HttpClient) {
    super(http);
    this.apiEndpoint = 'user';
    }

    readNotification(model: Array<string>): Observable<any> {
        return this.makeRequest('PATCH', 'notification', {id: model});
    }
}
