
export class GetProductDetailAction {
    static readonly type = '[Products] Get Products';
    constructor() { }
}



export class GetSubscriptionPlanByType {
    static readonly type = '[User] get subscription plan data by type';
    constructor(public model: { type: string, subType: string }) {
    }
}

export class PurchasePlanUsingGoodies {
    static readonly type = '[paid] purchase plan using goodies';
    constructor(public model: any) {
    }
}
export class CheckFilterPaymentForDog {
    static readonly type = '[Filter] check filter payment for dog';
    constructor() {
    }
}
export class CheckFilterPaymentForPerson {
    static readonly type = '[Filter] check filter payment for person';
    constructor() {
    }
}


export class SendIdsForManageSubscriptionOnPublish {
    static readonly type = '[Filter] send subscription ids for publish ad';
    constructor(public model:any) {
    }
}

export class GetPurchaseHistoryData {
    static readonly type = '[User] Get Purchase History Data';
    constructor(public page:number, public length:number) { }
}

export class GetBookingHistoryData {
    static readonly type = '[User] Get Booking History Data';
    constructor(public page:number, public length:number) { }
}

export class GetUserCurrentProductPaymentsData {
    static readonly type = '[User] Get User Current Product Payments Data';
    constructor() { }
}