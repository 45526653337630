<div class="edit-profile-picture-box map-modal" [formGroup]="formGroup">
    <div class="media">
        <div class="modal-body px-0">

            <div class="d-flex px-3 pt-4">
                <h4>{{ 'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_PIN' | translate }}</h4>
                <svg class="ic_close" (click)="close()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                    viewBox="0 0 40 40">
                    <g id="close" transform="translate(-1265 -197)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                            transform="translate(1265 197)" fill="#fed66b" />
                        <path id="Path_1" data-name="Path 1"
                            d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                            transform="translate(1278 209.984)" fill="#326291" />
                    </g>
                </svg>
            </div>
            <div class="custom-modal">

                <div class="custom-modal-body">
                    <div class="upload-section position-relative">
                        <div class="search-location">
                            <div class="d-flex w-100">
                                <img src="assets/img/ic_search.svg" alt="FairDogs_Search">
                                <!-- <input #addressInput type="text" id="address" formControlName="address"
                                             class="form-control input_location " placeholder="{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_PLACEHOLDER' | translate}}" floating-label> -->
                                <input type="text" #addressInput
                                    placeholder="{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_PLACEHOLDER' | translate}}"
                                    class="form-control input_location" formControlName="address"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
                                    <mat-option class="d-flex align-items-center location-dd-wrap"
                                        *ngFor="let option of placeOptions" [value]="option.description"
                                        (onSelectionChange)="placeSelect(option.place_id)">
                                        <span class="d-inline-block mr-2 location-dd"><img
                                                src="assets/img/ic_gray-loaction.svg"
                                                alt="FairDogs_Location"></span>{{option.description}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <span class="cursor-pointer d-flex justify-content-end w-100" (click)="getCurrentAddress()">
                                <img src="assets/img/ic_pin.svg" alt="FairDogs_Location">
                                <span class="ml-1 d-none d-lg-block">{{'PAGE.SHARED.EDIT_USER_PROFILE.DETECT_LOCATION' |
                                    translate}}</span>
                            </span>
                        </div>

                        <agm-map (mapReady)="onMapReady($event)" [styles]="styles" [zoom]="15" [latitude]='lat'
                            [longitude]='lng'>
                            <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"
                                iconUrl="assets/img/ic_location.svg" (dragEnd)="markerDragEnd($event)">
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
                <div class="mt-3 text-center">
                    <button class="btn-fill-blue btn-theme m-0"
                        [ngClass]="{'disabled' : (formGroup.controls.address.value && notFoundAddress)}"
                        (click)="SubmitAddress()">{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_SAVE' | translate}}</button>
                </div>

            </div>
        </div>
    </div>
</div>