import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DogModel } from '@core/models/dog.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DeleteParticipantPopupComponent } from 'app/profile/components/dog-person-relation/components/dog-sharing/components/delete-participant-popup/delete-participant-popup.component';
import { DogPreviewDialogComponent } from 'app/profile/components/dog-person-relation/components/dog-sharing/components/dog-preview-dialog/dog-preview-dialog.component';
import { GetDogForCareModel } from 'app/profile/components/dog-person-relation/components/dog-sharing/shared/models/get-dog-for-care.model';
import { GetPersonForCareModel } from 'app/profile/components/dog-person-relation/components/dog-sharing/shared/models/get-person-for-care.model';
import { ActivationChangePublication, AddToFavourite, DeleteMyPublication, GetAllMyPublications, GetSinglePublication, SetMainTabIndex, SetPersonOrDogForm } from 'app/profile/components/dog-person-relation/components/dog-sharing/shared/store/care.actions';
import { CareState } from 'app/profile/components/dog-person-relation/components/dog-sharing/shared/store/care.state';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ChangeKeyValue, GetUserAdsListData } from '../../store/user/user.actions';
import { UserState } from '../../store/user/user.state';

@Component({
  selector: 'app-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserInfoDialogComponent implements OnInit {
  date :any;
  bday = '';
  user!: DogModel;
  mobileView = false;
  showEmptyMsg: Boolean = false;
  selectedDogOrPerson:any = undefined;
  selectedDogOrPersonIndex:any = undefined;
  @Select(UserState.userWiseAdsData) data$ !: Observable<any>;
  // @Input() data!: any;
  @Input() participant!: boolean;
  @Input() insideData!: boolean;
  item!: any;
  userSharedDogs:Array<any> = [];
  inActiveAdFound:Boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserInfoDialogComponent>,
    private store: Store,

    private dialog: MatDialog,
    private translateService: TranslateService,
    private toastr: ToastrNotificationService


  ) {
    this.date = new Date();

    }

  ngOnInit(): void {
    
    let innerWidth = window.innerWidth;
      if (innerWidth < 768) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }

    window.addEventListener("resize", (event)=>{
      innerWidth = window.innerWidth;
      if (innerWidth < 768) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }

      if(this.mobileView === true && this.selectedDogOrPerson == undefined){
        this.selectedDogOrPerson = this.userSharedDogs[0];
      }
    })

    /**
     * dispatch action for get user id wise adds data
     */
    this.store.dispatch(new GetUserAdsListData(this.data.id)).subscribe(
      () => {
        this.getUSerWiseAddsData();
      },
      () => {}
    );

}


getUSerWiseAddsData(){
  // const data = this.store.subscribe(UserState.userWiseAdsData);
  // console.log('67',data);
  //  this.userSharedDogs = data;
   this.data$.subscribe((data: Array<any>) => {
    if (data) {
      this.userSharedDogs = data;
      let ind = this.userSharedDogs.findIndex(dt=>dt?.active === false);
      if(ind != -1){
        this.inActiveAdFound = true;
      }else{
        this.inActiveAdFound = false;
      }
      if(this.mobileView === true && this.selectedDogOrPerson == undefined){
        this.selectedDogOrPerson = this.userSharedDogs[0];
      }
      if(this.userSharedDogs.length > 0){
        this.showEmptyMsg =false
      } else{
        this.showEmptyMsg = true;
      }
      if(this.selectedDogOrPersonIndex != undefined){
        this.manageSelectedDogOrPersonView('', this.selectedDogOrPersonIndex)
      }
    }
  },error =>{
    this.showEmptyMsg = true;
  });
}


/**
 * assign clicked dog or person data for show in mobile mode
 * @param data 
 */
manageSelectedDogOrPersonView(data:any, index:number){
  this.selectedDogOrPersonIndex = undefined;
  this.selectedDogOrPerson = undefined;
  setTimeout(()=>{
    this.selectedDogOrPersonIndex = index;
    this.selectedDogOrPerson = JSON.parse(JSON.stringify(this.userSharedDogs[index]))
  })
}
 
onClose(): void {
  this.dialogRef.close();
}
}
