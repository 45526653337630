<div class="text-center mt2 paw-content">
    <div class="d-flex justify-content-center">
        <div class="text-center mb1 title font-poppins color-dark-gray">{{title}}</div>
    </div>
</div>
<div class="paw-form mt2">
    <div class="paw-container mt-0" *ngFor="let item of paws; let i = index;" (click)="selectAnswer(item.value)"
        [ngStyle]="{'background-image': 'radial-gradient( white, '+pawColors[i].bgr+')'}">
        <div class="text-center paw-text font-poppins" *ngIf="value != item.value">{{item.text}}</div>

        <div *ngIf="value == item.value" class="paw-icon">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.6 186.43">
                <path class="paw" [style.fill]="pawColors[i].border"
                    d="M301.24,506.29c-7.54-3.23-16.47-2-25.77,1.62-13.78,5.29-37.11-.77-45-13.68-5.78-9.43-6.15-27.06,7.83-35.29,4.3-2.53,8.51-5.24,12.63-8,8.45-5.72,12.83-14.44,17.22-23.26,3.85-7.73,7.93-15.72,17.81-17.24,10.31-1.59,22.38-.48,28.2,10.45A165,165,0,0,0,325,438.22c5.13,7.32,13.11,10.95,20.72,15,8.63,4.6,16,10.37,17.12,21.05,1.45,13.68-5.9,25.1-19,29.55-4.86,1.65-9.88,2.86-14.76,4.49C320.12,511.28,311.63,508.83,301.24,506.29Z"
                    transform="translate(-199.17 -323.39)" />
                <path class="paw" [style.fill]="pawColors[i].border"
                    d="M326.34,324.43c5.77.76,9.85,4.14,12.85,9.44a61.11,61.11,0,0,1,8.27,34.34c-.84,13.07-8.76,21.42-20.89,25.82a123.15,123.15,0,0,1-12.34,3.83c-8,2-14.22-1.56-16.47-9.53a77.94,77.94,0,0,1-2-31c.89-7.26,1.85-14.4,4.8-21.19A21.13,21.13,0,0,1,326.34,324.43Z"
                    transform="translate(-199.17 -323.39)" />
                <path class="paw" [style.fill]="pawColors[i].border"
                    d="M265,398.26c-9-2-17.56-5-23.95-12.09-5.93-6.57-7.71-14.63-6.63-23.1s1.08-17.19,5.44-25,10.27-13,19.65-12.8c10.61.26,18.31,5,21.78,15.36,5.2,15.62,7.34,31.58,4,47.88-2,9.93-7.09,12.87-16.93,10.56Z"
                    transform="translate(-199.17 -323.39)" />
                <path class="paw" [style.fill]="pawColors[i].border"
                    d="M254.67,427.43c-2.68,12-9.64,19.83-20.09,24.55-7.81,3.54-18.06.44-25-6.62-15.31-15.69-13.34-47.09,2.88-60.71,5.64-4.74,13.47-4.55,18.17,1.15,4.33,5.27,8.74,10.29,14.46,14.23C255.08,406.87,257.05,413.83,254.67,427.43Z"
                    transform="translate(-199.17 -323.39)" />
                <path class="paw" [style.fill]="pawColors[i].border"
                    d="M384.88,424.19c-2.33,9-6.52,15.94-13.17,21-10.51,8-22.62,4.53-32.43-5.57-7.88-8.11-10.56-18.65-9.41-29.85.39-3.81,2.94-7,5.79-9.61,5.61-5.16,11.34-10.11,15.59-16.62,5.05-7.73,13.24-8.51,20.49-2.63,11.11,9,13.68,21.52,13.89,34.79C385.67,418.75,386.2,422.09,384.88,424.19Z"
                    transform="translate(-199.17 -323.39)" />
            </svg> -->


            <img src="assets/img/person-test/option-{{i+1}}.svg">

        </div>
    </div>
</div>