import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from '@core/models/user.model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetDogShareMatchingResultDiscount, GetGoodiesDiscount, GetSubscriptionDiscount } from '../../../store/user/user.actions';
import { UserState } from '../../../store/user/user.state';

@Component({
  selector: 'app-subscription-parent',
  templateUrl: './subscription-parent.component.html',
  styleUrls: ['./subscription-parent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionParentComponent implements OnInit {

  subscriptionTypeData: any = {};
  @Select(UserState.user) user$ !: Observable<UserModel>;
  @Select(UserState.subscriptionDiscount) subDiscount$ !: Observable<UserModel>;
  @Select(UserState.goodiesDiscount) goodieDiscount$ !: Observable<UserModel>;
  @Select(UserState.dogShareMatchingResultDiscount) dogShareMatchingResultDiscount$ !: Observable<UserModel>;
  showGoodiesPurchaseComponent: Boolean = false;
  showDogShareMatchingResultDiscount : Boolean = false;
  subscriptionDiscount: number = 0;
  goodiesDiscount: number = 0;
  goodiesDiscountExpiry: any;
  dogShareMatchingResultDiscount: number = 0;
  dogShareMatchingResultDiscountExpiry: any;
  subscriptionDiscountExpiry: any;

  currentUser: any;

  constructor(
    public matDialogRef: MatDialogRef<SubscriptionParentComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.subscriptionTypeData = JSON.parse(JSON.stringify(this._data));

    /**
     * user data get
     */
    this.user$.subscribe((user: UserModel) => {
      if (user) {
        this.currentUser = user;
      }
    });

    /**
     * subscription discount get API call
     */
    this.store.dispatch(new GetSubscriptionDiscount('subscription-discount')).subscribe(
      () => {
        this.getSubscriptionDiscount();
      },
      () => { }
    );

    /**
     * goodies discount get API call
     */
    this.store.dispatch(new GetGoodiesDiscount('goodies-discount')).subscribe(
      () => {
        this.getGoodiesDiscount();
      },
      () => { }
    );

    this.store.dispatch(new GetDogShareMatchingResultDiscount('dogShareMatchingResult')).subscribe(
      () => {
        this.getDogShareMatchingResultDiscount();
      },
      () => { }
    );
  }

  /**
   * subscription discount
   */
  getSubscriptionDiscount() {
    this.subDiscount$.subscribe((discount: any) => {
      if (discount) {
        this.subscriptionDiscount = discount?.discount;
        this.subscriptionDiscountExpiry = discount?.expiry;
      }
    });
  }


  /**
   * goodies discount
   */
  getGoodiesDiscount() {
    this.goodieDiscount$.subscribe((discount: any) => {
      if (discount) {
        this.goodiesDiscount = discount.discount;
        this.goodiesDiscountExpiry = discount.expiry
      }
    });
  }

  getDogShareMatchingResultDiscount() {
    this.dogShareMatchingResultDiscount$.subscribe((discount: any) => {
      if (discount) {
        this.showDogShareMatchingResultDiscount = true;
        this.dogShareMatchingResultDiscount = discount?.discount;
        this.dogShareMatchingResultDiscountExpiry = discount?.expiry;
      }
    });
  }


  /**
   * 
   */
  manageChildActions(event: any) {
    if (event === 'show-goodies-purchase-screen') {
      this.showGoodiesPurchaseComponent = true;
    }
  }


  /**
   * manage plan purchase response
   * @param event 
   */
  manageAPIResponse(event: any) {
    if (event?.resp === 'success') {
      this.matDialogRef.close(event);
    }
  }
}
