import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddDogModel } from '@core/models/add-dog';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import * as ageMatrix from 'assets/data/age-matrix.json';
import { AddRipDogModel } from '@core/models/add-rip-dog.model';

@Injectable({ providedIn: 'root' })
export class EmergencyService extends BaseApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'dog-care-taking';
    }
    getViewEmergencyDogs(): Observable<ResponseBeanModel> {
        return this.makeRequest('GET', 'emergency-dogs', {});
    }
}
