import { AddDogModel } from '@core/models/add-dog';
import { AddRipDogModel } from '@core/models/add-rip-dog.model';
import { DogModel } from '@core/models/dog.model';

export class GetAllDogsAction {
    static readonly type = '[Dog] Get All Dogs';
    constructor(public model = true) { }
}

export class SetFilterOptionAction {
    static readonly type = '[Dog] Set Filter Options';
    constructor(public model: any) { }
}

export class SetFilteredDogAction {
    static readonly type = '[Dog] Set Filter Dogs';
    constructor(public model: DogModel[]) { }
}

export class SearchDogAction {
    static readonly type = '[Dog] Search Dogs';
    constructor(public model: string) { }
}

export class SetSortedDataAction {
    static readonly type = '[Dog] Sort Dog';
    constructor() { }
}

export class ResetFilteredDogAction {
    static readonly type = '[Dog] Reset Filter Dogs';
    constructor() { }
}

export class AddRipDogAction {
    static readonly type = '[Dog] Add Rip Dog';
    constructor(public model: { ripDog: AddRipDogModel, id: string }) { }
}

export class HideDogAction {
    static readonly type = '[Dog] Hide Dog';
    constructor(public model: { id: string, isShared: boolean }) { }
}

export class DeleteDogAction {
    static readonly type = '[Dog] Delete Dog';
    constructor(public model: { id: string, isShared: boolean }) { }
}

export class MoveRipFalse {
    static readonly type = '[Dog] move rip';
    constructor() { }
}


export class GetDogsFavoritesCountById {
    static readonly type = '[Dog] dog favorites count by id';
    constructor(public model: string) { }
}