import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dog-progress-bar',
  templateUrl: './dog-progress-bar.component.html',
  styleUrls: ['./dog-progress-bar.component.scss']
})
export class DogProgressBarComponent implements OnInit {
  @Input() title!: string;
  @Input() valueLabel!: string;
  @Input() value!: number;

  constructor() { }

  ngOnInit(): void {
  }

}
