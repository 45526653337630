import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { Select, Store } from '@ngxs/store';

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';

import { UserState } from '../store/user/user.state';
import { UserModel } from '@core/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class ChatService {

    socket!: Socket;
    public message$: BehaviorSubject<string> = new BehaviorSubject('');
    @Select(UserState.user) user$!: Observable<UserModel>;

    constructor(
        private store: Store,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        // Execute this wher user data is available
        this.user$.subscribe((user: UserModel) => {
            if (user && !this.socket && isPlatformBrowser(this.platformId)){
                this.socket = io(environment.serverDomain);
                const tempcurrentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

                this.socket.emit('room', {
                    id: store.selectSnapshot(UserState.user)?._id || tempcurrentUser?._id
                });
            }
        });
    }

     listenEvent(eventName: string): any {
        return new Observable((subscribe) => {
            this.socket.on(eventName, data => {
                subscribe.next(data);
            });
           });
     }

     emitEvent(eventName: string, data?: any): void{
        this.socket.emit(eventName, data);
     }

}
