<div class="chat-toast row" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="col-12">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
        </div>
        <div role="alert" [class]="options.messageClass">
            <p class="m-0">
                <span>Du hast ungelesene Nachrichten. <span (click)="action($event)" class="color-blue-light fw-500 text-underline-cus">Jetzt lesen</span></span>
            </p>
        </div>
    </div>
    <div class="close-button-wrapper">
        <button *ngIf="options.closeButton" class="close-button" (click)="remove()" type="button">&times;</button>
    </div>
    <!-- <div class="col-3 text-right">
        <a *ngIf="!options.closeButton" class="btn btn-pink btn-sm" (click)="action($event)">
            {{ undoString }}
        </a>
        <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-pink btn-sm">
            close
        </a>
    </div> -->
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>