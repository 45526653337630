import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DogModel, DogPurchaseOption, DogPurchaseOptionType } from '@core/models/dog.model';
import { ImageGallaryModel } from '@core/models/image-gallary.model';
import { UserModel } from '@core/models/user.model';
import { SpinnerService } from '@core/services/spinner.service';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { GallerySliderComponent } from 'app/profile/components/profile-dogs/shared/components/gallery-slider/gallery-slider.component';
import { ConfirmDialogComponent } from 'app/profile/shared/components/confirm-dialog/confirm-dialog.component';
import { EditCoverImageComponent } from 'app/profile/shared/components/edit-cover-image/edit-cover-image.component';
import { SubscriptionParentComponent } from 'app/profile/shared/components/subscription/subscription-parent/subscription-parent.component';
import { UserInfoDialogComponent } from 'app/profile/shared/components/user-info-dialog/user-info-dialog.component';
import { DogService } from 'app/profile/shared/services/dogs.service';
import { GetSubscriptionPlanByType, GetUserCurrentProductPaymentsData, SendIdsForManageSubscriptionOnPublish } from 'app/profile/shared/store/product/product.action';
import { ProductState } from 'app/profile/shared/store/product/product.state';
import { CheckChatPaymentForUSer, LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { UserState } from 'app/profile/shared/store/user/user.state';
import { cloneDeep, groupBy } from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EditImageForCare } from '../../shared/models/edit-image.model';
import { GetDogForCareModel } from '../../shared/models/get-dog-for-care.model';
import {
  CareImageChange, CareImageDelete, GetAllMyPublications, GetSinglePublication, PublishMyPublication,
  SetActiveTabIndex, SetMainTabIndex, SetNullDataAction, SetPersonOrDogForm
} from '../../shared/store/care.actions';
import { CareState } from '../../shared/store/care.state';
import { ViewAllEmergencyDogsAction } from '../../shared/store/emergency_dog/emergency.action';
import { EmergencyDogsState } from '../../shared/store/emergency_dog/emergency.state';

@Component({
  selector: 'app-dog-person-sharing-dog-preview-dialog',
  templateUrl: './dog-preview-dialog.component.html',
  styleUrls: ['./dog-preview-dialog.component.scss']
})
export class DogPreviewDialogComponent implements OnInit {

  dog!: DogModel;
  measure = '';
  dogType = '';
  bday = '';
  dogProfilePhoto: any;
  healthStatusText: string[] = [];
  dogNature: string[] = [];
  dogIsFriendly: string[] = [];
  dogIsGood: string[] = [];
  careTiming: string[] = [];
  dogChoice: string[] = [];
  panelOpenState = false;

  breedList: any;
  dogAgeHumanYears!: any;
  dogYears: any = 0;
  dogMonths: any = 0;
  dogDetail!: GetDogForCareModel;
  user!: UserModel;
  mobileView = false;
  searchDataLength = 0;
  currentIndex!: number;
  // searchData!: Array<GetDogForCareModel>;
  searchData!: Array<any>;
  isSubmitted = false;
  @ViewChild('topHeader', { static: true }) topHeader!: ElementRef;

  @Select(UserState.breeds) breed$ !: Observable<any>;
  uploadedImage: any;
  invalidFormat = false;
  openedFrom = '';
  @Select(UserState.user) user$ !: Observable<UserModel>;
  @Select(CareState.dogDetail) dogDetail$!: Observable<GetDogForCareModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DogPreviewDialogComponent>,
    private store: Store,
    private dogService: DogService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrNotificationService,
    private translateService: TranslateService,
    private spinner: SpinnerService
  ) {
    // this.user = this.store.selectSnapshot(UserState.user) as UserModel;
    this.user$.subscribe((user: UserModel) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnInit(): void {
    this.getAllDogBreed();
    const innerWidth = window.innerWidth;
    if (innerWidth < 768) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }

    this.dogDetail$.subscribe((dogDetail: GetDogForCareModel) => {
      this.getDogDetail();
    });

    this.openedFrom = this.data.openedFrom;
    if (this.openedFrom === 'adds') {
      const data = this.store.selectSnapshot(UserState.userWiseAdsData);
      this.searchData = data?.filter((dt: any) => dt?.type === 'dog');
      let indx = this.searchData.findIndex(dt => dt?._id === this.data?._id);
      this.searchDataLength = this.searchData ? this.searchData.length : 0;
      this.currentIndex = indx !== -1 ? indx : 0;
      this.getDogDetail();
    } else {
      if (this.data.emergency) {
        this.searchData = this.store.selectSnapshot(EmergencyDogsState.Emergencydogs);
        this.searchDataLength = this.searchData ? this.searchData.length : 0;
      } else {
        this.searchData = this.store.selectSnapshot(CareState.searchData) as Array<GetDogForCareModel>;
        this.searchDataLength = this.searchData ? this.searchData.length : 0;
      }
      if (typeof this.data?.index !== undefined) {
        this.currentIndex = this.data.index;
      }

      this.getDogDetail();
    }


  }

  userinfo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: this.dogDetail?.user?._id,
      photo: this.dogDetail?.user?.profilePhoto,
      userName: this.dogDetail?.user?.userName,
      firstName: this.dogDetail?.user?.firstName,
      lastName: this.dogDetail?.user?.lastName,
      gender: this.dogDetail?.user?.gender,
      birthdate: this.dogDetail?.user?.birthDay,
      location: this.dogDetail?.user?.location
    };
    // this.dialog.open(UserInfoDialogComponent, dialogConfig);
    this.dialog.open(UserInfoDialogComponent, {
      panelClass: 'user-wise-adds-show',
      disableClose: true,
      data: {
        id: this.dogDetail?.user?._id,
        photo: this.dogDetail?.user?.profilePhoto,
        userName: this.dogDetail?.user?.userName,
        firstName: this.dogDetail?.user?.firstName,
        lastName: this.dogDetail?.user?.lastName,
      }
    }).afterClosed()
      .subscribe(response => {
      }
      )
  }

  getDogDetail(): void {
    const dogDetail = this.store.selectSnapshot(CareState.dogDetail);

    this.dogDetail = dogDetail;

    const dog = dogDetail?.dog;
    if (dog) {
      this.setDogDetail(dog);
      this.setDogFormOptions(dogDetail);
      this.topHeader.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getAllDogBreed(): void {
    this.breed$.subscribe((breed: any) => {
      if (breed) {
        this.breedList = breed;
      }
    });
  }

  setDogDetail(dog: DogModel): void {
    if (dog) {
      this.bday = dog.birthDay.split('.').reverse().join('/');
      this.dogYears = moment().diff(moment(dog.birthDay.split('.').join('/'), 'DD/MM/YYYY'), 'years');
      this.dogMonths = moment().diff(moment(dog.birthDay.split('.').join('/'), 'DD/MM/YYYY'), 'months');
      this.dogAgeHumanYears = this.dogService.calculateDogAgeFromMatrix(this.bday, dog.size, dog.ripDate);
      this.dog = dog;
      // console.log('this.dog 38', this.dog);
      this.dogProfilePhoto = this.dog?.profilePhoto ? this.dog?.profilePhoto : 'assets/img/img_dog_placeholder_dog_profile.svg';
      if (dog.idealWeight) {
        const difference = dog.weight - dog.idealWeight;
        let diffDigit = difference;
        if (diffDigit < 0) {
          diffDigit = diffDigit * (-1);
        }
        const diffPercent = Math.round((diffDigit * 100) / dog.idealWeight);
        if (difference > 0) {
          if (diffPercent > 20) {
            this.measure = 'OVER';
          } else if (diffPercent <= 20 && diffPercent > 10) {
            this.measure = 'SLIGHTLY_OVER';
          } else {
            this.measure = 'IDEAL';
          }
        } else {
          if (diffPercent <= 5) {
            this.measure = 'IDEAL';
          } else if (diffPercent > 5 && diffPercent <= 10) {
            this.measure = 'SLIGHTLY_UNDER';
          } else {
            this.measure = 'UNDER';
          }
        }

      } else {
        this.measure = '';
      }
      const dogYear = this.dogYears;
      const size = dog.size;
      if (dogYear >= 0 && dog.size) {
        if (dogYear === 0 && this.dogMonths < 7) {
          this.dogType = 'PUPPY';
        } else if ((this.dogMonths > 6 && dogYear === 0) || (dogYear < 3 && dogYear > 0)) {
          this.dogType = 'JUNIOR';
        } else if (dogYear > 2 && dogYear <= 10 && (size === 's' || size === 'xs')) {
          this.dogType = 'ADULT';
        } else if (dogYear > 2 && dogYear <= 8 && size === 'm') {
          this.dogType = 'ADULT';
        } else if (dogYear > 2 && dogYear <= 7 && size === 'l') {
          this.dogType = 'ADULT';
        } else if (dogYear > 2 && dogYear <= 5 && size === 'xl') {
          this.dogType = 'ADULT';
        } else if (dogYear > 10 && (size === 's' || size === 'xs')) {
          this.dogType = 'SENIOR';
        } else if (dogYear > 8 && size === 'm') {
          this.dogType = 'SENIOR';
        } else if (dogYear > 7 && size === 'l') {
          this.dogType = 'SENIOR';
        } else if (dogYear > 5 && size === 'xl') {
          this.dogType = 'SENIOR';
        } else {
          this.dogType = 'NOT_MATCHED';
        }
      } else {
        this.dogType = '';
      }
      this.healthStatusText = [];
      if (dog.healthStatus.healthy) {
        // this.healthStatusText = ' fit und gesund';
        this.healthStatusText.push('HEALTHY');
      }
      if (dog.healthStatus.sick) {
        this.healthStatusText.push('POOR_HEALTH');
      }
      if (dog.healthStatus.disabilty || dog.healthStatus.blind) {
        this.healthStatusText.push('SPECIAL_NEED');
      }
      if (dog.healthStatus.pain) {
        this.healthStatusText.push('JOINTS');
      }
    }
  }

  // openChatScreen(dogDetails: any) {
  //   this.dialogRef.close();
  //   localStorage.setItem('User', JSON.stringify(dogDetails));
  //   window.open('/profile/chat', "_blank");
  // }

  setDogFormOptions(dogDetail: GetDogForCareModel): void {
    const { dogNature, dogIsGood, dogIsFriendly, careTiming, dogChoice } = dogDetail;
    this.dogNature = this.customGroupBy(dogNature);
    this.dogIsFriendly = this.customGroupBy(dogIsFriendly);
    this.dogIsGood = this.customGroupBy(dogIsGood);
    this.careTiming = this.customGroupBy(careTiming);
    this.dogChoice = this.customGroupBy(dogChoice);
  }

  onClose(): void {
    this.dialogRef.close();
    this.store.dispatch(new SetNullDataAction());
    if (this.data?.afterAdd) {
      this.store.dispatch(new SetMainTabIndex(1)); // on ad create move to sec tab
    }
  }

  customGroupBy(myObj: { [key: string]: boolean }): any {
    return Object.keys(myObj).filter((key) => myObj[key]);
  }

  // onImageEdit(event: any): void {
  //   const file = event.target.files[0];
  //   if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif')) {
  //     // console.log('164 image', file);
  //     this.store.dispatch(new CareImageChange({ image: file, id: this.dogDetail._id, type: 'dog' } as EditImageForCare)).subscribe(
  //       () => {
  //         this.dogDetail = this.store.selectSnapshot(CareState.dogDetail);
  //         // console.log('168 dogDetail', this.dogDetail);
  //       },
  //       () => { }
  //     );
  //   }
  // }

  openCropImageDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      coverImageWeb: this.dogDetail.image,
      title: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.IMAGE_CROP_TITLE',
      aspectWidth: 163,
      aspectHeight: 125,
      roundCropper: false
    };
    dialogConfig.panelClass = 'c--img-upload';
    const dialogRef = this.dialog.open(EditCoverImageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result?.croppedImage) {
        // console.log(result?.croppedImage)
        if (result?.croppedImage && (result?.croppedImage.type === 'image/png')) {
          // console.log('164 image', file);
          this.store.dispatch(new CareImageChange({ image: result?.croppedImage, id: this.dogDetail._id, type: 'dog' } as EditImageForCare)).subscribe(
            () => {
              this.dogDetail = this.store.selectSnapshot(CareState.dogDetail);
              // console.log('168 dogDetail', this.dogDetail);
            },
            () => { }
          );
        }
      }
    });
  }

  // renderImage(file: File): void{
  //   if (file){
  //     // if (file.type.indexOf('image') > -1){
  //     if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'){
  //       // this.dogDetail.image.patchValue({ image: file });
  //       const myReader: FileReader = new FileReader();
  //       myReader.onloadend = (e) => {
  //         this.uploadedImage = myReader.result;
  //       };
  //       myReader.readAsDataURL(file);
  //       this.invalidFormat = false;
  //     } else{
  //       this.invalidFormat = true;
  //     }
  //   }
  // }


  onImageDelete(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: 'PAGE.SHARED.PICTURE_GALLARY.CONFIRM_DIALOG_TITLE',
      message: 'PAGE.SHARED.PICTURE_GALLARY.CONFIRM_DIALOG_DESC_FIRST',
      cancel: 'PAGE.SHARED.PICTURE_GALLARY.CANCEL_BUTTON',
      submit: 'PAGE.SHARED.PICTURE_GALLARY.DELETE_BUTTON'
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reason) {
          this.store.dispatch(new CareImageDelete({ id: this.dogDetail._id, type: 'dog' } as EditImageForCare)).subscribe(
            () => {
              this.dogDetail = this.store.selectSnapshot(CareState.dogDetail);
            },
            () => { }
          );
        }
      }
    });
  }

  goToPersonality(id: string): void {
    if (this.dogDetail.ptAvailable) {
      window.open(`/profile/dogs/${id}/test-result`, '_blank');
    } else {
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.TEST_NOT_AVAILABLE').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
    }
  }

  giveDogPersonalityTest(id: string): void {
    // Do not proceed if user is not owner
    if (this.dogDetail?.user?._id !== this.user?._id) return;
    window.open(`/profile/dogs/${id}/test-result/latest?test=begin`, '_self');
  }

  viewGallary(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'full-screen-popup';
    const imageArray = cloneDeep(this.dogDetail.imageGallery);
    imageArray.map((file: ImageGallaryModel) => {
      file.url = file.image;
      file.isImage = true;
    });
    dialogConfig.data = {
      startIndex: 0,
      onlyPreview: true,
      filesArray: imageArray,
    };

    const dialogRef = this.dialog.open(GallerySliderComponent, dialogConfig);
  }

  editData(): void {
    if (this.dogDetail?.dog?.ripDate) { // if dog rip than cant edit
      this.translateService.get('COMMON.RIP_DOG_EDIT_ACTIVATE_MSG').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
      return;
    }
    this.store.dispatch(new SetActiveTabIndex(0));
    this.dialogRef.close();
    this.store.dispatch(new SetPersonOrDogForm(true));
    this.store.dispatch(new SetMainTabIndex(0));
  }

  onNext(): void {
    this.store.dispatch(new GetSinglePublication({
      id: this.searchData[this.currentIndex + 1]._id,
      type: 'dog'
    })).subscribe(
      () => {
        this.currentIndex++;
        this.getDogDetail();
      },
      () => { }
    );
  }

  onPrev(): void {
    // console.log('266 index', this.data.index);
    this.store.dispatch(new GetSinglePublication({
      id: this.searchData[this.currentIndex - 1]._id,
      type: 'dog'
    })).subscribe(
      () => {
        this.currentIndex--;
        this.getDogDetail();
      },
      () => { }
    );
  }


  /**
   * show dog sharing payment modal
   */
  async showDogSharingPaymentPopup() {
    if (this.dogDetail?.dog?.ripDate) { // if dog rip than cant share
      this.translateService.get('COMMON.RIP_DOG_EDIT_ACTIVATE_MSG').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
      return;
    }
    if (this.dogDetail.image || this.dogDetail.dog.profilePhoto) {
      let dogData = JSON.parse(JSON.stringify(this.dogDetail));
      let keyValMatchObj: any = {
        anonymousAd: 'anonymousAd',
        emergencyCare: 'emergencyCare',
        dogNotOnHome: 'lpspecificaddisable',
        displayLocation: 'withoutResidence'
      }

      // Fetch new details
      const publicationId: string = dogData._id;
      this.store.dispatch(new GetSinglePublication({ id: publicationId,  type:'dog' })).subscribe(async () => {

        this.store.dispatch(new GetUserCurrentProductPaymentsData()).subscribe(async () => {
          dogData = this.store.selectSnapshot(CareState.dogDetail);
          const activePayments = this.store.selectSnapshot(ProductState.getActivePayments);
          const activePaymentsFiltered = activePayments.filter((payment)=> payment?.uniqueId === publicationId);
          const existingSelectedIds = activePaymentsFiltered.map((payment) => payment?.product?._id);
  
          await this.getDogSharingSubscriptionPlan();
          const dogSharingSubscriptionPlan: any = this.store.selectSnapshot(ProductState.getSubscriptionPlanByType);
  
          let selectedIds = [];
          // let existingSelectedIds: Array<any> = [];
          for (const key of Object.keys(keyValMatchObj)) {
            if (dogData[key] === true) { // find on edit new selected plan ids
              let index = dogSharingSubscriptionPlan.findIndex((plan: any) => plan?.subType === keyValMatchObj[key]);
              selectedIds.push(dogSharingSubscriptionPlan[index]?._id);
            }
          }
  
          let mode = '';
          let bothArraySame: boolean = true;
          if (existingSelectedIds.length > 0) { // compare with old ids and check emergency payment done or not
            for (const id of selectedIds) {
              if (existingSelectedIds.includes(id) === false) {
                bothArraySame = false;
                mode = 'edit';
              }
            }
  
          } else if (selectedIds?.length === 0) {
            bothArraySame = true;
          } else { // if need to do payment
            mode = 'add';
            bothArraySame = false;
          }
  
          if (!bothArraySame) {
            this.dialog.open(SubscriptionParentComponent, {
              panelClass: 'subscription-parent-dialog',
              disableClose: true,
              data: {
                type: 'dogCare',
                subType: '',
                mode: mode,
                selectedIds: selectedIds,
                existingIds: existingSelectedIds,
                dogOrPersonId: publicationId
              }
            }).afterClosed()
              .subscribe(response => {
                if (response?.resp === 'success') {
                  let obj = {
                    _id: this.dogDetail?._id,
                    products: response?.productSelectedIds,
                    type: response?.type,
                    subType: response?.subType
                  }
                  this.store.dispatch(new SendIdsForManageSubscriptionOnPublish(obj)).subscribe( // send ids for publish change subscription ids
                    () => {
                      this.publish();
                    },
                    () => { }
                  );
                }
              }
              )
  
          } else {
            this.publish();
          }

        });

      });

    } else {
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.UPLOAD_PHOTO_ERROR').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
      this.isSubmitted = false;
    }
  }


  /**
   * API for get subscription plan 
   */
  getDogSharingSubscriptionPlan() {
    return new Promise((resolve, reject) => {
      this.store.dispatch(new GetSubscriptionPlanByType({ type: 'dogCare', subType: '' })).subscribe(
        () => {
          resolve('');
        },
        () => {
          reject('');
        }
      );
    })
  }



  publish(): void {
    if (this.isSubmitted) {
      return;
    }
    this.isSubmitted = true;
    if (this.dogDetail.image || this.dogDetail.dog.profilePhoto) {
      this.store.dispatch(new PublishMyPublication({ id: this.dogDetail._id, type: 'dog' })).subscribe(
        () => {
          this.store.dispatch(new LoadUserAction());
          this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
            this.toastr.showSuccess(res, '');
          });
          if (this.data?.afterAdd) {
            this.isSubmitted = false;
            this.dialogRef.close();
            this.store.dispatch(new SetNullDataAction());
            this.store.dispatch(new SetMainTabIndex(1));
          } else {
            this.store.dispatch(new GetAllMyPublications()).subscribe(
              () => {
                this.isSubmitted = false;
                this.dialogRef.close();
              },
              () => {
                this.isSubmitted = false;
              }
            );
          }
        },
        () => { }
      );
    } else {
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.UPLOAD_PHOTO_ERROR').subscribe((res: string) => {
        this.toastr.showError(res, '');
      });
      this.isSubmitted = false;
    }
  }

  openChatScreen(userData: any): void {
    this.spinner.show();
    this.dialogRef.close();
    localStorage.setItem('User', JSON.stringify(userData));
    var open = window.open('/chat');
    if (open == null || typeof (open) == 'undefined') {
      window.location.assign('/chat')
    }

    setTimeout(() => {
      this.spinner.hide();
    }, 5000)

    // window.open('/profile/chat', 'myTab'); // use myTab keyword for solve issue of popup block open in new tab
    // this.router.navigate(['/profile/chat'], { state: { User: userData } });
  }

  /**
   * manage to show chat page or chat make payment popup
   * @param userData 
   */
  manageChatFeature(userData: any) {
    this.spinner.show();
    if (this.user?.isAdAvailable === false) {
      this.spinner.hide();
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.TRY_CHAT_NO_ADS').subscribe((res: string) => {
        this.toastr.showError(res, '');
      })
    } else {
      this.openChatScreen(userData);
      this.spinner.hide();
      /**
       * API call for check chat payment and get data from store
       */
      // this.spinner.show();
      // this.store.dispatch(new CheckChatPaymentForUSer(userData?.user?._id)).subscribe(
      //   () => {
      //     const isPaidForChat: any = this.store.selectSnapshot(UserState.checkChatPaymentStatusForUser);

      //     if (isPaidForChat?.paidChat) {
      //       this.openChatScreen(userData);
      //     } else {
      //       this.showChatPaymentPopup(userData);
      //     }

      //   },
      //   (error: any) => {
      //     this.showChatPaymentPopup(userData); // TODO: remove it when this API works
      //   }
      // );

    }
  }


  /**
   * show chat payment modal
   */
  showChatPaymentPopup(userData: any) {
    this.spinner.hide();
    this.dialog.open(SubscriptionParentComponent, { // show chat subscription popup
      panelClass: 'subscription-parent-dialog',
      disableClose: true,
      data: {
        type: 'dogCare',
        subType: 'Kontaktaufnahme',
        userId: userData?.user?._id
      }
    }).afterClosed()
      .subscribe(response => {
        // this.spinner.show();
        if (response?.resp === 'success') {
          // setTimeout(() => {
          //   this.spinner.hide();
          // }, 3000)
          this.manageChatFeature(userData);
        }
      }
      )
  }

}
