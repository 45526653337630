export class LoadDogTestResults {
    static readonly type = '[DogTestResult] Load Dog Test Result';
    constructor(public dogId: string, public testId?: string, public loading = true) { }
}

export class SetCurrentDogTest {
    static readonly type = '[DogTestResult] Set Current Dog Test';
    constructor(public id: string) { }
}

export class SetTestSatisfaction {
    static readonly type = '[DogTestResult] Set Dog Test Satisfaction';
    constructor(public id: string, public markValue: number) { }
}

export class SetDogSatisfaction {
    static readonly type = '[DogTestResult] Set Dog Satisfaction';
    constructor(public id: string, public markValue: number) { }
}

export class ShowDogTestDemo {
    static readonly type = '[DogTestResult] Show Dog Test Demo';
    constructor(public id: string) { }
}

export class ShowDogTestFullReport {
    static readonly type = '[DogTestResult] Show Dog Test Full Report';
    constructor(public id: string) { }
}

export class GetMatchingHumanTestResult {
    static readonly type = '[DogTestResult] Get Matching Test';
    constructor(public id: string, public testId?: string) { }
}

export class CheckPaymentForMatchTest {
    static readonly type = '[DogTestResult] Check Payment Match Test';
    constructor(public id: string, public testId?: string) { }
}

export class GenerateMatchingTestReport {
    static readonly type = '[DogTestResult] Generate Matching  Test Report';
    constructor(public id: string, public testId?: string) { }
}