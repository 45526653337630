import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class DogDetailService extends BaseApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'dog';
    }

    getDogDetail(id: string): Observable<any> {
        // return of(TEST_USER);
        const headers = new HttpHeaders({
          'cache-control': 'no-cache',
          'Pragma': 'no-cache',
        });
        // headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
        return this.makeRequest('GET', 'profile/' + id, {}, undefined, headers);
    }

    addDogDesc(model: { description: string, id: string }): Observable<any> {
        const { description, id } = model;
        // return of(TEST_USER);
        return this.makeRequest('PATCH', 'description/' + id, { description });
    }

    addChipNo(chipNumber: string, id: string): Observable<any> {
        return this.makeRequest('PATCH', 'chipNumber/' + id, { chipNumber });
    }

    dogProfileVideoUpload(file: Blob | File, id: string): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.makeRequest('PATCH', 'gallery/video/' + id, formData, undefined, headers);
    }

    dogDeleteVideoUpload(model: string): Observable<any> {
        return this.makeRequest('DELETE', 'gallery/video/' + model, {});
    }

    addPayment(obj: object): Observable<any> {
        this.apiEndpoint = 'user';
        // console.log('object', obj);
        return this.makeRequest('POST', 'checkout', obj);
    }
}
