import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddImageGallaryModel } from '@core/models/add-image-gallary.model';
import { EditImageGallaryModel } from '@core/models/edit-image-gallary.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageGallaryService extends BaseApiService {

  constructor(protected http: HttpClient) {
    super(http);
    this.apiEndpoint = 'dog';
  }

  public getImages(id: string): Observable<any> {
    return this.makeRequest('GET', 'gallery/imageView/' + id, {});
  }

  public create(model: AddImageGallaryModel): Observable<any> {
    const formData = new FormData();
    model.file.forEach((f: File) => {
      formData.append('file', f);
    });
    formData.append('dog', model.id);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('POST', 'gallery/imageAdd', formData, undefined, headers);
  }

  public update(model: EditImageGallaryModel): Observable<any> {
    const formData = new FormData();
    formData.append('file', model.file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.makeRequest('PATCH', 'gallery/image/' + model.id, formData, undefined, headers);
  }

  public delete(id: string): Observable<any> {
    return this.makeRequest('DELETE', 'gallery/image/' + id, {});
  }
}
