import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonTestService extends BaseApiService {

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
    this.apiEndpoint = 'user'; // PATH to controller or microservice
  }

  private redirectToResult = false;

  createTest(model: object, isAuthenticated: boolean): Observable<any> {
    if (isAuthenticated) {
      this.apiEndpoint = 'user/createPersonTest';
      return this.makeRequest('POST', '', model);
    } else {
      this.apiEndpoint = 'peoplePersonalityTestGuest';
      // return this.makeRequest('POST', 'test', model);
      const data = this.jsonToFormData(model);
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      return this.makeRequest('POST', '', data, undefined, headers);
    }
  }

  patchTest(testId: string, model: object): Observable<any> {
    this.apiEndpoint = 'editPersonTest';
    // return this.makeRequest('PATCH', `test/${testId}`, model);
    return this.makeRequest('PUT', `test`, model);
  }

  getAllBreeds(): Observable<ResponseBeanModel> {
    this.apiEndpoint = 'dogPersonalityTestGuest';
    return this.makeRequest('GET', 'breed', {});
  }

  buildFormData(formData: any, data: any, parentKey: any): void {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  jsonToFormData(data: any): any {
    const formData = new FormData();
    this.buildFormData(formData, data, '');
    return formData;
  }

  setRedirectToResultFlag(data: boolean): void {
    this.redirectToResult = data;
  }

  getRedirectToResultFlag(): boolean {
    return this.redirectToResult;
  }
}
