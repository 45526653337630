export default [
  {
    _id: {
      $oid: '60cf3726aea8c83044561d6e',
    },
    questionId: 7,
    question:
      '… skeptisch oder verstört bei Veränderungen und neuen Dingen ist.',
    type: 'curiosity',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d6d',
    },
    questionId: 6,
    question: '... unerschrocken, stressresistent und selbstsicher ist.',
    type: 'sensitivity',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d71',
    },
    questionId: 10,
    question: '... reserviert oder genervt bei Hundebegegnungen ist.',
    type: 'desireForContactDog',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d69',
    },
    questionId: 2,
    question:
      'Mit meinem Hund gehe ich täglich mindestens ... spazieren. ',
    type: 'energy',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d6a',
    },
    questionId: 3,
    question: '… vor Energie sprüht, schnell, aktiv und sehr ausdauernd ist.',
    type: 'energy',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d68',
    },
    questionId: 1,
    question:
      'Was beschreibt dich zusammen mit einem Hund am besten?',
    type: 'energy',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d70',
    },
    questionId: 9,
    question: '… unzuverlässig ist.',
    type: 'lifestyle',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d6c',
    },
    questionId: 5,
    question:
      '... bockt und konfliktbereit ist, wenn er etwas anderes als der Halter will.',
    type: 'socialBehaviorHuman',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d6f',
    },
    questionId: 8,
    question: '… Konflikte mit anderen Hunden nicht scheut.',
    type: 'socialBehaviorDog',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d6b',
    },
    questionId: 4,
    question:
      '… freundlich ist und viel Aufmerksamkeit sucht, auch bei fremden Menschen.',
    type: 'desireForContactHuman',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d72',
    },
    questionId: 11,
    question:
      '… kooperativ und rücksichtsvoll mit Erwachsenen und Kindern ist.',
    type: 'socialBehaviorHuman',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d73',
    },
    questionId: 12,
    question: '... viel nervös, gestresst und unsicher ist.',
    type: 'sensitivity',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d74',
    },
    questionId: 13,
    question:
      '... desinteressiert, scheu und kritisch bei Besuchern oder Passanten ist.',
    type: 'desireForContactHuman',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d75',
    },
    questionId: 14,
    question:
      '… neugierig und einfallsreich ist, bewusst andere aus der Reserve lockt.',
    type: 'curiosity',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d78',
    },
    questionId: 17,
    question:
      '... selbstbewusst, interessiert und aufgeschlossen auf fremde Hunde zugeht.',
    type: 'desireForContactDog',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d76',
    },
    questionId: 15,
    question: '… Aufgaben ausdauernd, genau und zielorientiert ausführt.',
    type: 'lifestyle',
    minValue: 1,
    maxValue: 5,
  },
  {
    _id: {
      $oid: '60cf3726aea8c83044561d77',
    },
    questionId: 16,
    question:
      '… anderen Hunden freundlich, schlichtend und unterwürfig begegnet.',
    type: 'socialBehaviorDog',
    minValue: 1,
    maxValue: 5,
  },
];
