<div class="dog-preview-modal container px-0" #topHeader>
    <div class="preview-person-or-dog">
        <div class="media">
            <div class="modal-body p-0" *ngIf="dogDetail">
                <div>
                    <div class="bg-4CADCD position-relative min-height-empty">
                        <ng-container *ngIf="data.participant; else othersPublication">
                            <div class="text-center flex-wrap d-flex justify-content-center"
                                [ngClass]="{'d-flex': !mobileView}">
                                <!-- Use d-flex instead of d-block to show/hide in above line-->
                                <div class="resposive-btn-dialog mr-sm-3">
                                    <button
                                        class="btn bg-white rounded-pill color-blue-important font-weight-sbold fs-14 cus-my-20-btn"
                                        (click)="editData()">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.EDIT_BUTTON_HEADER' | translate }}
                                    </button>
                                </div>
                                <div class="resposive-btn-dialog" *ngIf="!dogDetail.published">
                                    <!-- publish()    showDogSharingPaymentPopup() -->
                                    <button
                                        class="btn rounded-pill color-blue-important font-weight-sbold fs-14 cus-my-20-btn bg-yellow"
                                        [ngClass]="{'disabled': isSubmitted}" (click)="showDogSharingPaymentPopup()">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PUBLISH_BUTTON_HEADER' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #othersPublication>
                            <h3
                                class="fs-32 mb-0 padd-right-resp text-white font-weight-bold text-left fsm-18 text-md-center py-3 cus-px-80">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.TITLE' | translate}}
                            </h3>
                        </ng-template>
                        <div class="position-absolute pos-vertical-align pos-right-20">
                            <svg class="ic_close cursor-pointer" (click)="onClose()" xmlns="http://www.w3.org/2000/svg"
                                width="40" height="40" viewBox="0 0 40 40">
                                <g id="close" transform="translate(-1265 -197)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                        transform="translate(1265 197)" fill="#fed66b" />
                                    <path id="Path_1" data-name="Path 1"
                                        d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                                        transform="translate(1278 209.984)" fill="#326291" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-md-5 col-xl-4 px-0">
                            <h2 *ngIf="dogDetail.emergencyCare"
                                class="fsm-22 text-white font-weight-sbold pink-flag pink-main-heading mb-0 d-inline-block">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.EMERGENCY_TEXT' | translate }}
                            </h2>
                            <!-- Use d-inline-block instead of d-block to show/hide in above line-->
                            <div class="modal-left-div">
                                <div class="w-100 profile-modal-btn mt-5"
                                    *ngIf="!data.participant && (user._id !== dogDetail.user._id)">
                                    <!-- (click)="openChatScreen(dogDetail)"  (click)="manageChatFeature(dogDetail)"-->
                                    <button (click)="manageChatFeature(dogDetail)"
                                        class="btn theme-primary-btn ml-auto btn-height-45 px-4 mb-0"><span
                                            class="d-inline-block chatbubbles-icon mr-2"><img
                                                src="assets/img/ion_chatbubbles-blue.svg"
                                                alt="FairDogs_ChatBlue"></span>Kontakt
                                        aufnehmen</button>
                                </div>
                                <div class="main-title-modal">
                                    <h5 class="fs-18 font-weight-sbold mb-1 color-blue text-center text-break">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.PROFILE_TEXT' | translate
                                        : { name: dog?.name} }}
                                    </h5>
                                    <ng-container *ngIf="dog?.breed?.length;else emptyBreed">
                                        <p class="color-dark-gray fs-16 fsm-16 text-center">
                                            <ng-container
                                                *ngIf="dog?.dogType === 'mix_breed_known' || dog?.dogType === 'cross_breed'">
                                                <span class="color-gray"><b>{{'PAGE.DOGS.DOG_DETAIL.DOG_INFO.' +
                                                        (dog?.dogType ===
                                                        'mix_breed_known' ? 'MIX_BREED_KNOWN_INFO' : 'CROSS_BREED_INFO')
                                                        |
                                                        translate}}</b></span>
                                            </ng-container>
                                            <ng-container *ngFor="let breedID of dog.breed; let index = index">
                                                <ng-container *ngFor="let list of breedList">
                                                    <ng-container *ngIf="list._id == breedID">
                                                        <span>{{list.breed.includes(',') ?
                                                            list.breed.substr(0,list.breed.indexOf(',')) :
                                                            list.breed}}</span>
                                                        <span>{{dog.breed.length > 1 && index !=(dog.breed.length-1) ? '
                                                            + ' : null}}</span>
                                                        <!-- <ng-container *ngIf="dog?.dogType === 'mix_breed_known' && dog.breed.length > 1 && index ===(dog.breed.length-1)">
                                                        - Mix
                                                      </ng-container> -->

                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </p>
                                    </ng-container>
                                    <ng-template #emptyBreed>
                                        <p class="color-dark-gray fs-16 fsm-16 text-center">
                                            {{'PAGE.DOGS.DOG_DETAIL.DOG_INFO.PROMENADE_MIX' | translate }}</p>
                                    </ng-template>
                                </div>
                                <div class="text-center position-relative">
                                    <div class="title-profile-img">
                                        <img class="w-100"
                                            [src]="dog?.profilePhoto ? dog?.profilePhoto : 'assets/img/img_dog_placeholder.svg'"
                                            alt="FairDogs_Hunde">
                                    </div>

                                    <div *ngIf="(!data.participant && openedFrom !== 'adds' && searchData?.length && searchData[currentIndex].distance >= 0); else valuetrue"
                                        class=" d-flex justify-content-center pb-4 bottom-cta-result km-main card-action-bottom">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <p class="m-0 color-gray fs-14 text-nowrap">
                                                    <span class="d-inline-block mr-1">
                                                        <ng-container
                                                            *ngIf="searchData[currentIndex].distance > 1; else meterBlock">
                                                            {{ searchData[currentIndex].distance | number: "1.0-0" }}km
                                                        </ng-container>
                                                        <ng-template #meterBlock>
                                                            {{ searchData[currentIndex].distance * 1000 | number:
                                                            "1.0-0" }}m
                                                        </ng-template>
                                                    </span>{{ "PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.FROM_YOU" |
                                                    translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div *ngIf="false" class="status-symbol status-offline">
                                            <p class="m-0 status-dot-dynamic fs-14">offline</p>
                                        </div>
                                        <div class="text-truncate ml-1 mw-100"
                                            *ngIf="!searchData[currentIndex]?.displayLocation">
                                            <p class=" m-0 color-gray fs-14 text-nowrap d-flex flex-nowrap"
                                                [ngClass]="{'content-seperate': !searchData[currentIndex]?.displayLocation}">
                                                <ng-container
                                                    *ngIf=" (searchData[currentIndex]?.user?.location?.city || searchData[currentIndex]?.user?.location?.state) && searchData[currentIndex]?.user?.location?.countryName; else elseBlock ">
                                                    <ng-container
                                                        *ngIf="searchData[currentIndex]?.user?.location?.city">
                                                        <span class="text-truncate location-width">| {{
                                                            searchData[currentIndex]?.user?.location?.city}}</span>,
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="searchData[currentIndex]?.user?.location?.state">
                                                        <span class="pl-1 text-truncate location-width">{{
                                                            searchData[currentIndex]?.user?.location?.state}}</span>,
                                                    </ng-container>
                                                    <span class="pl-1">{{
                                                        searchData[currentIndex]?.user?.location?.countryName }}</span>
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    <span>{{ searchData[currentIndex]?.user?.country }}</span>
                                                </ng-template>
                                            </p>
                                        </div>
                                    </div>
                                    <ng-template #valuetrue>
                                        <div
                                            class=" d-flex justify-content-center pb-4 bottom-cta-result km-main card-action-bottom">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <p class="m-0 color-gray fs-14 text-nowrap">
                                                        <span class="d-inline-block mr-1">
                                                            <ng-container
                                                                *ngIf="dogDetail.distance > 1; else meterBlock">
                                                                {{ dogDetail?.distance | number: "1.0-0" }}km
                                                            </ng-container>
                                                            <ng-template #meterBlock>
                                                                {{ dogDetail.distance * 1000 | number: "1.0-0" }}m
                                                            </ng-template>
                                                        </span>
                                                        {{ "PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.FROM_YOU" |
                                                        translate }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div *ngIf="false" class="status-symbol status-offline">
                                                <p class="m-0 status-dot-dynamic fs-14">offline</p>
                                            </div>
                                            <div class="text-truncate ml-1 mw-100" *ngIf="!dogDetail?.displayLocation">
                                                <p class=" m-0 color-gray fs-14 text-nowrap d-flex flex-nowrap"
                                                    [ngClass]="{'content-seperate': !dogDetail?.displayLocation}">
                                                    <ng-container
                                                        *ngIf=" (dogDetail?.user?.location?.city || dogDetail?.user?.location?.state) && dogDetail?.user?.location?.countryName; else elseBlock ">
                                                        <ng-container *ngIf="dogDetail?.user?.location?.city">
                                                            <span class="text-truncate location-width">| {{
                                                                dogDetail?.user?.location?.city}}</span>,
                                                        </ng-container>
                                                        <ng-container *ngIf="dogDetail?.user?.location?.state">
                                                            <span class="pl-1 text-truncate location-width">{{
                                                                dogDetail?.user?.location?.state}}</span>,
                                                        </ng-container>
                                                        <span class="pl-1">{{ dogDetail?.user?.location?.countryName
                                                            }}</span>
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        <span>{{ dogDetail?.user?.country }}</span>
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-container *ngIf="mobileView && !data.participant">
                                        <div role="button" class="dialog-arrow-left-sm dialog-slider-arrow-sm"
                                            [ngClass]="{'disabled': this.currentIndex===0}">
                                            <img (click)="this.currentIndex !== 0 && onPrev()"
                                                src="assets/img/ic_arrow_right-blue.svg" alt="FairDogs_ArrowRight">
                                        </div>
                                        <div role="button" class="dialog-arrow-right-sm dialog-slider-arrow-sm"
                                            [ngClass]="{'disabled': (this.currentIndex+1)===this.searchDataLength}">
                                            <img (click)="(this.currentIndex+1) !== this.searchDataLength && onNext()"
                                                src="assets/img/ic_arrow_right-blue.svg" alt="FairDogs_ArrowRight">
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="birthday-card mb-2 mb-xl-0 bg-FFF5DA">
                                    <div class="row align-items-center mx-0">
                                        <div class="col-1 col-md-12 col-xl-1 mb-0 mb-md-3 mb-xl-0 px-0">
                                            <div class="icon text-center">
                                                <img src="assets/img/modal-birthday.svg" alt="FairDogs_BirthDay">
                                            </div>
                                        </div>
                                        <div class="col-5 col-md-12 col-xl-5 px-2 title-details">
                                            <div class="date-card">
                                                <div class="text-left text-md-center text-xl-left mb-0 mb-md-2 mb-xl-0">
                                                    <h4
                                                        class="color-gray-img fs-14-imp font-weight-bold cus-mb-4 text-overflow-ellipsis white-space-nowrap">
                                                        {{'PAGE.BIRTHDAY' | translate }}</h4>
                                                    <p
                                                        class="color-gray-img fs-14-imp mb-0 white-space-nowrap text-overflow-ellipsis">
                                                        {{ bday ? (bday | date:'d MMM yyyy' : '' :'de') : '' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-12 col-xl-6 px-0 pl-3 pl-md-0 pl-xl-2">
                                            <div class="age text-md-center text-left text-xl-left">
                                                <p
                                                    class="color-gray-img fs-14-imp cus-mb-4 white-space-nowrap text-overflow-ellipsis">
                                                    <ng-container *ngIf="dogYears>0; else displayMonthBlock">
                                                        {{dogYears}} {{ dogYears > 1 ? ('PAGE.YEAR' | translate) :
                                                        ('PAGE.ONE_YEAR' | translate) }}
                                                    </ng-container>
                                                    <ng-template #displayMonthBlock>
                                                        {{dogMonths}} {{ dogMonths > 1 ? ('PAGE.MONTH' | translate) :
                                                        ('PAGE.ONE_MONTH' | translate) }}
                                                    </ng-template>
                                                </p>
                                                <p *ngIf="dogAgeHumanYears?.year"
                                                    class="color-gray-img fs-14-imp mb-0 text-overflow-ellipsis white-space-nowrap">
                                                    <ng-container
                                                        *ngIf="dogAgeHumanYears.month >= 5; else elseBeforeMid">
                                                        {{dogAgeHumanYears.year+1 + ' ' + ('PAGE.HUMAN_YEAR' |
                                                        translate)}}
                                                    </ng-container>
                                                    <ng-template #elseBeforeMid>
                                                        {{dogAgeHumanYears.year + ' ' + ('PAGE.HUMAN_YEAR' |
                                                        translate)}}
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dog-details-card dog-detail-modal p-0">
                                    <div class="information">
                                        <ul>
                                            <li class="mb-3">
                                                {{((dog?.size === 'xs'|| dog?.size === 's') ?
                                                ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.SMALL_DOG' | translate) : ((dog?.size
                                                === 'xl'|| dog?.size === 'l') ? ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.BIG_DOG'
                                                | translate) : ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.MEDIUM_DOG' |
                                                translate)))
                                                + ', '+ dog?.weight + (dog?.kg ? ' kg' : ' lbs') + (measure ? ', ' +
                                                ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.'+ measure | translate) : '' )}}
                                            </li>
                                            <li class="mb-3">
                                                {{(dog?.dogType === 'mix_breed_unknown'|| dog?.dogType ===
                                                'mix_breed_known' ? ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.KNOWN_UNKNOWN' |
                                                translate) : (dog?.dogType === 'cross_breed' ?
                                                ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.CROSS_BREED' | translate) :
                                                (dog?.dogType ===
                                                'pure_breed' ? ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.PURE_BREED' | translate)
                                                : ''))) + ((dog?.gender == 'male' ? 'PAGE.MALE' : 'PAGE.FEMALE') |
                                                translate) + (!dog?.surgeries?.sterilized && !dog?.surgeries?.neutered
                                                ? ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.INTACT' | translate) :
                                                ((dog?.surgeries?.sterilized ?
                                                ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.STERLIZED' | translate) : '' ) +
                                                (dog?.surgeries?.neutered ? ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.CASTRATED'
                                                | translate) : '' ))) }}
                                            </li>
                                            <li class="mb-3">
                                                <span>{{(dogType ? ('PAGE.DOGS.DOG_DETAIL.DOG_INFO.'+ dogType |
                                                    translate) : '') }}</span>
                                                <ng-container *ngIf="healthStatusText.length">
                                                    <span>, </span>
                                                    <ng-container
                                                        *ngFor="let item of healthStatusText; let index = index">
                                                        <span>{{('PAGE.DOGS.DOG_DETAIL.DOG_INFO.'+ item |
                                                            translate)}}</span>
                                                        <span>{{healthStatusText.length > 1 && index
                                                            !=(healthStatusText.length-1) ? ', ' : null}}</span>
                                                    </ng-container>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr class="line-above-matching">
                                <div class="profile-modal-btn position-relative border-cus-bttom mt-4">
                                    <div class="gauge-acordion" *ngIf="dogDetail?.overAllMatchingResult != null && !this.data.thirdTab">
                                        <div class="sidebar-pad">
                                            <span class="font-color-1 font-poppins mb-3 text-break progress-bar-header">
                                                {{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.TITLE' | translate :
                                                {name: dog?.name} }}
                                            </span>
                                            <p class="mb-3 mt-3">Matching Mensch-Hund</p>
                                            <!-- valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.GENERAL_RESULT' | translate }}" -->
                                            <app-dog-progress-bar
                                                valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.GENERAL_RESULT' | translate }}"
                                                [value]="dogDetail?.overAllMatchingResult?.overAll">
                                            </app-dog-progress-bar>
                                        </div>
                                        <mat-accordion class="matching-expansion custom-side-accordion">
                                            <mat-expansion-panel (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false" [expanded]="false">
                                                <mat-expansion-panel-header>
                                                    <!-- <span class="matching-details"> -->
                                                        Matching Details anzeigen
                                                    <!-- </span> -->
                                                </mat-expansion-panel-header>
                                                <mat-panel-description>
                                                    <div>
                                                        <app-dog-progress-bar class="progress-bar-detail"
                                                            valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.ENERGY' | translate }}"
                                                            [value]="dogDetail?.overAllMatchingResult?.energy">
                                                        </app-dog-progress-bar>
                                                    </div>
                                                    <div class="my-4">
                                                        <app-dog-progress-bar class="progress-bar-detail"
                                                            valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.CONTACT' | translate }}"
                                                            [value]="dogDetail?.overAllMatchingResult?.desireForContact">
                                                        </app-dog-progress-bar>
                                                    </div>
                                                    <div class="my-4">
                                                        <app-dog-progress-bar class="progress-bar-detail"
                                                            valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.SOCIAL' | translate }}"
                                                            [value]="dogDetail?.overAllMatchingResult?.socialBehavior">
                                                        </app-dog-progress-bar>
                                                    </div>
                                                    <div class="my-4">
                                                        <app-dog-progress-bar class="progress-bar-detail"
                                                            valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.INTEREST' | translate }}"
                                                            [value]="dogDetail?.overAllMatchingResult?.curiosity">
                                                        </app-dog-progress-bar>
                                                    </div>
                                                    <div class="my-4">
                                                        <app-dog-progress-bar class="progress-bar-detail"
                                                            valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.SENSITIVITY' | translate }}"
                                                            [value]="dogDetail?.overAllMatchingResult?.sensitivity">
                                                        </app-dog-progress-bar>
                                                    </div>
                                                    <div class="my-4">
                                                        <app-dog-progress-bar class="progress-bar-detail" valueLabel="{{'PAGE.DOGS.DOG_PERSONALITY.MATCHING_HUMAN_RESULT.LIFESTYLE' | translate }}
                                          " [value]="dogDetail?.overAllMatchingResult?.lifestyle">
                                                        </app-dog-progress-bar>
                                                    </div>
                                                </mat-panel-description>
                                            </mat-expansion-panel>

                                        </mat-accordion>
                                    </div>
                                    <div class="text-left mt-2">
                                        Lese über die Persönlichkeit und jede Eigenschaft vom Hund.
                                    </div> 
                                    <button class="btn theme-primary-btn ml-auto btn-height-45 px-5 mt-4"
                                        (click)="goToPersonality(dog._id)">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.GO_TO_PROFILE' |
                                        translate}}
                                    </button>
                                    <div *ngIf="!dogDetail.ptAvailable" (click)="giveDogPersonalityTest(dog._id)" class="move-to-dog-test-section" [class.owner]="dogDetail?.user?._id === user?._id">
                                        <p *ngIf="dogDetail?.user?._id === user?._id" [innerHTML]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.GO_TO_DOG_TEST' | translate"></p>
                                        <p *ngIf="dogDetail?.user?._id !== user?._id" [innerHTML]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.PERSONALITY_TEST_MISSING' | translate"></p>
                                    </div>
                                </div>



                                <div class="form-row mx-0 mt-3">
                                    <h6 class="color-blue mb-3 font-weight-bold fs-18" *ngIf="dogNature.length">
                                        {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.FEATURE' | translate}}
                                    </h6>
                                    <div *ngIf="dogNature.length" class="w-100">
                                        <span class="fs-16 text-white font-weight-sbold span-info"
                                            *ngFor="let item of dogNature">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.FORTH.OPTION.'+item.toUpperCase()
                                            | translate}}
                                        </span>
                                    </div>
                                    <div class="form-group mb-1 d-flex px-0 col-12 align-items-center">
                                        <span
                                            class="color-gray mr-2 d-none d-md-block fs-16">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.THIRD.OPTION.FIRST'
                                            | translate }}</span>
                                        <mat-slider class="mb-md-2" [max]="20" [min]="1" [step]="1"
                                            [value]="dogDetail.dogBarking" [disabled]="true"
                                            aria-labelledby="example-name-label">
                                        </mat-slider>
                                        <span
                                            class="color-gray ml-3 d-none d-md-block fs-16">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.THIRD.OPTION.SECOND'
                                            | translate }}</span>
                                    </div>
                                    <div class="w-100 d-md-none d-flex justify-content-between mb-3">
                                        <span
                                            class="color-gray fs-16">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.THIRD.OPTION.FIRST'
                                            | translate }}</span>
                                        <span
                                            class="color-gray fs-16">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.THIRD.OPTION.SECOND'
                                            | translate }}</span>
                                    </div>
                                    <div class="w-100" *ngIf="dogIsFriendly.length">
                                        <h6 class="font-weight-medium sub-title-cus">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.COMPATIBLE' |
                                            translate}}
                                        </h6>
                                        <div class="dog-details-card dog-detail-modal p-0" *ngIf="dogIsFriendly.length">
                                            <div class="information">
                                                <ul>
                                                    <li class="mb-3" *ngFor="let item of dogIsFriendly">
                                                        {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.FIRST.OPTION.'+item.toUpperCase()
                                                        | translate}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-100" *ngIf="dogIsGood.length">
                                        <h6 class="font-weight-medium sub-title-cus">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.CAN_BE' | translate}}
                                        </h6>
                                        <div class="dog-details-card dog-detail-modal p-0" *ngIf="dogIsGood.length">
                                            <div class="information">
                                                <ul>
                                                    <li class="mb-3" *ngFor="let item of dogIsGood">
                                                        {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.SECOND.OPTION.'+item.toUpperCase()
                                                        | translate}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 col-xl-8  px-0"
                            [ngClass]=" dogDetail.emergencyCare ? 'bg-FFF7E2' : 'bg-4CADCD-10'">
                            <div class="modal-right-div">
                                <div class="white-box-top">
                                    <ng-container *ngIf="careTiming.length">
                                        <h6 class="font-weight-medium sub-title-cus cus-mb-10">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.CARE_TIME_TEXT' |
                                            translate}}
                                        </h6>
                                        <div class="w-100">
                                            <span class="fs-16 text-white font-weight-sbold span-info"
                                                *ngFor="let item of careTiming">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.SECOND.FIRST.OPTION.'+item.toUpperCase()
                                                | translate}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="dogChoice.length">
                                        <h6 class="font-weight-medium sub-title-cus cus-mb-10">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.DOG_CHOICE_TEXT' |
                                            translate}}
                                        </h6>
                                        <div *ngIf="dogChoice.length" class="w-100">
                                            <span class="fs-16 text-white font-weight-sbold span-info"
                                                *ngFor="let item of dogChoice">{{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.SECOND.SECOND.OPTION.'+item.toUpperCase()
                                                | translate}}</span>
                                        </div>
                                    </ng-container>
                                    <div class="qa-list-section col-xl-7 col-lg-9 px-0">
                                        <!-- <div class="row list-loop">
                                            <div class="col-9">
                                                <h6 class="font-weight-medium sub-title-cus">Erfahrung mit Hunden nötig:</h6>
                                            </div>
                                            <div class="col-3">
                                                <p class="paw-img-list">ja</p>
                                            </div>
                                        </div> -->
                                        <div class="row list-loop" *ngIf="dogDetail.onlyWalk !== undefined">
                                            <div class="col-9">
                                                <h6 class="font-weight-medium sub-title-cus">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.ONLY_WALK_TEXT'
                                                    | translate}}
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <p class="paw-img-list">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.'+(dogDetail.onlyWalk
                                                    === 'yes' ? 'YES' : 'NO') | translate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row list-loop">
                                            <div class="col-9">
                                                <h6 class="font-weight-medium sub-title-cus">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.INSURANCE_TEXT'
                                                    | translate}}
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <p class="paw-img-list">
                                                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.'+(dogDetail.insurance
                                                    === 'yes' ? 'YES' : 'NO') | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="white-box-bottom paw-background-image">
                                    <div class="quote-white-box">
                                        <img src="assets/img/quote-profile.svg" alt="FairDogs_Hundehalter">
                                    </div>
                                    <p class="fs-16 color-gray white-space-break text-break">
                                        {{dogDetail?.dogDescription}}
                                    </p>
                                    <div *ngIf="!dogDetail.anonymousAd"
                                        class="profile-status row mx-0 align-items-center">
                                        <div class="prof-width-left col-3 px-0">
                                            <div class="border-radius-50 overflow-hidden profile-status-img">
                                                <img [src]="dogDetail?.user?.profilePhoto ? dogDetail?.user?.profilePhoto : 'assets/img/img_person_placeholder_web.svg'"
                                                    alt="FairDogs_Hundehalter" (click)="userinfo()">
                                            </div>
                                        </div>
                                        <!-- <div class="prof-width-left col-3 px-0" *ngIf="dogDetail?.user?.profilePhoto">
                                            <div class="border-radius-50 overflow-hidden profile-status-img">
                                                <img [src]="dogDetail?.user?.profilePhoto ? dogDetail?.user?.profilePhoto : 'assets/img/img_person_placeholder_web.svg'"
                                                    alt="Image">
                                            </div>
                                        </div> -->
                                        <div class="prof-width-right col-9 px-0">
                                            <p class="status-text text-overflow-ellipsis text-break">
                                                <ng-container *ngIf="dogDetail?.user?.userName; else userNameNull">
                                                    {{ dogDetail?.user?.userName ? dogDetail?.user?.userName : '' }}
                                                </ng-container>
                                                <ng-template #userNameNull>
                                                    {{dogDetail?.user?.firstName + ' ' +
                                                    dogDetail?.user?.lastName?.charAt(0)+ '.'}}
                                                </ng-template>
                                            </p>
                                            <!-- <div class="status-dynamic">
                                                <p class="mb-0">offline</p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="d-flex flex-wrap flex-xl-nowrap justify-content-center align-items-center px-4 custom-mb">
                                    <div class="width-sm-responsive">
                                        <div>
                                            <ng-container *ngIf="dogDetail.image; else elseBlock">
                                                <div class="three-view-show box-shadow-profile mr-md-0 mr-auto mr-lg-3">
                                                    <div>
                                                        <div class="dog-details-box position-relative">
                                                            <button *ngIf="data.participant" mat-icon-button
                                                                [matMenuTriggerFor]="menu"
                                                                class="btn btn-toggle-custom p-0"
                                                                aria-label="Example icon-button with a menu">
                                                                <img src="assets/img/more.svg" alt="FairDogs_More">
                                                            </button>
                                                            <mat-menu #menu="matMenu" xPosition="before"
                                                                class="dog-gallery-drop">
                                                                <!-- <input #fileInputEdit (change)="onImageEdit($event)" 
                                                                    type="file"
                                                                    accept="image/x-png,image/bmp,image/jpeg,image/gif"
                                                                    class="cover-image-input"> -->
                                                                <button mat-menu-item (click)="openCropImageDialog()">
                                                                    <img class="mr-2" src="assets/img/ic_edit-white.svg"
                                                                        alt="FairDogs_Edit"><span>{{
                                                                        'PAGE.SHARED.PICTURE_GALLARY.UPDATE' |
                                                                        translate}}</span>
                                                                </button>
                                                                <button *ngIf="!dogDetail.published" mat-menu-item
                                                                    (click)="onImageDelete()">
                                                                    <img class="mr-2"
                                                                        src="assets/img/ic_delete_white.svg"
                                                                        alt="FairDogs_Delete"><span>{{
                                                                        'PAGE.SHARED.PICTURE_GALLARY.DELETE' |
                                                                        translate}}</span>
                                                                </button>
                                                            </mat-menu>
                                                            <div class="overflow-hidden list-view-img">
                                                                <img class="w-100" [src]="dogDetail.image"
                                                                    alt="FairDogs_Hunde">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                <div *ngIf="data.participant && !dogDetail.published">
                                                    <div class="if-no-img mr-md-0 mr-auto mr-lg-3">
                                                        <div class="dog-details-box">
                                                            <div class="overflow-hidden list-view-img">
                                                                <!-- <input #fileInputOther (change)="onImageEdit($event)" 
                                                                    type="file"
                                                                    accept="image/png,image/bmp,image/jpeg,image/gif"
                                                                    class="cover-image-input"> -->
                                                                <img class="cursor-pointer"
                                                                    matTooltip="{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.IMAGE_TOOLTIP' | translate }}"
                                                                    [src]="'assets/img/img_upload_placefolder.svg'"
                                                                    alt="FairDogs_ImageUpload"
                                                                    (click)="openCropImageDialog()">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="profile-modal-btn mb-0 ml-0 ml-lg-4"
                                        *ngIf="dogDetail.imageGallery.length && !dogDetail.anonymousAd">
                                        <button
                                            class="btn theme-primary-btn ml-auto btn-height-45 px-5 mb-0 font-weight-bold"
                                            (click)="viewGallary()">
                                            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.VIEW_GALLARY' |
                                            translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!data.participant">
        <div role="button" class="dialog-arrow-left dialog-slider-arrow d-none d-md-block"
            [ngClass]="{'disabled': this.currentIndex===0}">
            <img (click)="this.currentIndex !== 0 && onPrev()" src="assets/img/ic_arrow_right.svg"
                alt="FairDogs_ArrowRight">
        </div>
        <div role="button" class="dialog-arrow-right dialog-slider-arrow d-none d-md-block"
            [ngClass]="{'disabled': (this.currentIndex+1)===this.searchDataLength}">
            <img (click)="(this.currentIndex+1) !== this.searchDataLength && onNext()"
                src="assets/img/ic_arrow_right.svg" alt="FairDogs_ArrowRight">
        </div>
    </ng-container>
</div>