import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { SEOService } from '@core/services/seo-service';

@Injectable({ providedIn: 'root' })
export class SEOResolver implements Resolve<void> {
    constructor(
        private seoService: SEOService
    ) { }
    async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        if (!this.seoService.seoData)
            this.seoService.seoData = await this.getMetaData();
    }

    getMetaData() {
        return new Promise((resolve, reject) => {
            this.seoService.getMetaTags().subscribe((response: any) => {
                let dt: any = {};
                const metaDt = response?.data;
                this.seoService.seoData = {}
                metaDt && metaDt?.forEach((item: any) => {
                    dt[item.page] = item.meta;
                    this.seoService.seoData[item.page] = item.meta;
                });
                this.seoService.seoData = response.data;
                this.seoService.MetaData = dt;
                resolve(response.data);
            }, error => {
                reject({});
            })
        })
    }
}
