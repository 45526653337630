import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-participant-popup',
  templateUrl: './delete-participant-popup.component.html',
  styleUrls: ['./delete-participant-popup.component.scss']
})
export class DeleteParticipantPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteParticipantPopupComponent>
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onAction(): void {
      this.dialogRef.close({ reason: true });
  }

  onSecondaryAction(): void{
    this.dialogRef.close({ reason: false });
  }

}
