import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/auth/services/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean { 
    if(state.url.includes("membership") || state.url.includes("goodies")){
      return true
    }
    const isAuth = this.authService.isAuthenticated();
    if (!isAuth) {
      this.router.navigate(['/']);
      // window.open(environment.wpApiUrl, "_self")
      return false;
    }
    return true;
  }
}
