<div class="box-result" [ngClass]="{'cursor-pointer': !participant}" (click)="!participant ? onDogPreviewDialog(item._id) : null;">
    <div class="card--img">
        <div class="img-result">
            <img class="cursor-pointer" (click)="participant ? onDogPreviewDialog(item._id) : null;"
                [src]="item?.image ? item?.image : (item?.dog?.profilePhoto ? item?.dog?.profilePhoto : 'assets/img/img_dog_placeholder_square.svg')"
                alt="FairDogs_Hunde">
            <span class="image-title d-inline-block"
                *ngIf="item.emergencyCare">{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.EMERGENCY_TITLE' |
                translate}}</span>
        </div>
        <ng-container *ngIf="!participant; else activateBlock">
            <div class="fav-work">
                <img width="20" class="cursor-pointer fav-unfav-shadow"
                    (click)="favouriteChange($event, item._id,item.favourite)"
                    [src]="item.favourite ? 'assets/img/fav_view.svg' : 'assets/img/un-fav_view.svg'"
                    alt="FairDogs_FavHunde">
            </div>
        </ng-container>
        <ng-template #activateBlock>
            <div class="onoff-switch fav-work">
                <div class="d-flex align-items-center" [matTooltipPosition]="'above'"
                    [matTooltip]="!item.published ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.PUBLISH_TOOLTIP' | translate) : null">
                    <mat-slide-toggle *ngIf="showToggle" [checked]="item.active"
                        (change)="activateChange(item._id, $event, item, '')">
                    </mat-slide-toggle>
                    <div class="ml-2">
                        <p class="m-0 fs-12 font-weight-medium"
                            [ngClass]="item.active ? 'color-green' : 'font-color-gray'">{{item.active ?
                            ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.ACTIVE' | translate) :
                            ('PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.INACTIVE' | translate)}}</p>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class="l-action-result">
            <div class="d-flex flex-row  justify-content-start align-items-center">
                <div class="col-md-6 test-percentage">
                    <div *ngIf="item?.overAllMatchingResult?.overAll">
                        <span class="text-white fs-16 pt-1 d-flex"> Matching</span>
                        <mat-slider class="mb-md-2" [min]="1" [max]="100" [step]="1" [disabled]="true"
                            [value]="item?.overAllMatchingResult?.overAll" aria-labelledby="example-name-label">
                        </mat-slider>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end">
                    <ul class="list-unstyled main-selected-animals  d-flex mr-4 ">
                        <li class="" [matTooltipPosition]="'above'"
                            [matTooltip]="item.dogIsFriendly.cat ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.CAT' | translate) : ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.CAT_CROSS' | translate)"
                            [matTooltipClass]="'mat-tooltip-for-icon'">
                            <img [src]="item.dogIsFriendly.cat ? 'assets/img/cats.svg' : 'assets/img/notcompatiblecats.svg'"
                                width="34" alt="Hundepflege">
                        </li>
                        <li class="" [matTooltipPosition]="'above'"
                            *ngIf="item.dogIsFriendly.smallChildren === item.dogIsFriendly.children"
                            [matTooltip]="(item.dogIsFriendly.smallChildren || item.dogIsFriendly.children) ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.KID' | translate) : ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.KID_CROSS' | translate)"
                            [matTooltipClass]="'mat-tooltip-for-icon'">
                            <img [src]="(item.dogIsFriendly.smallChildren || item.dogIsFriendly.children) ? 'assets/img/Child_friendly.svg' : 'assets/img/notUnsuitable_children.svg'"
                                width="34" alt="Hundebabysitting">
                        </li>
                        <li class="" [matTooltipPosition]="'above'"
                            [matTooltip]="item.dogIsFriendly.dog ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.DOG' | translate) : ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.DOG_CROSS' | translate)"
                            [matTooltipClass]="'mat-tooltip-for-icon'">
                            <img [src]="item.dogIsFriendly.dog ? 'assets/img/dog-rabbit-new.svg' : 'assets/img/no-dog-rabbit-new.svg'"
                                width="34" alt="Betreuung eines Welpen">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="desc-result">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="name-beside-gender">
                <h6 class="font-weight-bold fs-16 color-blue text-truncate text-nowrap mb-0">{{item?.dog?.name}}</h6>
            </div>
            <div class="gender-result">
                <img [src]="item?.dog?.gender==='male' ? 'assets/img/male.svg' : 'assets/img/female.svg'"
                    alt="FairDogs_GenderFemale">
                <span class="ml-2 fs-14 color-gray">{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.SIZE'
                    | translate
                    }}{{item?.dog?.size.toUpperCase()}}</span>
            </div>
        </div>
        <div>
            <p class="fs-14 color-gray mb-2">{{(item.dogType ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.'+
                item.dogType | translate) : '') }} •
                <ng-container *ngIf="item.dogYears>0; else displayMonthBlock">
                    {{item.dogYears}} {{ item.dogYears > 1 ? ('PAGE.YEAR' | translate) : ('PAGE.ONE_YEAR' | translate)
                    }}
                </ng-container>
                <ng-template #displayMonthBlock>
                    {{item.dogMonths}} {{ item.dogMonths > 1 ? ('PAGE.MONTH' | translate) : ('PAGE.ONE_MONTH' |
                    translate) }}
                </ng-template>
            </p>
        </div>
        <div *ngIf="item.dogNatureExist.length">
            <span class="badge px-2 bg-98B0C8 text-white badge-bold fs-12 mr-2 text-lowercase"
                *ngFor="let i of item.dogNatureExist">
                {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.FIRST.FORTH.OPTION.'+i.toUpperCase() | translate}}
            </span>
        </div>
        <ng-container *ngIf="item.showLastLine">
            <div class="divide-result-work col-2 px-0">
                <hr class="my-2">
            </div>
            <div
                [ngClass]="{'four-items-small': (item.dogChoice.longWalk || item.dogChoice.shortWalk) && item.careTiming.weekdays && item.careTiming.weekends && (item.careTiming.occasionally || item.careTiming.night)}">
                <h6 class="color-blue fs-14 font-weight-sbold mb-1">
                    {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.SEARCH_FOR' | translate }}
                </h6>
                <div class="list-amentiy">
                    <ul class="list-unstyled m-0 p-0 d-flex">
                        <li class="cursor-pointer" *ngIf="item.dogChoice.longWalk || item.dogChoice.shortWalk"
                            [matTooltipPosition]="'above'"
                            [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.WALK' | translate"
                            [matTooltipClass]="'mat-tooltip-for-icon'">
                            <span class="color-gray fs-14 font-weight-normal">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.WALK' | translate }}
                                <img width="12" src="assets/img/ic_paw.svg" alt="FairDogs_Hunde">
                            </span>
                        </li>
                        <li class="cursor-pointer" *ngIf="item.careTiming.weekdays" [matTooltipPosition]="'above'"
                            [matTooltip]="('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.WEEKDAY' | translate) +
                        ((item.careTiming.allDay || item.careTiming.morning || item.careTiming.overnoon || item.careTiming.afternoon || item.careTiming.evening || item.careTiming.night) ? ',' : '') +
                        (item.careTiming.allDay || item.careTiming.morning || item.careTiming.overnoon || item.careTiming.afternoon ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.SUN' | translate) : '') + 
                        ((item.careTiming.allDay || item.careTiming.morning || item.careTiming.overnoon || item.careTiming.afternoon) && (item.careTiming.evening || item.careTiming.night) ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.AND' | translate) : '') + 
                        (item.careTiming.evening || item.careTiming.night ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.MOON' | translate) : '')
                        " [matTooltipClass]="'mat-tooltip-for-icon'">
                            <span class="color-gray fs-14 font-weight-normal">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.WEEKDAY' | translate }}
                            </span>
                            <!-- <img width="12" class="ml-1" src="assets/img/ic_paw.svg" alt=""> -->
                            <img src="assets/img/sun_first.svg" alt="FairDogs_Sun"
                                *ngIf="item.careTiming.allDay || item.careTiming.morning || item.careTiming.overnoon || item.careTiming.afternoon">
                            <img src="assets/img/moon_second.svg" alt="FairDogs_Moon"
                                *ngIf="item.careTiming.evening || item.careTiming.night">
                        </li>
                        <li class="cursor-pointer" *ngIf="item.careTiming.weekends" [matTooltipPosition]="'above'"
                            [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.WEEKEND' | translate"
                            [matTooltipClass]="'mat-tooltip-for-icon'">
                            <span class="color-gray fs-14 font-weight-normal">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.WEEKEND' | translate }}
                                <img width="12" src="assets/img/ic_paw.svg" alt="FairDogs_Hunde">
                            </span>
                        </li>
                        <li class="cursor-pointer" *ngIf="item.careTiming.occasionally || item.careTiming.night"
                            [matTooltipPosition]="'above'"
                            [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.VACATION' | translate"
                            [matTooltipClass]="'mat-tooltip-for-icon'">
                            <span class="color-gray fs-14 font-weight-normal">
                                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.VACATION' | translate }}
                                <img width="12" src="assets/img/ic_paw.svg" alt="FairDogs_Hunde">
                            </span>
                        </li>
                        <div  *ngIf="careTimingList.length === 1">
                            <li class="cursor-pointer" *ngFor="let item of careTimingList"
                                [matTooltipPosition]="'above'"
                                [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.' + item.toUpperCase() | translate"
                                [matTooltipClass]="'mat-tooltip-for-icon'">

                                <span class="color-gray fs-14 font-weight-normal"
                                    *ngIf="item === 'allDay' || item === 'morning' || item === 'overnoon' || item === 'afternoon' || item === 'evening' || item === 'night'">
                                    {{'PAGE.DOG_CARE.DOG_SHARING.TAB.FIRST_TAB.DOG_FORM.SECOND.FIRST.OPTION.'+
                                    item.toUpperCase()
                                    | translate}}</span>
                                <img class="ml-1" src="assets/img/sun_first.svg" alt="FairDogs_Sun"
                                    *ngIf="item === 'allDay' || item === 'morning' || item === 'overnoon' || item === 'afternoon'">
                                <img class="ml-1" src="assets/img/moon_second.svg" alt="FairDogs_Moon"
                                    *ngIf="item === 'evening' || item === 'night'">
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="participant || (!participant && (item.distance >= 0))"
        class=" d-flex align-items-center bottom-cta-result km-main card-action-bottom"
        [ngClass]="item.emergencyCare ? 'bg-FFF5DA' : 'bg-C7E5EF'">
        <div *ngIf="!participant && (item.distance >= 0)"
            class="d-flex align-items-center justify-content-between text-truncate mw-100">
            <div class="d-flex align-items-center">
                <div class="location-km-img">
                    <img src="assets/img/ic_gray-loaction.svg" alt="FairDogs_Location">
                </div>
                <div>
                    <p class="m-0 color-gray fs-12 text-nowrap">
                        <span class="d-inline-block mr-1">
                            <ng-container *ngIf="item.distance > 1; else meterBlock">
                                {{item.distance | number: '1.0-0'}}km
                            </ng-container>
                            <ng-template #meterBlock>
                                {{(item.distance*1000) | number : '1.0-0'}}m
                            </ng-template>
                        </span>{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.FROM_YOU' | translate }}
                    </p>
                </div>
            </div>
            <div *ngIf="false" class="status-symbol status-offline">
                <p class="m-0 status-dot-dynamic fs-12">offline</p>
            </div>
            <div class="text-truncate mw-100" *ngIf="!item?.displayLocation">
                <p class="m-0 color-gray fs-12 text-nowrap d-flex flex-nowrap"
                    [ngClass]="{'content-seperate': !item?.displayLocation}">
                    <ng-container
                        *ngIf="(item.user?.location?.city || item.user?.location?.state) && item.user?.location?.countryName; else elseBlock">
                        <ng-container *ngIf="item.user?.location?.city">
                            <span class="text-truncate location-width">{{item.user?.location?.city}}</span>,
                        </ng-container>
                        <ng-container *ngIf="item.user?.location?.state">
                            <span class="pl-1 text-truncate location-width">{{item.user?.location?.state}}</span>,
                        </ng-container>
                        <span class="pl-1">{{item.user?.location?.countryName}}</span>
                    </ng-container>
                    <ng-template #elseBlock> <span>{{item.user?.country}}</span> </ng-template>
                </p>
            </div>
        </div>
        <div class="d-flex ml-auto" *ngIf="participant">
            <button class="all-edit-btn justify-content-center align-items-center d-flex"
                [matTooltipPosition]="'above'"
                [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.SHOW_PROFILE' | translate"
                [matTooltipClass]="'mat-tooltip-for-icon'"
                (click)="onDogPreviewDialog(item._id)"
            >
                <img type="image/svg+xml" src="assets/img/eye-blue.svg" alt="FairDogs_BlueEye">
            </button>
            <button class="all-edit-btn justify-content-center align-items-center d-flex" 
                [matTooltipPosition]="'above'"
                [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.CHANGE_PROFILE' | translate"
                [matTooltipClass]="'mat-tooltip-for-icon'"
                (click)="onDogEdit(item._id)"
            >
                <img type="image/svg+xml" src="assets/img/ic_edit.svg" alt="FairDogs_Edit">
            </button>
            <!-- deletePublication(item._id,item.type, item.active)   manageDeletePublicationPopup(item._id,item.type, item.active) -->
            <button class="all-edit-btn justify-content-center align-items-center d-flex mr-0"
                [matTooltipPosition]="'above'"
                [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.DELETE_PROFILE' | translate"
                [matTooltipClass]="'mat-tooltip-for-icon'"
                (click)="manageDeletePublicationPopup(item._id,item.type, item.active)"
            >
                <img type="image/svg+xml" src="assets/img/ic_delete_gray.svg" alt="FairDogs_Delete">
            </button>
        </div>
    </div>
</div>