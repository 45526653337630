<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0 bg-white-header">
        <mat-toolbar-row class="row ml-0 mr-0 dialog-wuffdog mt-2 mb-2" style="height: auto;">
            <div class="col-12 d-flex justify-content-end">
                <svg class="ic_close cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                    (click)="matDialogRef.close();" viewBox="0 0 40 40" style="z-index: 9;">
                    <g id="close" transform="translate(-1265 -197)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                            transform="translate(1265 197)" fill="#fed66b" />
                        <path id="Path_1" data-name="Path 1"
                            d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                            transform="translate(1278 209.984)" fill="#326291" />
                    </g>
                </svg>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="m-0 modal-body" style="padding: 24px;">
        <div class="row ml-0 mr-0">
            <div class="col-12">
                <h4 style="white-space: pre-wrap;">Vorsicht: Ohne Anzeige kein Zugriff auf Dog Sharing & Chat</h4>
            </div>
            <div class="col-md-12 text-center">
                Um Anzeigen anzusehen oder den Chat zu nutzen, ist mindestens eine Dog Sharing-Anzeige erforderlich. Um
                Dog Sharing und den Chat weiter zu nutzen, kannst du die Anzeige deaktivieren.
                <br />
                Wie möchtest du mit der Anzeige fortfahren?
            </div>
            <div class="col-12 d-flex justify-content-center mt-4 mb-4">
                <button class="btn btn-theme btn-fill-white mb-0 mr-2" (click)="matDialogRef.close({action:''})">
                    Abbrechen
                </button>
                <button class="btn btn-theme btn-fill-yellow mb-0 mr-2"
                    (click)="matDialogRef.close({action:'deactive'})">
                    Deaktivieren
                </button>
                <button class="btn btn-theme mb-0 mr-2" (click)="matDialogRef.close({action:'delete'})">
                    Löschen
                </button>
            </div>
        </div>
    </div>

</div>


<!-- <div class="edit-profile-picture-box info--popup">
    <div class="media">
        <div class="modal-body px-0">
            <h4>Vorsicht: Ohne Anzeige kein Zugriff auf Dog Sharing & Chat</h4>
            <div class="p-4">

                <div class="d-flex">
                    <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                        viewBox="0 0 40 40">
                        <g id="close" transform="translate(-1265 -197)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                transform="translate(1265 197)" fill="#fed66b" />
                            <path id="Path_1" data-name="Path 1"
                                d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                                transform="translate(1278 209.984)" fill="#326291" />
                        </g>
                    </svg>
                </div>

            </div>

        </div>
    </div>
</div> -->