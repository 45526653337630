import { JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DogModel } from '@core/models/dog.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Action, StateContext, Store } from '@ngxs/store';
import { Selector, State } from '@ngxs/store';
import { AuthService } from 'app/auth/services/auth.service';
import { LoginState } from 'app/auth/store/login/login.state';
import { DogTestResultState } from 'app/profile/components/profile-dogs/components/dog-test-result/store/dog-test-result.state';
import { DogDetailState } from 'app/profile/components/profile-dogs/shared/store/dog-detail/dog-detail.state';
import { DogService } from 'app/profile/shared/services/dogs.service';
import { UserState } from 'app/profile/shared/store/user/user.state';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AnswerModel } from '../models/answer.model';
// import { DogTestService } from '../shared/services/dog-test.service';
import { PersonTestService } from '../shared/services/person-test.service';
import {
    IncrementPersonTestPage, SavePersonTest, SavePersonTestAnswers, AddTestPerson, ALLBreedDogAction,
    AddRegister, SetPersonTestPage, SetRepeatTestId, RepeatTest, SetFreeTestFlag, SetRepeatTestIdNull
} from './person-test.actions';
import { environment } from 'environments/environment';

export interface PersonTestStateModel {
    loading: boolean;
    error: any;
    person?: DogModel;
    // dogBreed: any;
    answers: AnswerModel[];
    currentPage: number;
    register: any;

    repeatTestId: number | any;
    existingEmail: boolean;
    freeTestFlag: boolean;
}

const defaultState: PersonTestStateModel = {
    loading: false,
    error: undefined,
    person: undefined,
    // dogBreed: [],
    answers: [],
    currentPage: 1,
    register: null,

    repeatTestId: null,
    existingEmail: false,
    freeTestFlag: false
};

@State<PersonTestStateModel>({
    name: 'personTest',
    defaults: defaultState
})
@Injectable()
export class PersonTestState {

    @Selector()
    public static getState(state: PersonTestStateModel): PersonTestStateModel {
        return state;
    }

    @Selector()
    public static currentPage(state: PersonTestStateModel): number {
        return state.currentPage;
    }

    @Selector()
    public static loading(state: PersonTestStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static answers(state: PersonTestStateModel): AnswerModel[] {
        return state.answers;
    }

    // @Selector()
    // public static dogBreed(state: PersonTestStateModel): any[] {
    //     return state.dogBreed;
    // }
    @Selector()
    public static repeatTestId(state: PersonTestStateModel): any {
        return state.repeatTestId;
    }

    @Selector()
    public static existingEmail(state: PersonTestStateModel): boolean {
        return state.existingEmail;
    }

    @Selector()
    public static freeTestFlag(state: PersonTestStateModel): boolean {
        return state.freeTestFlag;
    }

    constructor(
        private dogService: DogService,
        private personTestService: PersonTestService,
        private store: Store,
        private router: Router,
        private authService: AuthService,
        public dialog: MatDialog,
        private toastr: ToastrNotificationService
    ) { }

    @Action(SavePersonTestAnswers)
    SavePersonTestAnswers(ctx: StateContext<PersonTestStateModel>, action: SavePersonTestAnswers): any {
        const answers = ctx.getState().answers;

        let newAnserws: AnswerModel[] = [];
        answers.forEach(el => {
            const find = action.answers.find(item => item.questionId === el.questionId);
            if (!find) {
                newAnserws = [...newAnserws, el];
            }
        });

        ctx.patchState({
            answers: [...newAnserws, ...action.answers]
        });
    }

    @Action(IncrementPersonTestPage)
    incrementPersonTestPage(ctx: StateContext<PersonTestStateModel>, action: IncrementPersonTestPage): any {
        let increment = (ctx.getState().currentPage + action.increment);
        if (increment < 1) {
            increment = 1;
        }

        if (increment > 6) {
            increment = 6;
        }
        ctx.patchState({
            currentPage: increment
        });
    }

    @Action(SetPersonTestPage)
    settPersonTestPage(ctx: StateContext<PersonTestStateModel>, action: SetPersonTestPage): any {
        // console.log(action.payload);

        ctx.patchState({
            currentPage: action.payload,
            person: undefined,
            answers: [],
            register: null
        });
    }

    @Action(SavePersonTest)
    savePersonTest(ctx: StateContext<PersonTestStateModel>, action: SavePersonTest): any {
        ctx.patchState({ loading: true });
        const person = ctx.getState().person as any;
        const answers = ctx.getState().answers;
        const register = ctx.getState().register;
        let user: any = this.store.selectSnapshot(UserState.user);
        // select the snapshot state from preferences
        const isloggedIn = this.authService.isAuthenticated();
        const personId = user?._id;

        let redirectUrl = `../`;
        let model = {};
        if (isloggedIn) {
            const isFreetest = ctx.getState().freeTestFlag;
            model = {
                personId,
                personalityTestResult: answers,
                isFreetest
            };
            redirectUrl = `/profile/person/${personId}/test-result/`;
        } else {
            const personObj = {
                ...person,
                birthDay: person?.birthDate
            };
            delete personObj.birthDate;
            model = {
                person: personObj,
                personalityTestResult: answers,
                registrationData: register,
                isFreetest: true
            };
        }

        // this.dialog.closeAll();
        return this.personTestService.createTest(model, isloggedIn)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (isloggedIn) {
                        response.data._id ? redirectUrl += response.data._id : redirectUrl += 'latest';
                    } else {
                        redirectUrl = '/';
                    }

                    this.toastr.showSuccess('', response.message || '');
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        currentPage: 6,
                        existingEmail: false
                    });
                }),
                catchError((err) => {
                    // console.log('save test response', err);
                    if (err.error.data && err.error.data.email) {
                        // console.log('err email', err.error.data.email);
                    }
                    if (err.error.data && err.error.data.test) {
                        // console.log('err test', err.error.data.test);
                    }
                    ctx.patchState({
                        error: err,
                        existingEmail: true,
                    });
                    this.toastr.showError('', err.error.message);
                    return throwError(err);
                }),
                finalize(() => {
                    if(redirectUrl === '/')
                        window.location.href = environment.wpApiUrl;
                    // this.router.navigateByUrl(redirectUrl);
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddTestPerson)
    addTestDog(ctx: StateContext<PersonTestStateModel>, action: AddTestPerson): any {
        let person = ctx.getState().person;
        person = { ...person, ...action.payload };
        ctx.patchState({
            person
        });
    }

    @Action(ALLBreedDogAction)
    getAllBreed(ctx: StateContext<PersonTestStateModel>, action: ALLBreedDogAction): any {
        ctx.patchState({ loading: true });
        return this.personTestService.getAllBreeds()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                        // dogBreed: response.data
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddRegister)
    addRegister(ctx: StateContext<PersonTestStateModel>, action: AddRegister): any {
        ctx.patchState({
            register: action.payload
        });
    }

    @Action(SetRepeatTestId)
    addRSetRepeatTestIdegister(ctx: StateContext<PersonTestStateModel>): any {
        const latestTestId = this.store.selectSnapshot(DogTestResultState.latestTestId);
        ctx.patchState({
            repeatTestId: latestTestId
        });
    }

    /**
     * this feature not in work right now for person test
     * @param ctx 
     * @returns 
     */
    @Action(RepeatTest)
    repeatTest(ctx: StateContext<PersonTestStateModel>): any {
        ctx.patchState({ loading: true });
        const testId = ctx.getState().repeatTestId;
        const answers = ctx.getState().answers;

        const dogId = this.store.selectSnapshot(DogDetailState.dogId);

        const redirectUrl = `/profile/dogs/${dogId}/test-result`;
        let model = {};
        model = {
            dogId,
            personalityTestResult: answers,
        };

        return this.personTestService.patchTest(testId, model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    this.toastr.showSuccess('', response.message || '');
                    ctx.patchState({
                        error: undefined,
                        loading: false,
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message);
                    return throwError(err);
                }),
                finalize(() => {
                    // this.router.navigateByUrl(redirectUrl);
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetFreeTestFlag)
    SetFreeTestFlag(ctx: StateContext<PersonTestStateModel>, action: SetFreeTestFlag): any {
        ctx.patchState({
            freeTestFlag: action.model,
        });
    }

    @Action(SetRepeatTestIdNull)
    setRepeatTestIdNull(ctx: StateContext<PersonTestStateModel>): any {
        ctx.patchState({
            repeatTestId: ''
        });
    }

}
