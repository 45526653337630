<div class="content-container">
    <div class="header-text">
        Persönlichkeitstest
    </div>
    <div class="sub-header-text">
        für Hundefreunde & Hundehalter
    </div>
    <div class="d-flex flex-column">

        <p class="font-size-16 mt-3 mb2 font-weight-bold color-dark-gray">Der Persönlichkeitstest für Hundehalter
            beschreibt deine
            Persönlichkeit und
            Einstellung zu Hunden im Alltag.</p>

        <p class="font-size-16 font-weight-bold mt-2 mb-0 color-dark-gray">Mit dem Resultat kannst du</p>
        <div class="mt-0 color-gray">
            <ol class="list-info">
                <li>über deine Charaktereigenschaften lesen.</li>
                <li>deine Persönlichkeit mit eigenen oder anderen Hunden vergleichen.</li>
                <li>sehen wie gut ein Hund zu dir passt.</li>
                <li>die Unterschiede zwischen dir und (d)einem Hund besser verstehen.</li>
                <li>einfacher einen passenden Hund finden.</li>
            </ol>
        </div>
        <p class="font-size-16 mt-2 color-gray">Standardisierte Persönlichkeitsberichte helfen Hundetrainern, damit sie
            massgeschneidert auf dich und deinen Hund eingehen können. Auch Tierheime oder Züchter können dir damit
            leichter passende Hunde vorstellen.</p>

        <p class="font-size-16 mt-2 color-gray">Die Auswertung besteht aus Grafiken und einem Beschrieb der
            Persönlichkeit und ähnelt der Auswertung für Hunde. Der Persönlichkeitstest für Hundefreunde ist
            kostenpflichtig. Im nächsten Schritt siehst du die Preise, bevor du ihn startest.</p>


        <!--action : begin-->
        <div class="d-flex justify-content-center align-items-end">
            <!-- <button *ngIf="!isAuthenticated" class="btn border-color-dark-gray border-2 border-radius-1 bgr-white mr2"
                    (click)="back()">
                    <img class="" style="opacity: .5;" src="assets/img/arrow-left.png" width="17px" height="14px" />
                </button> -->
            <!-- saveAndNext() managePersonTestPaymentCheck() -->
            <button class="btn-fill-blue mb-0 p1 primary-btn border-radius-lg pl3 pr3"
                (click)="managePersonTestPaymentCheck()">
                Weiter</button>
        </div>
        <div
            class="d-flex flex-column justify-content-center align-items-center flex-grow-1 mt3 mb3 w-100 coming-soon-img">
            <img src="assets/img/comingsoon.png" />
        </div>
        <!--action : end-->
    </div>
</div>