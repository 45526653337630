import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AnswerModel } from 'app/person-test/models/answer.model';
import { IncrementPersonTestPage, SavePersonTestAnswers } from 'app/person-test/store/person-test.actions';
import { PersonTestState } from 'app/person-test/store/person-test.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import testQuestions from '../../shared/personpersonalitytestquestions';
import { Constants, peoplepersonalitytestquestions } from 'app/contants';
@Component({
  selector: 'app-person-test-page-three',
  templateUrl: './person-test-page-three.component.html',
  styleUrls: ['./person-test-page-three.component.scss']
})
export class PersonTestPageThreeComponent implements OnInit {

  question3?= peoplepersonalitytestquestions.find(el => el.questionId === 3);
  question4?= peoplepersonalitytestquestions.find(el => el.questionId === 4);
  question5?= peoplepersonalitytestquestions.find(el => el.questionId === 5);
  question6?= peoplepersonalitytestquestions.find(el => el.questionId === 6);
  question7?= peoplepersonalitytestquestions.find(el => el.questionId === 7);

  questions = [
    { ...this.question3, result: 0 },
    { ...this.question4, result: 0 },
    { ...this.question5, result: 0 },
    { ...this.question6, result: 0 },
    { ...this.question7, result: 0 },
  ];


  @Select(PersonTestState.answers) answersState$!: Observable<AnswerModel[]>;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.answersState$.pipe(
      tap(data => {
        this.questions.forEach(element => {
          const find = data.find(el => el.questionId === element.questionId);
          if (find) {
            element.result = find?.result;
          }
        });
      })
    ).subscribe();
  }

  saveResults(form: any): void {
    const answers: AnswerModel[] = [
      { questionId: 3, type: this.question3?.type, result: form.questionId3 },
      { questionId: 4, type: this.question4?.type, result: form.questionId4 },
      { questionId: 5, type: this.question5?.type, result: form.questionId5 },
      { questionId: 6, type: this.question6?.type, result: form.questionId6 },
      { questionId: 7, type: this.question7?.type, result: form.questionId7 },
    ];
    this.store.dispatch(new SavePersonTestAnswers(answers));
  }

  saveAndNext(form: any): void {
    this.saveResults(form);
    this.store.dispatch(new IncrementPersonTestPage(1));
  }

  back(form: any): void {
    this.saveResults(form);
    this.store.dispatch(new IncrementPersonTestPage(-1));
  }

}
