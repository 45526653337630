import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@core/guard/authenticated.guard';
import { UnauthenticatedGuard } from '@core/guard/unauthenticated.guard';
import { SEOResolver } from '@core/resolver/seo.resolver';
import { EntryDialogPerson } from './person-test/shared/dialog/entry-dialog';

const routes: Routes = [
  // code start --  (issue - app-route render two times)
  // {
  //   path: '',
  //   component: AppComponent,
  //   children: [
  //     { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
  //     {
  //       path: 'auth',
  //       // redirectTo: '', // add for maintenance
  //       canActivate: [UnauthenticatedGuard],  // comment when maintenance is active
  //       loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) // comment when maintenance is active
  //     },
  //     // {
  //     //   path: 'test',
  //     //   // canActivate: [AuthenticatedGuard],
  //     //   loadChildren: () => import('./dog-test/dog-test.module').then(m => m.DogTestModule)
  //     // },
  //     {
  //       path: 'public',
  //       loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  //     },
  //     {
  //       path: '**',
  //       redirectTo: 'auth', // comment when maintenance is active
  //       // redirectTo: '', // add for maintenance
  //     },
  //   ]
  // }
  // code end --  (issue - app-route render two times)

  // problem resolved (app-route render two times)
  {
    path: '',
    resolve: [SEOResolver],
    // redirectTo: '', // add for maintenance
    canActivate: [UnauthenticatedGuard],  // comment when maintenance is active
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) // comment when maintenance is active
  },
  {
    path: '',
    resolve: [SEOResolver],
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  // {
  //   path: 'test',
  //   // canActivate: [AuthenticatedGuard],
  //   loadChildren: () => import('./dog-test/dog-test.module').then(m => m.DogTestModule)
  // },
  {
    path: 'profile',
    resolve: [SEOResolver],
    // redirectTo: '', // add for maintenance
    canActivate: [AuthenticatedGuard], // comment when maintenance is active
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: '',
    resolve: [SEOResolver],
    // redirectTo: '', // add for maintenance
    canActivate: [AuthenticatedGuard], // comment when maintenance is active
    loadChildren: () => import('./products-and-credits/products-and-credits.module').then(m => m.ProductsAndCreditsModule)
  },
  {
    path: 'person-test',
    resolve: [SEOResolver],
    // redirectTo: '', // add for maintenance
    canActivate: [AuthenticatedGuard], // comment when maintenance is active
    component: EntryDialogPerson
  },
  {
    path: 'chat',
    resolve: [SEOResolver],
    // redirectTo: '', // add for maintenance
    canActivate: [AuthenticatedGuard], // comment when maintenance is active
    loadChildren: () => import('./user-chat/user-chat.module').then(m => m.UserChatModule)
  },
  {
    path: 'public',
    resolve: [SEOResolver],
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'goodies-products',
    resolve: [SEOResolver],
    loadChildren: () => import('./shared/goodies-products/goodies-products.module').then(m => m.GoodiesProductsModule)
  },
  {
    path: '**',
    redirectTo: 'login', // comment when maintenance is active
    // redirectTo: '', // add for maintenance
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
