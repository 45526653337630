import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { AuthService } from 'app/auth/services/auth.service';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private toastr: ToastrNotificationService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      }
    });
    // return next.handle(request);
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const key: StateKey<string> = makeStateKey<string>(request.url);

    if (isPlatformServer(this.platformId)) {
      return next.handle(request).pipe(tap((event) => {
        this.transferState.set(key, (event as HttpResponse<any>).body);
      }));
    } else {
      const storedResponse = this.transferState.get<any>(key, null);
      if (storedResponse) {
        const response = new HttpResponse({ body: storedResponse, status: 200 });
        this.transferState.remove(key);
        return of(response);
      } else {
        return next.handle(request).pipe(
          catchError(
            (err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.authService.logout();
              }
              return throwError(err);
            }
          )
        );
      }
    }
  }
}
