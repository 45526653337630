export class LoadDogDetailAction {
    static readonly type = '[DogDetail] Load Dog Detail';
    constructor(public model: string, public loading = true) {
    }
}

export class SetImageAction {
    static readonly type = '[DogDetail] Set Image';
    constructor(public model: { file: Blob | File, image: number, id: string }) { }
}
export class SetAllNullDogDetailAction {
    static readonly type = '[DogDetail] Set Null User';
    constructor() { }
}
export class AddDescriptionAction {
    static readonly type = '[DogDetail] Add Description';
    constructor(public model: { description: string, id: string }) { }
}
export class AddChipNoAction {
    static readonly type = '[DogDetail] Add ChipNo';
    constructor(public model: { chipNumber: string, id: string }) { }
}
export class SetDogId {
    static readonly type = '[DogDetail] Set Dog Id';
    constructor(public model: string) { }
}

export class SetProfileVideoAction {
    static readonly type = '[DogDetail] Set Profile Video';
    constructor(public model: { file: Blob | File, id: string }) { }
}
export class DeleteProfileVideoAction {
    static readonly type = '[DogDetail] Delete Profile Video';
    constructor(public model: string) { }
}

export class SetDogDetailLoaderState {
  static readonly type = '[DogDetail] Loader State ';
  constructor(public model: boolean) { }
}