import { Injectable } from '@angular/core';
import { DogModel } from '@core/models/dog.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AddImageGallaryAction, DeleteImageGallaryAction, GetImageGallaryAction, UpdateImageGallaryAction } from './image-gallary.actions';
import { ImageGallaryService } from '../../services/image-gallary.service';
import { AddImageGallaryModel } from '@core/models/add-image-gallary.model';
import { ImageGallaryModel } from '@core/models/image-gallary.model';
import { EditImageGallaryModel } from '@core/models/edit-image-gallary.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
export interface DogImageGallaryStateModel {
    imageArray: ImageGallaryModel[] | undefined;
    error?: any;
    loading: boolean;
}

const defaultState: DogImageGallaryStateModel = {
    imageArray: undefined,
    error: undefined,
    loading: false,
};

@State<DogImageGallaryStateModel>({
    name: 'imageGallary',
    defaults: defaultState
})
@Injectable()
export class DogImageGallaryState {

    @Selector()
    public static getState(state: DogImageGallaryStateModel): DogImageGallaryStateModel {
        return state;
    }

    @Selector()
    public static images(state: DogImageGallaryStateModel): ImageGallaryModel[] | undefined {
        return state.imageArray;
    }

    @Selector()
    public static loading(state: DogImageGallaryStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: DogImageGallaryStateModel): any {
        return state.error;
    }

    constructor(
        private imageGallaryService: ImageGallaryService,
        private toastr: ToastrNotificationService
    ) { }

    @Action(GetImageGallaryAction)
    getGallaryImage(ctx: StateContext<DogImageGallaryStateModel>, action: GetImageGallaryAction): any {
        ctx.patchState({ loading: true });
        return this.imageGallaryService.getImages(action.model as string)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            imageArray: response.data
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddImageGallaryAction)
    addGallaryImage(ctx: StateContext<DogImageGallaryStateModel>, action: AddImageGallaryAction): any {
        ctx.patchState({ loading: true });
        return this.imageGallaryService.create(action.model as AddImageGallaryModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const imageArray = ctx.getState().imageArray as ImageGallaryModel[];
                        const oldImageArray = [...imageArray];
                        const newImageArray = oldImageArray?.concat(response.data as ImageGallaryModel[]);
                        ctx.patchState({
                            imageArray: newImageArray
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(UpdateImageGallaryAction)
    updateGallaryImage(ctx: StateContext<DogImageGallaryStateModel>, action: UpdateImageGallaryAction): any {
        ctx.patchState({ loading: true });
        return this.imageGallaryService.update(action.model as EditImageGallaryModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const { id } = action.model;
                        const imageArray = ctx.getState().imageArray as ImageGallaryModel[];
                        const updateImageArray = [...imageArray];
                        const index = updateImageArray.findIndex((image: ImageGallaryModel) => image._id === id);
                        updateImageArray[index] = response.data;
                        ctx.patchState({
                            imageArray: updateImageArray
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(DeleteImageGallaryAction)
    deleteGallaryImage(ctx: StateContext<DogImageGallaryStateModel>, action: DeleteImageGallaryAction): any {
        ctx.patchState({ loading: true });
        return this.imageGallaryService.delete(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const id = action.model;
                        const { imageArray } = ctx.getState();
                        const updatedArray = imageArray?.filter(image => image._id !== id);
                        ctx.patchState({
                            imageArray: updatedArray
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }
}
