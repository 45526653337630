import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AuthService } from 'app/auth/services/auth.service';
import { AnswerModel } from 'app/person-test/models/answer.model';
// import { LoadDogTestResults } from 'app/profile/components/profile-dogs/components/dog-test-result/store/dog-test-result.actions';
// import { LoadDogDetailAction } from 'app/profile/components/profile-dogs/shared/store/dog-detail/dog-detail.actions';
// import { DogDetailState } from 'app/profile/components/profile-dogs/shared/store/dog-detail/dog-detail.state';
import { LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { PersonTestService } from 'app/person-test/shared/services/person-test.service';
import {
  IncrementPersonTestPage, RepeatTest, SavePersonTest,
  SavePersonTestAnswers,
  SetFreeTestFlag
} from 'app/person-test/store/person-test.actions';
import { PersonTestState } from 'app/person-test/store/person-test.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import testQuestions from '../../shared/personpersonalitytestquestions';
import { Constants, peoplepersonalitytestquestions } from 'app/contants';
import { UserState } from 'app/profile/shared/store/user/user.state';
import { UserModel } from '@core/models/user.model';
import { EditUserProfileComponent } from 'app/profile/shared/components/edit-user-profile/edit-user-profile.component';
import { SpinnerService } from '@core/services/spinner.service';


@Component({
  selector: 'app-person-test-page-five',
  templateUrl: './person-test-page-five.component.html',
  styleUrls: ['./person-test-page-five.component.scss']
})
export class PersonTestPageFiveComponent implements OnInit {

  repeatTestId = '';
  isAuthenticated = false;
  question13?= peoplepersonalitytestquestions.find(el => el.questionId === 13);
  question14?= peoplepersonalitytestquestions.find(el => el.questionId === 14);
  question15?= peoplepersonalitytestquestions.find(el => el.questionId === 15);
  question16?= peoplepersonalitytestquestions.find(el => el.questionId === 16);
  question17?= peoplepersonalitytestquestions.find(el => el.questionId === 17);

  questions = [
    { ...this.question13, result: 0 },
    { ...this.question14, result: 0 },
    { ...this.question15, result: 0 },
    { ...this.question16, result: 0 },
    { ...this.question17, result: 0 },
  ];

  currentUser: any;

  @Select(PersonTestState.answers) answersState$!: Observable<AnswerModel[]>;
  @Select(PersonTestState.repeatTestId) repeatTestId$!: Observable<any>;
  @Select(UserState.user) user$ !: Observable<UserModel>;
  @Output() paymentPopupToggle = new EventEmitter();

  constructor(
    private store: Store,
    public authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private personTestService: PersonTestService,
    public _constants: Constants,
    private spinner: SpinnerService
  ) {

  }

  ngOnInit(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.answersState$.pipe(
      tap(data => {
        this.questions.forEach(element => {
          const find = data.find(el => el.questionId === element.questionId);
          if (find) {
            element.result = find?.result;
          }
        });
      })
    ).subscribe();

    this.repeatTestId$.subscribe(data => {
      if (!data) { return; }
      this.repeatTestId = data;
    });

    this.user$.subscribe((user: UserModel) => {
      if (user) {
        this.currentUser = user;

      }
    });
  }

  saveResults(form: any): void {
    const answers: AnswerModel[] = [
      { questionId: 13, type: this.question13?.type, result: form.questionId13 },
      { questionId: 14, type: this.question14?.type, result: form.questionId14 },
      { questionId: 15, type: this.question15?.type, result: form.questionId15 },
      { questionId: 16, type: this.question16?.type, result: form.questionId16 },
      { questionId: 17, type: this.question17?.type, result: form.questionId17 },
    ];
    this.store.dispatch(new SavePersonTestAnswers(answers));
  }

  saveAndNext(form: any): void {
    this.saveResults(form);

    if (this.isAuthenticated) {
      const repeatTestId = this.store.selectSnapshot(PersonTestState.repeatTestId);
      // console.log('88 repeat', repeatTestId);
      // if (this.repeatTestId) { // issue with new test for one dog affter done repeat test for other dog
      if (repeatTestId) {
        this.store.dispatch(new RepeatTest()).subscribe(
          () => {
            const dogId = this.store.selectSnapshot(this.currentUser?._id);
            this.personTestService.setRedirectToResultFlag(true); // because of close dog-test modal should not redirect 
            this.dialog.closeAll();
            this.store.dispatch(new SetFreeTestFlag(false));
            // this.store.dispatch(new LoadDogTestResults(dogId, this.repeatTestId));
            // this.store.dispatch(new LoadDogDetailAction(dogId));
          },
          () => { }
        );
      } else {
        this.store.dispatch(new SavePersonTest()).subscribe(
          () => {

            // const dogId = this.currentUser?._id;
            this.spinner.show();
            this.router.navigate(['/profile/person/test-result']);
            this.personTestService.setRedirectToResultFlag(true);
            this.dialog.closeAll();
            this.store.dispatch(new SetFreeTestFlag(false));
            // this.store.dispatch(new LoadDogDetailAction(dogId));

            // this.store.dispatch(new LoadUserAction());
            
            // this.dialog.close({authenticate: true});
            // this.store.dispatch(new LoadDogDetailAction(dogId));
          },
          () => { }
        );
      }
    } else {
      this.store.dispatch(new IncrementPersonTestPage(1));
    }

    // this.store.dispatch(new LoadUserAction());

  }

  back(form: any): void {
    this.saveResults(form);
    this.store.dispatch(new IncrementPersonTestPage(-1));
  }

  /**
   * check user's gender exist or not
   * @param form 
   */
  checkGender(form: any) {
    if (this.currentUser?.gender != null && this.currentUser?.gender != '') {
      this.saveAndNext(form);
    } else {
      this.onEditProfile(form);
    }
  }

  /**
   * open edit profile popup
   */
  onEditProfile(form: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // console.log(this.currentUser);

    // dialogConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    dialogConfig.data = {
      user: this.currentUser,
      requiredField : "PersonalityTest"
    };
    const dialogRef = this.dialog.open(EditUserProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submitted') {
        this.checkGender(form);
      }
    });
  }
}
