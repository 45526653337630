import { ProductOptionsModel } from "@core/models/product-options.model";
import { COUNTRIES_WITH_INDEX, COUNTRY_CODES } from "app/contants";
import { CountrySelection } from "app/shared/interfaces/types";
import * as moment from "moment";

export const MOMENT_DATE_FORMAT: string = 'DD/MM/YYYY';

export enum DOG_AGE_STATE {
    PUPPY = 'PUPPY',
    JUNIOR = 'JUNIOR',
    ADULT = 'ADULT',
    SENIOR = 'SENIOR',
};
    
export enum DOG_SIZE {
    XS = 'XS',
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
};
        
export type dogSizeType = `${DOG_SIZE}`
        
        
export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);
        
/**
 * 
 * @param age: date string should be in DD/MM/YYYY
 * @param size: should be one of XS, S, M, L, XL
 */
export const getDogAgeState = (age: string, size: dogSizeType) => {
    const years = moment().diff(moment(age, MOMENT_DATE_FORMAT), 'years');
    const months = moment().diff(moment(age, MOMENT_DATE_FORMAT), 'months');
    const sizeCapitalized: dogSizeType = size.toUpperCase() as dogSizeType;

    // PUPPY
    if (years < 1 && months <= 6) return DOG_AGE_STATE.PUPPY;
    // JUNIOR
    else if (months > 6 && years < 2) return DOG_AGE_STATE.JUNIOR;
    // ADULT
    else if (years >=2 && years < 10 && ([DOG_SIZE.XS, DOG_SIZE.S] as dogSizeType[]).includes(sizeCapitalized)) return DOG_AGE_STATE.ADULT;
    else if (years >=2 && years < 8 && sizeCapitalized === DOG_SIZE.M) return DOG_AGE_STATE.ADULT;
    else if (years >=2 && years < 7 && sizeCapitalized === DOG_SIZE.L) return DOG_AGE_STATE.ADULT;
    else if (years >=2 && years < 5 && sizeCapitalized === DOG_SIZE.XL) return DOG_AGE_STATE.ADULT;
    // SENIOR
    else if (years >= 10 && ([DOG_SIZE.XS, DOG_SIZE.S] as dogSizeType[]).includes(sizeCapitalized)) return DOG_AGE_STATE.SENIOR;
    else if (years >= 8 && sizeCapitalized === DOG_SIZE.M) return DOG_AGE_STATE.SENIOR;
    else if (years >= 8 && sizeCapitalized === DOG_SIZE.L) return DOG_AGE_STATE.SENIOR;
    else if (years >= 5 && sizeCapitalized === DOG_SIZE.XL) return DOG_AGE_STATE.SENIOR;
    // Does not match any
    return 'NOT_MATCHED';
}

export const countryForPayment = (country: string): CountrySelection => {
    let countryFilter: CountrySelection = "US";
  
    switch (country) {
      case "BE":
      case "DE":
      case "EE":
      case "FI":
      case "FR":
      case "GR":
      case "IE":
      case "IT":
      case "LV":
      case "LU":
      case "MT":
      case "NL":
      case "AT":
      case "PT":
      case "SK":
      case "SI":
      case "ES":
      case "CY":
      case "DK":
      case "NO":
      case "GB":
      case "SE":
      case "EU":
        countryFilter = "EU";
        break;
      case "CH":
      case "LI":
        countryFilter = "CH";
        break;
      // case "BG":
      // case "HR":
      // case "PL":
      // case "RO":
      // case "CZ":
      // case "HU":
      // case "BY":
      // case "TR":
      //   countryFilter = "EURS";
      //   break;
  
      default:
        break;
    }
    return countryFilter;
};

export const updateProductWithCountry = (products: ProductOptionsModel[], selectedCountry: number, defaultCountry: CountrySelection = COUNTRY_CODES.USA) => {
  const activeCountryCode = (Object.keys(COUNTRIES_WITH_INDEX) as CountrySelection[]).find((key: CountrySelection) => COUNTRIES_WITH_INDEX[key] === selectedCountry);
  const countryForPaymentSelected = countryForPayment(activeCountryCode ?? defaultCountry);
  
  return products.filter((product: ProductOptionsModel) => product.country === countryForPaymentSelected);
}

export function normalizeCommonJSImport<T>(
  importPromise: Promise<T>,
): Promise<T> {
  // CommonJS's `module.exports` is wrapped as `default` in ESModule.
  return importPromise.then((m: any) => (m.default || m) as T);
}

export function updateImageCacheVersion(object: {[key: string]: any}){
  const IMAGE_KEYS = ['profilePhoto', 'smallProfilePhoto', 'coverImage']
  Object.entries(object).forEach(([key, val]) => {
    if (IMAGE_KEYS.includes(key)) {
      const version = object[key + 'UpdatedAt'] ? object[key + 'UpdatedAt'] : 1;
      object[key] = `${object[key]}?ver${version}`
    }
  });
  return object;
}