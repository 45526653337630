import { Injectable } from '@angular/core';
import { ProductOptionsModel } from '@core/models/product-options.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { DogTestResultsModel } from '../models/dog-test-results.model';
import { MatchTestResult } from '../models/person-matching-test.model';
import { DogPersonalityTestService } from '../services/dog-personality-test.service';
import { DogTestResultService } from '../services/dog-test-result.service';
import { LoadDogTestResults, GetMatchingHumanTestResult, SetCurrentDogTest, SetDogSatisfaction, SetTestSatisfaction, ShowDogTestDemo, ShowDogTestFullReport, GenerateMatchingTestReport, CheckPaymentForMatchTest } from './dog-test-result.actions';

export interface DogTestResultStateModel {
    error?: any;
    loading: boolean;
    currentTest: DogTestResultsModel | any;
    allTests?: DogTestResultsModel[];
    eligibleForNewTest: boolean;
    latestTestId?: string;
    // productPt: ProductOptionsModel;
    productDr: ProductOptionsModel;
    matchTestResult: MatchTestResult | undefined;
    checkPaymentForMatchTest: boolean;
    generateMatchingTestReport: any
}

const defaultState: DogTestResultStateModel = {
    error: undefined,
    loading: false,
    currentTest: null,
    allTests: undefined,
    eligibleForNewTest: false,
    latestTestId: undefined,
    // productPt: {} as ProductOptionsModel,
    productDr: {} as ProductOptionsModel,
    matchTestResult: undefined,
    checkPaymentForMatchTest: false,
    generateMatchingTestReport: undefined
};

@State<DogTestResultStateModel>({
    name: 'dogTestResult',
    defaults: defaultState
})
@Injectable()
export class DogTestResultState {

    @Selector()
    public static getCurrentTest(state: DogTestResultStateModel): DogTestResultsModel | undefined {
        return state.currentTest;
    }

    @Selector()
    public static getAllTests(state: DogTestResultStateModel): DogTestResultsModel[] | undefined {
        return state.allTests;
    }
    @Selector()
    public static latestTestId(state: DogTestResultStateModel): string | any {
        return state.latestTestId;
    }


    @Selector()
    public static loading(state: DogTestResultStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: DogTestResultStateModel): any {
        return state.error;
    }

    @Selector()
    public static eligibleForNewTest(state: DogTestResultStateModel): boolean {
        return state.eligibleForNewTest;
    }

    // @Selector()
    // public static productPt(state: DogTestResultStateModel): ProductOptionsModel {
    //     return state.productPt;
    // }

    @Selector()
    public static productDr(state: DogTestResultStateModel): ProductOptionsModel {
        return state.productDr;
    }

    @Selector()
    public static matchTestResult(state: DogTestResultStateModel): MatchTestResult {
        return state.matchTestResult as MatchTestResult;
    }

    @Selector()
    public static checkPaymentForMatchTest(state: DogTestResultStateModel): boolean {
        return state.checkPaymentForMatchTest;
    }

    @Selector()
    public static generateMatchingTestReport(state: DogTestResultStateModel): boolean {
        return state.generateMatchingTestReport;
    }
    constructor(
        private service: DogTestResultService,
        private toastr: ToastrNotificationService,
        private dogPersonalityService: DogPersonalityTestService,
    ) { }


    @Action(LoadDogTestResults)
    loadDogTestResult(ctx: StateContext<DogTestResultStateModel>, action: LoadDogTestResults): Observable<DogTestResultsModel | undefined> {
        ctx.patchState({ loading: action.loading ? true : false });
        return this.service.getTestsForDog(action.dogId)
            .pipe(
                tap((response: any) => {
                    ctx.patchState({
                        allTests: response.data,
                        eligibleForNewTest: response.eligibleForNewTest,
                        latestTestId: response.latestTestId,
                        // productPt: response.personalityTestInfo.productPt,
                        productDr: response.personalityTestInfo.productDr
                    });
                }),
                map((response: any) => {
                    const test = action.testId === 'latest'
                        ?
                        response.data[0]
                        :
                        response.data?.find((s: DogTestResultsModel) => s._id === action.testId);

                    ctx.patchState({
                        currentTest: test
                    });
                    return test;
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetCurrentDogTest)
    setCurrentDogTest(ctx: StateContext<DogTestResultStateModel>, action: SetCurrentDogTest): void {
        ctx.patchState({
            currentTest: ctx.getState().allTests?.find(s => s._id === action.id)
        });
    }

    @Action(SetDogSatisfaction)
    SetDogSatisfaction(ctx: StateContext<DogTestResultStateModel>, action: SetDogSatisfaction): Observable<any> {
        ctx.patchState({ loading: true });
        return this.dogPersonalityService.setDogSatisfaction(action.id, { dogMark: action.markValue })
            .pipe(
                tap((response: any) => {
                    // this.toastr.showSuccess('', response.message || '');
                    this.toastr.showSuccess('', 'Vielen Dank für dein Feedback!');
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', 'Irgendwas lief falsch. Probier es bitte nochmal.');
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetTestSatisfaction)
    SetTestSatisfaction(ctx: StateContext<DogTestResultStateModel>, action: SetTestSatisfaction): Observable<any> {
        ctx.patchState({ loading: true });
        return this.dogPersonalityService.setTestSatisfaction(action.id, { testMark: action.markValue })
            .pipe(
                tap((response: any) => {
                    // this.toastr.showSuccess('', response.message || '');
                    this.toastr.showSuccess('', 'Vielen Dank für dein Feedback!');
                    if (response.data) {
                        // tslint:disable-next-line: no-unused-expression
                        // response.data.eligibleForNewTest && action.callback();
                        ctx.patchState({ eligibleForNewTest: response.data.eligibleForNewTest });
                    }
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', 'Irgendwas lief falsch. Probier es bitte nochmal.');
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ShowDogTestDemo)
    ShowDogTestDemo(ctx: StateContext<DogTestResultStateModel>, action: ShowDogTestDemo): Observable<any> {
        ctx.patchState({ loading: true });
        return this.dogPersonalityService.setDemoTest(action.id, { showFreeData: true })
            .pipe(
                tap((response: any) => {
                    ctx.patchState({
                        currentTest: { ...ctx.getState().currentTest, showFreeData: true },
                    });
                    this.toastr.showSuccess('', response.message || '');
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', 'Irgendwas lief falsch. Probier es bitte nochmal.');
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ShowDogTestFullReport)
    showDogTestShowDogTestFullReportDemo(ctx: StateContext<DogTestResultStateModel>, action: ShowDogTestFullReport): Observable<any> {
        ctx.patchState({ loading: true });
        return this.dogPersonalityService.setDemoTest(action.id, { showFullReport: true })
            .pipe(
                tap((response: any) => {
                    ctx.patchState({
                        currentTest: { ...ctx.getState().currentTest, showFullReport: true },
                    });
                    this.toastr.showSuccess('', 'Du Glückspilz! Es gibt nur kurze Zeit alle Berichte gratis.');
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', 'Irgendwas lief falsch. Probier es bitte nochmal.');
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetMatchingHumanTestResult)
    GetMatchingHumanTestResult(ctx: StateContext<DogTestResultStateModel>, action: GetMatchingHumanTestResult):
    Observable<MatchTestResult> {
        ctx.patchState({ loading: true });
        return this.service.GetMatchingHumanTestResult(action.id, action?.testId)
            .pipe(
                tap((response: any) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            matchTestResult: response.data
                        });
                    }
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    console.log('err', err);

                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(CheckPaymentForMatchTest)
    checkPaymentForMatchTest(ctx: StateContext<DogTestResultStateModel>, action: CheckPaymentForMatchTest): Observable<any> {
        ctx.patchState({ loading: true });
        return this.service.checkPaymentForMatchTest(action.id, action?.testId)
            .pipe(
                tap((response: any) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            checkPaymentForMatchTest: response.data?.paymentSuccess
                        });
                    }
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    console.log('err', err);

                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GenerateMatchingTestReport)
    generateMatchingTestReport(ctx: StateContext<DogTestResultStateModel>, action: GenerateMatchingTestReport): Observable<any> {
        ctx.patchState({ loading: true });
        return this.service.generateMatchingTestReport(action.id, action?.testId)
            .pipe(
                tap((response: any) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                            generateMatchingTestReport: response.data?.generateMatchingTestReport
                        });
                    }
                }),
                catchError((err: any) => {
                    ctx.patchState({
                        error: err,
                    });
                    return err;
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }
}
