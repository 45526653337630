import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '@core/services/spinner.service';
import { Select, Store } from '@ngxs/store';
import { AuthService } from 'app/auth/services/auth.service';
import { LoadDogDetailAction, SetDogId } from 'app/profile/components/profile-dogs/shared/store/dog-detail/dog-detail.actions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ALLBreedDogAction } from './store/person-test.actions';
import { PersonTestState } from './store/person-test.state';
// import { ALLBreedDogAction } from './store/dog-test.actions';
// import { DogTestState } from './store/dog-test.state';

@Component({
  selector: 'app-person-test',
  templateUrl: './person-test.component.html',
  styleUrls: ['./person-test.component.scss']
})
export class PersonTestComponent implements OnInit {

  @ViewChild('furhundbody', { static: true }) furhundbody!: ElementRef;
  @ViewChild('switchContent', { static: true }) switchContent!: ElementRef;

  currentStep = 1;
  isAuthenticated = false;
  numberOfSteps = 7;
  showPPTModal: Boolean = true;
  showUIEle: Boolean = false;
  @Select(PersonTestState.currentPage) currentPage$!: Observable<number>;
  @Select(PersonTestState.loading) loading$ !: Observable<any>;

  constructor(
    private store: Store,
    public authService: AuthService,
    public spinner: SpinnerService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this.calculateSteps();

  }

  ngOnInit(): void {
    this.loadingChanges();

    this.currentPage$.subscribe(step => {

      this.currentStep = step;
      if (this.furhundbody) {
        this.furhundbody.nativeElement.scroll({ top: 0, left: 0 });
      }
      if (this.switchContent) {
        this.switchContent.nativeElement.scroll({ top: 0, left: 0 });
      }
    });

    this.route.params.subscribe(params => {
      if (params.id) {
        this.store.dispatch(new LoadDogDetailAction(params.id));
        this.store.dispatch(new SetDogId(params.id));
      }
    });


    this.showUIEle = this._data.showUI;

  }

  calculateSteps(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      this.numberOfSteps = 7;
    } else {
      this.store.dispatch(new ALLBreedDogAction());
    }
  }

  calculatePercentage(): any {
    const step = 100 / this.numberOfSteps;
    const start = this.numberOfSteps === 7 ? this.currentStep : this.currentStep - 0;
    return step * start;
  }

  loadingChanges(): void {
    this.loading$.pipe(
      tap(loading => {
        if (loading) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      })
    ).subscribe();
  }

}
