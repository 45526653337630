<div class="edit-profile-picture-box gallery-popup slider-gallery-popup">
    <div class="media">
        <div class="modal-body p-0">
            <div class="container-fluid ">
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="d-flex">
                            <h4></h4>
                            <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40"
                                height="40" viewBox="0 0 40 40">
                                <g id="close" transform="translate(-1265 -197)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                        transform="translate(1265 197)" fill="#fed66b" />
                                    <path id="Path_1" data-name="Path 1"
                                        d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                                        transform="translate(1278 209.984)" fill="#326291" />
                                </g>
                            </svg>
                        </div>
                        <div class="custom-modal pt-5 pt-md-4 mt-md-3">
                            <div class="custom-modal-body">
                                <swiper [(index)]="startIndex"
                                    style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff"
                                    [spaceBetween]="10" [navigation]="true" [thumbs]="{ swiper: thumbsSwiper }"
                                    class="mySwiper2">
                                    <ng-container *ngIf="!data.onlyPreview">
                                        <ng-template swiperSlide
                                            *ngFor="let item of data.filesArray; let index = index">
                                            <img *ngIf="item.format === 'image'" [src]="item.fileUrl"
                                                alt="FairDogs_Hunde" />
                                            <video *ngIf="item.format === 'video'" controls width="100%" height="100%"
                                                [src]="item.fileUrl">
                                            </video>
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngIf="data.onlyPreview">
                                        <ng-template swiperSlide
                                            *ngFor="let item of data.filesArray; let index = index">
                                            <img *ngIf="item.isImage" [src]="item.url" alt="FairDogs_Hunde" />
                                            <video *ngIf="!item.isImage" controls width="100%" height="100%"
                                                [src]="item.url">
                                            </video>
                                        </ng-template>
                                    </ng-container>

                                </swiper>
                                <swiper (swiper)="thumbsSwiper = $event" [spaceBetween]="10" [slidesPerView]="7"
                                    [freeMode]="true" [watchSlidesProgress]="true" [navigation]="true" class="mySwiper">
                                    <ng-container *ngIf="!data.onlyPreview">
                                        <ng-template swiperSlide *ngFor="let item of data.filesArray">
                                            <img *ngIf="item.format === 'image'" [src]="item.fileUrl"
                                                alt="FairDogs_Hunde" />
                                            <div *ngIf="item.format === 'video'" class="dg-diary-video">
                                                <video width="100%" height="100%" [src]="item.fileUrl">
                                                </video>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngIf="data.onlyPreview">
                                        <ng-template swiperSlide *ngFor="let item of data.filesArray">
                                            <img *ngIf="item.isImage" [src]="item.url" alt="FairDogs_Hunde" />
                                            <div *ngIf="!item.isImage" class="dg-diary-video">
                                                <video width="100%" height="100%" [src]="item.url">
                                                </video>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </swiper>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>