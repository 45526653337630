import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressService } from '../../services/address.service';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit {

  title = 'firstApp';
  lat: any;
  lng: any;
  private geoCoder: any;
  public formGroup: FormGroup = {} as FormGroup;
  public isSuggestedSelect = false;
  public notFoundAddress = false;
  public styles: Array<any> = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ];
  placeOptions: any[] = [];
  @ViewChild('addressInput')
  addressInput!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddressDialogComponent>,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    // tslint:disable-next-line: new-parens
    this.mapsAPILoader.load().then(() => {
    this.geoCoder = new google.maps.Geocoder;
    });
    const { latitude, longitude, address, placeId, city, state, countryName, country } = this.data.location;
    this.formGroup = this.formBuilder.group({
      address: [''],
      latitude: [''],
      longitude: [''],
      placeId: [''],
      city: [''],
      country: [country],
      state: [''],
      countryName: ['']
    });

    if (latitude && longitude) {
      this.lat = latitude;
      this.lng = longitude;
      this.formGroup.patchValue({
        address,
        latitude,
        longitude,
        placeId,
        city,
        state,
        countryName,
        country
      });
    }

    // @ts-ignore: Object is possibly 'null'.
    this.formGroup
      .get('address')
      .valueChanges
      .subscribe((value) => {
        if (value){
          this.getPlaceAutocompleteCustom();
          // this.getPlaceAutocomplete();
          if (this.isSuggestedSelect) {
            this.isSuggestedSelect = false;
            this.notFoundAddress = false;
          } else {
            this.notFoundAddress = true;
            this.setLoactionNull();
          }
        } else{
          this.placeOptions = [];
          this.setLoactionNull();
        }
      });
  }

  setLoactionNull(): void{
    this.formGroup.patchValue({
      placeId: null,
      latitude: null,
      longitude: null,
      city: null,
      state: null,
      countryName: null
    });
  }

  getCurrentAddress(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.getAddress(this.lat, this.lng);
        }
      });
    }
  }
  markerDragEnd(event: any): void {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.getAddress(lat, lng);
  }

  getAddress(latitude: any, longitude: any): void {
    // console.log('70', latitude, longitude);
    this.isSuggestedSelect = true;
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          const locationObj: { [key: string]: any } = this.addressService.fillAddressRelatedFields(results[0]);
          // if (locationObj.countryName && locationObj.countryName === this.data.location.country.toUpperCase()){
          Object.keys(locationObj).forEach((keyname: string) => {
              this.formGroup.patchValue({
                [keyname]: locationObj[keyname]
              });
            });
          this.formGroup.patchValue({
              address: results[0].formatted_address,
            });
          // }
        } else {
          // console.log('No results found');
        }
      } else {
        // console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  private getPlaceAutocomplete(): void{
    const componentRestrictions = {} as any;
    if (this.data?.location?.country){
      componentRestrictions.country = [this.data.location.country];
    }else{
      componentRestrictions.country = [];
    }
    this.mapsAPILoader.load().then(() => {
      const autocomplete  =  new  google.maps.places.Autocomplete(this.addressInput.nativeElement,
        {
          componentRestrictions
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          this.ngZone.run(() => {
            const address  =  autocomplete.getPlace() as Address;
            this.isSuggestedSelect = true;
            const inputValue = this.addressInput.nativeElement.value;
            const locationObj: {[key: string]: any} = this.addressService.fillAddressRelatedFields(address);
            Object.keys(locationObj).forEach((keyname: string) => {
              this.formGroup.patchValue({
                [keyname]: locationObj[keyname]
              });
            });
            this.formGroup.patchValue({
              address: inputValue,
            });
            this.lat = this.formGroup.value.latitude;
            this.lng = this.formGroup.value.longitude;
          });
        });
    });
  }

  private getPlaceAutocompleteCustom(): void{
    this.mapsAPILoader.load().then(() => {
      const service = new google.maps.places.AutocompleteService();
      const request = {
        input: this.formGroup.value.address,
        componentRestrictions: {country: this.formGroup.value.country},
    };
      service.getPlacePredictions(request, (places) => {
        this.placeOptions = places;
      });
    });
   }

   placeSelect(id: string): void{
        const map = new google.maps.Map(document.createElement('div'));
        const service = new google.maps.places.PlacesService(map);
        const placeDetails = service.getDetails(
        { placeId: id },
        (result, status) => {
          this.ngZone.run(() => {
              const address  =  result as Address;
              this.isSuggestedSelect = true;
              const inputValue = this.addressInput.nativeElement.value;
              const locationObj: {[key: string]: any} = this.addressService.fillAddressRelatedFields(address);
              Object.keys(locationObj).forEach((keyname: string) => {
                this.formGroup.patchValue({
                  [keyname]: locationObj[keyname]
                });
              });
              this.formGroup.patchValue({
                address: inputValue,
              });
              this.lat = this.formGroup.value.latitude;
              this.lng = this.formGroup.value.longitude;
          });
        }
      );
  }

  // onAddressChange(address: Address): void {
  //   this.isSuggestedSelect = true;
  //   const inputValue = this.addressInput.nativeElement.value;
  //   this.formGroup.patchValue({
  //     address: inputValue,
  //   });
  //   const locationObj: { [key: string]: any } = this.addressService.fillAddressRelatedFields(address);
  //   Object.keys(locationObj).forEach((keyname: string) => {
  //     this.formGroup.patchValue({
  //       [keyname]: locationObj[keyname]
  //     });
  //   });
  //   this.lat = this.formGroup.value.latitude;
  //   this.lng = this.formGroup.value.longitude;
  // }

  SubmitAddress(): void {
    if (this.formGroup.value.address && this.notFoundAddress) {
      return;
    }
    this.dialogRef.close({ location: this.formGroup.value });
  }

  close(): void {
    this.dialogRef.close();
  }

  onMapReady(map?: google.maps.Map): void {
    if (map) {
      map.setOptions({
        fullscreenControl: false,
        streetViewControl: false
      });
    }
  }
}
