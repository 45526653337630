import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DogTestResultsModel } from '../models/dog-test-results.model';
import { MatchTestResult } from '../models/person-matching-test.model';

@Injectable({ providedIn: 'root' })
export class DogTestResultService extends BaseApiService {
    constructor(
        protected http: HttpClient
    ) {
        super(http);
        this.apiEndpoint = 'dog'; // PATH to controller or microservice
    }

    getTestsForDog(dogId?: string): Observable<DogTestResultsModel[]> {
        return this.makeRequest('GET', `${dogId}/personalityTestResults`, {})
            .pipe(map((s: any) => s.data));
    }

    // setDogSatisfaction(id: string, model: any): Observable<any> {
    //     return this.makeRequest('PATCH', id, model);
    // }

    GetMatchingHumanTestResult(dogId?: string, testId?: string): Observable<MatchTestResult> {
        return this.makeRequest('POST', `${dogId}/getDogHumanTest`, { testId });
        // return of({
        //   success: true
        // })
    }


    checkPaymentForMatchTest(dogId?: string, testId?: string): Observable<any> {
        return this.makeRequest('POST', `${dogId}/paymentDogHumanTest`, { testId });
        // return of({
        //   success: true
        // })
    }

    generateMatchingTestReport(dogId?: string, testId?: string): Observable<any> {
        return this.makeRequest('POST', `${dogId}/matchingDogHumanTest`, { testId });
        // return of({
        //   success: true
        // })
    }
}
