import { Injectable } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class AddressService {
  constructor(
  ) {
  }

  public fillAddressRelatedFields(
    address: Address,
  ): object {
    const locationObj: {[key: string]: any} = {
        countryName: '',
        city : '',
        state: '',
        latitude: 0,
        longitude: 0,
        placeId: ''
    };
    locationObj.latitude = address.geometry.location.lat();
    locationObj.longitude = address.geometry.location.lng();
    locationObj.placeId = address.place_id;
    address.address_components.forEach((addressComponent: any) => {
        switch (addressComponent.types[0]) {
          case 'locality':
            locationObj.city = addressComponent.long_name;
            break;
          case 'country':
            locationObj.countryName = addressComponent.short_name;
            break;
          case 'administrative_area_level_1':
            locationObj.state = addressComponent.short_name;
            break;
          case 'administrative_area_level_2':
          case 'administrative_area_level_3':
          case 'administrative_area_level_4':
          case 'administrative_area_level_5':
            break;
          }
      });

    return locationObj;
    }
}
