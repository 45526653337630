import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { AnswerModel } from 'app/person-test/models/answer.model';

import { IncrementPersonTestPage, SavePersonTestAnswers } from 'app/person-test/store/person-test.actions';
import { PersonTestState } from 'app/person-test/store/person-test.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import questions from '../../shared/personpersonalitytestquestions';

@Component({
  selector: 'app-person-test-page-two',
  templateUrl: './person-test-page-two.component.html',
  styleUrls: ['./person-test-page-two.component.scss']
})
export class PersonTestPageTwoComponent implements OnInit {

  question1?= questions.find((el) => el.questionId === 1);
  question2?= questions.find((el) => el.questionId === 2);

  public formGroup: FormGroup = {} as FormGroup;
  public formSubmitted = false;
  public submitButtonDisabled = false;
  public selectList1 = [
    {
      answer: 5,
      title: 'Ich gehe täglich mit Hund rennen, reiten oder Velo fahren.',
    },
    { answer: 4, title: 'Ich gehe mit Hund spazieren und mehrmals pro Woche joggen.' },
    { answer: 3, title: 'Ich gehe mit Hund spazieren und lass ihn auch mal frei Gas geben.' },
    {
      answer: 2,
      title:
        'Hunde laufen bei mir immer an der Leine.',
    },
    {
      answer: 1,
      title:
        'Ich kann nicht täglich mit dem Hund laufen.',
    },
  ];
  public selectList12 = [
    { answer: 5, title: '3-4 Stunden' },
    { answer: 4, title: '2-3 Stunden' },
    { answer: 3, title: '1-2 Stunden' },
    { answer: 2, title: '1 Stunde' },
    { answer: 1, title: 'eine kleine Runde' },
  ];

  @Select(PersonTestState.answers) answersState$!: Observable<AnswerModel[]>;

  constructor(private store: Store, private formBuilder: FormBuilder) { }

  get fg(): any {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.initForm();

    this.answersState$
      .pipe(
        tap((data) => {
          const answer1 = data.find((el) => el.questionId === 1)
            ? data.find((el) => el.questionId === 1)?.result
            : null;
          const answer2 = data.find((el) => el.questionId === 2)
            ? data.find((el) => el.questionId === 2)?.result
            : null;
          this.formGroup.patchValue({
            select1: answer1,
            select2: answer2,
          });
        })
      )
      .subscribe();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      select1: [, Validators.required],
      select2: [, Validators.required],
    });
  }

  saveResults(): void {
    const answers: AnswerModel[] = [
      {
        questionId: 1,
        type: this.question1?.type,
        // tslint:disable-next-line: radix
        result: parseInt(this.formGroup.value.select1),
      },
      {
        questionId: 2,
        type: this.question2?.type,
        // tslint:disable-next-line: radix
        result: parseInt(this.formGroup.value.select2),
      },
    ];
    this.store.dispatch(new SavePersonTestAnswers(answers));
  }

  saveAndNext(): void {
    if (this.formGroup.invalid) { return; }
    this.saveResults();
    this.store.dispatch(new IncrementPersonTestPage(1));
  }

  back(): void {
    this.saveResults();
    this.store.dispatch(new IncrementPersonTestPage(-1));
  }

}
