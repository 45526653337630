<div class="row subscription-plans">
    <div class="col-12">
        <ng-container *ngIf="subscriptionTypeData?.subType != 'dogShareMatchingResult'">
            <div class="head-text" *ngIf="subscriptionTypeData?.subType !== 'personPersonalityTest'">
                {{'SUBSCRIPTION.HEADER_TEXT' | translate}}
            </div>
            <div class="head-text" *ngIf="subscriptionTypeData?.subType === 'personPersonalityTest'">
                Persönlichkeitstest
            </div>
        </ng-container>
        <ng-container *ngIf="subscriptionTypeData?.subType === 'dogShareMatchingResult'">
            <div class="matching-head-text fs-24 d-sm-block d-none">
                Mitgliedschaft - Coming Soon
            </div>
            <div class="matching-head-text fs-20 d-sm-none d-block">
                Mitgliedschaft - Coming Soon
            </div>
        </ng-container>
        <div class="sub-header">
            <ng-container
                *ngIf="(subscriptionTypeData?.type === 'dogCare' || subscriptionTypeData?.type === 'personCare') && subscriptionTypeData?.subType === ''">
                {{'SUBSCRIPTION.SUB_HEADER_TEXT_SHARING' | translate}}
            </ng-container>
            <ng-container
                *ngIf="subscriptionTypeData?.type === 'dogCare' && subscriptionTypeData?.subType === 'Kontaktaufnahme'">
                {{'SUBSCRIPTION.SUB_HEADER_TEXT_CHAT' | translate}}
            </ng-container>
            <ng-container
                *ngIf="subscriptionTypeData?.type === 'dogCare' && subscriptionTypeData?.subType === 'dogFilteronemonth'">
                {{'SUBSCRIPTION.SUB_HEADER_TEXT_FILTER' | translate}}
            </ng-container>
            <ng-container
                *ngIf="subscriptionTypeData?.type === 'personCare' && subscriptionTypeData?.subType === 'personFilteronemonth '">
                {{'SUBSCRIPTION.SUB_HEADER_TEXT_FILTER' | translate}}
            </ng-container>
            <ng-container
                *ngIf="subscriptionTypeData?.type === 'personCare' && subscriptionTypeData?.subType === 'personPersonalityTest'">
                für Hundefreunde & Hundehalter
            </ng-container>
        </div>
    </div>
    <div class="col-12 mt-4">
        <ng-container *ngFor="let plan of subscriptionPlanList; let indx = index">
            <div class="row mb-3"
                *ngIf="subscriptionTypeData?.existingIds == undefined || subscriptionTypeData?.existingIds?.length == 0 || subscriptionTypeData?.existingIds.includes(plan?._id) === false">
                <div class="col-10">
                    <div class="checkbox--dog d-flex align-items-center">
                        <mat-checkbox class="form-check pl-0"
                            [checked]="(subscriptionTypeData?.type === 'dogCare' || subscriptionTypeData?.type === 'personCare') && (subscriptionTypeData?.subType === '' || subscriptionTypeData?.subType === 'personPersonalityTest') && planIds?.indexOf(plan?._id) != -1"
                            (change)="manageSelectedPlanTotal($event, plan?.credits, plan)">
                            <div class="font-weight-bold plan-name">{{plan?.name}}</div>
                        </mat-checkbox>
                        <i class="fa fa-info-circle fa-lg cursor-pointer ml-1 txt-color-grey" style="z-index: 999;"
                            (click)="plan.isHidden = plan?.isHidden == true ? false:true" aria-hidden="true"></i>
                    </div>
                    <div class="desc-section text-muted">
                        <div class="sort-desc">{{plan?.sortDescription}}</div>
                        <div class="detail-desc detail-desc-{{indx}}" [ngClass]="{'d-none': plan?.isHidden}">
                            <span [innerHtml]="plan?.longDescription"></span>
                        </div>
                    </div>
                </div>
                <div class="col-2 text-right">{{plan?.credits}}</div>
            </div>
        </ng-container>

    </div>
    <div class="col-12 total-credit">
        <ng-container *ngIf="subscriptionDiscount > 0 && totalSelectedCredit > 0">
            <div class="row mb-3">
                <div class="col-10">
                    <span class="subtotal-text"> {{'SUBSCRIPTION.SUB_TOTAL_GOODIES' | translate}}
                    </span>
                </div>
                <div class="col-2 text-right">
                    <span class="subtotal-amount">{{totalSelectedCredit}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-9">
                    <span class="discount-text"> {{'SUBSCRIPTION.DISCOUNT_TEXT' | translate}}
                    </span>
                </div>
                <div class="col-3 text-right">
                    <span class="discount-amount">-&nbsp;{{discountAmount}}</span>
                </div>
            </div>
            <hr>
        </ng-container>

        <ng-container *ngIf="subscriptionTypeData?.subType != 'dogShareMatchingResult'">
            <div class="row custom_line" *ngIf="showProductSection"
                [ngClass]="{'text-danger': totalSelectedCredit > 0 && (totalSelectedCredit - discountAmount) > currentUser?.credits}">
                <div class="col-9">
                    <span class="credit-total-text"> {{'SUBSCRIPTION.TOTAL_GOODIES' | translate}}
                    </span>
                </div>
                <div class="col-3 text-right product-total-amt-clr"
                    [ngClass]="{'text-danger': totalSelectedCredit > 0 && (totalSelectedCredit - discountAmount) > currentUser?.credits}">
                    <span>{{totalSelectedCredit - discountAmount}}</span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="subscriptionTypeData?.subType === 'dogShareMatchingResult'">
            <div class="row custom_line" *ngIf="showProductSection"
                [ngClass]="{'text-danger': totalSelectedCredit > 0 && (totalSelectedCredit - discountAmount) > currentUser?.credits}">
                <div class="col-9">
                    <span class="credit-total-text"> {{'SUBSCRIPTION.TOTAL_GOODIES' | translate}}
                    </span>
                </div>
                <div class="col-3 text-right product-total-amt-clr"
                    [ngClass]="{'text-danger': totalSelectedCredit > 0 && (totalSelectedCredit - discountAmount) > currentUser?.credits}">
                    <span>{{totalSelectedCredit}}</span>
                </div>
            </div>
        </ng-container>
        <!-- <hr /> -->
        <div class="col-md-12 text-danger text-center"
            *ngIf="totalSelectedCredit > 0 && (totalSelectedCredit - discountAmount) > currentUser?.credits">
            <div style="font-size: 14px; font-weight: normal">
                {{'SUBSCRIPTION.LESS_CREDITS_TEXT1' | translate}}
                {{'SUBSCRIPTION.LESS_CREDITS_TEXT2' | translate}}
            </div>
            <!-- <div style="font-size: 14px; font-weight: normal;" class="mb-2">
            
            </div> -->
        </div>
    </div>

    <ng-container *ngIf="subscriptionTypeData?.subType != 'dogShareMatchingResult'">
        <div class="col-12 d-flex justify-content-center" *ngIf="showProductSection">
            <div class="checkbox--dog" *ngIf="(totalSelectedCredit - discountAmount) <= currentUser?.credits">
                <mat-checkbox class="form-check pl-0" [(ngModel)]="checkboxChecked">
                    <label class="form-check-label"
                        [innerHTML]="'SUBSCRIPTION.TERMS_CONDITION_TEXT' | translate: { domainUrl: frontEndDomain}">
                    </label>
                </mat-checkbox>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="subscriptionTypeData?.subType === 'dogShareMatchingResult'">
        <div class="col-12 d-flex justify-content-center" *ngIf="showProductSection">
            <div class="checkbox--dog">
                <mat-checkbox class="form-check pl-0" [(ngModel)]="checkboxChecked">
                    <label class="form-check-label"
                        [innerHTML]="'SUBSCRIPTION.TERMS_CONDITION_TEXT' | translate: { domainUrl: frontEndDomain}">
                    </label>
                </mat-checkbox>
            </div>
        </div>
    </ng-container>

    <div class="col-12 d-flex justify-content-center mt-4" *ngIf="showProductSection">
        <ng-container *ngIf="subscriptionTypeData?.subType != 'dogShareMatchingResult'">
            <button [disabled]="!checkboxChecked || planIds?.length == 0 || (totalSelectedCredit - discountAmount == 0)"
                class="max-width-310 btn btn-fill-yellow font-weight-bold fs-16 btn-no-text-hover"
                *ngIf="(totalSelectedCredit - discountAmount) <= currentUser?.credits"
                (click)="purchasePlanUsingGoodies()">
                {{'SUBSCRIPTION.BUY_GOODIES_BUTTON' | translate}}
            </button>
            <button class="max-width-310 btn btn-fill-blue  fs-16 btn-no-text-hover" style="border-radius: 99px;"
                *ngIf="(totalSelectedCredit - discountAmount) > currentUser?.credits"
                (click)="onActionChange.emit('show-goodies-purchase-screen')">{{'SUBSCRIPTION.PURCHASE_PLANS' |
                translate}}</button>
        </ng-container>

        <ng-container *ngIf="subscriptionTypeData?.subType === 'dogShareMatchingResult'">
            <button class="max-width-310 btn btn-fill-yellow fs-16 btn-no-text-hover" style="border-radius: 99px;" [disabled]="!(planIds.length > 0 && checkboxChecked)"
                (click)="purchasePlanUsingGoodies()">{{'SUBSCRIPTION.BUY_MATCH_SHARING_PLAN' | translate}}</button>
        </ng-container>
    </div>
</div>