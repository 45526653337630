<div class="popup--dog">
    <div class="media">
        <div class="modal-body px-0 p-4">
            <div class="d-flex justify-content-between modal-line">
                <div class="c-profile-ads__body color-blue header-pro-width">
                    <div class="row">
                        <div class=" header-left ml-4">
                            <div class="profile-img">
                                <img class="profile-img"
                                    [src]="data?.photo ? data?.photo : 'assets/img/img_person_placeholder_web.svg'"
                                    alt="FairDogs_Hundehalter">
                            </div>
                        </div>
                        <div class="pl-4 mt-2">
                            <div class="c-profile-ads__view color-blue">
                                Anzeigen von
                            </div>
                            <div style="font-weight:700">
                                <ng-container *ngIf="data?.userName; else userNameNull">
                                    {{ data?.userName ? data?.userName : '' }}
                                </ng-container>
                                <ng-template #userNameNull>
                                    {{data?.firstName + ' ' + data?.lastName?.charAt(0)+ '.'}}
                                </ng-template>
                            </div>
                        </div>
                    </div>


                </div>
                <svg class="ic_close cursor-pointer" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40"
                    height="40" viewBox="0 0 40 40">
                    <g id="close" transform="translate(-1265 -197)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                            transform="translate(1265 197)" fill="#fed66b" />
                        <path id="Path_1" data-name="Path 1"
                            d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                            transform="translate(1278 209.984)" fill="#326291" />
                    </g>
                </svg>
            </div>
            <div class="">
                <div class="c-profile-ads-main">
                    <div class="c-profile-ads" *ngFor="let data of userSharedDogs; let indexIco = index"
                        [ngClass]="{'emergency-care-yellow-border': data?.emergencyCare, 'active-round':selectedDogOrPersonIndex === indexIco}"
                        (click)="manageSelectedDogOrPersonView(data,indexIco)">
                        <img *ngIf="data.type==='dog'"
                            class="c-profile-ads__image d-flex border-radius-50 overflow-hidden"
                            [ngClass]="{'emergency-care-yellow-border': data?.emergencyCare, 'opacity-point-5': !data?.active}"
                            [src]="data?.image ? data?.image : data?.dog?.profilePhoto" alt="Hundesitter">
                        <img *ngIf="data.type !=='dog'"
                            class="c-profile-ads__image d-flex border-radius-50 overflow-hidden"
                            [ngClass]="{'emergency-care-yellow-border': data?.emergencyCare, 'opacity-point-5': !data?.active}"
                            [src]="data?.image ? data?.image : data?.user?.profilePhoto" alt="Hundesitter">
                    </div>
                </div>


            </div>

            <!-- <div class="col-md-6">
        <div  class="d-flex pb-4 km-main card-action-bottom">
            <div  class="text-truncate ml-1 mw-100">
        <p>
        </p>
             </div>
        </div>
       </div> -->
            <!-- dog and person adds : hide in mobile screen start -->
            <div class="c-profile-ads-text" *ngIf="inActiveAdFound">
                <p>Diese Anzeigen sind nicht aktiv.</p>
            </div>

            <div class="row" *ngIf="!mobileView">
                <ng-container *ngFor="let item of userSharedDogs; let index = index">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <ng-container *ngIf="item.type==='dog'; else personBlock">
                            <app-dog-person-sharing-dog-card [data]="item" [participant]="false" [openedFrom]="'adds'"
                                [index]="index" [startIndex]="0" [insideData]="true"></app-dog-person-sharing-dog-card>

                        </ng-container>
                        <ng-template #personBlock>
                            <app-dog-person-sharing-person-card [data]="item" [participant]="false"
                                [openedFrom]="'adds'" [index]="index" [startIndex]="0">
                            </app-dog-person-sharing-person-card>
                        </ng-template>
                        <div *ngIf="!item?.active" class="overlay-set"></div>
                    </div>
                </ng-container>

            </div>
            <!-- dog and person adds : hide in mobile screen end -->

            <!-- on click show dog or person adds start -->
            <div class="row c-profile-ads-main-clicked" *ngIf="selectedDogOrPerson != undefined">
                <ng-container>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <ng-container *ngIf="selectedDogOrPerson.type==='dog'; else personBlock">
                            <app-dog-person-sharing-dog-card [data]="selectedDogOrPerson" [participant]="false"
                                [openedFrom]="'adds'" [index]="selectedDogOrPersonIndex" [startIndex]="0"
                                [insideData]="true">
                            </app-dog-person-sharing-dog-card>
                        </ng-container>
                        <ng-template #personBlock>
                            <app-dog-person-sharing-person-card [data]="selectedDogOrPerson" [participant]="false"
                                [openedFrom]="'adds'" [index]="selectedDogOrPersonIndex" [startIndex]="0">
                            </app-dog-person-sharing-person-card>
                        </ng-template>
                        <div *ngIf="!selectedDogOrPerson?.active" class="overlay-set"></div>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="showEmptyMsg">
                <div class="c-runnig-dog-error">
                    <div class="c-runnig-dog-error__image">
                        <img src="assets/img/dog-running_gray.svg" alt="Hundesitter">
                    </div>
                </div>
                <div class="c-runnig-dog-error__text text text-center">
                    {{'PAGE.CHAT.NO_USER_ADS_AVAILABLE' | translate}}
                </div>
            </ng-container>
        </div>
    </div>