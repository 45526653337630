import { Injectable } from '@angular/core';
import {  NgxSpinnerService } from 'ngx-spinner';

@Injectable()
// remove root because set to module wise 
export class SpinnerService {

  constructor(private spinner: NgxSpinnerService) {
   }

  public show(): void{
    this.spinner.show(undefined,
      {
        // type: 'ball-triangle-path',
        size: 'large',
        bdColor: '#ffff',
        // color: '#fff',
        fullScreen: true,
        template :"<img src='assets/img/loader-new.gif' />"

      });
  }

  public hide(): void{
    this.spinner.hide();
  }
}
