import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddToCartModel } from '@core/models/add-to-cart.model';
import { MyCartModel } from '@core/models/my-cart.model';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CreditService extends BaseApiService {

    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'products';
    }

    getAllProducts(): Observable<ResponseBeanModel> {
        const headers = new HttpHeaders({
            'cache-control': 'no-cache',
            'Pragma': 'no-cache',
        });
        return this.makeRequest('GET', '', {}, undefined, headers);
    }

    getstaticProductDesc(): Observable<ResponseBeanModel> {
        const headers = new HttpHeaders({
            'cache-control': 'no-cache',
            'Pragma': 'no-cache',
        });
        return this.makeRequest('GET', 'staticProductDesc', {}, undefined, headers);
    }

    purchaseProduct(model: any): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', 'purchase', model);
    }

    addToCart(model: AddToCartModel): Observable<ResponseBeanModel> {
        return this.makeRequest('POST', 'cart', model);
    }

    getCartDetail(): Observable<ResponseBeanModel> {
        // const data = [
        //     {
        //         _id: '1',
        //         data: {
        //             dogName: 'dog1',
        //         },
        //         product: {
        //             _id: 'a',
        //             subType: 'personalityTest' ,
        //             type: 'pt',
        //             credits: 10,
        //             currencySymbol: '$',
        //             unitCostPrice: 1,
        //         },
        //         isChecked: false
        //     },
        //     {
        //         _id: '1',
        //         data: {
        //             dogName: 'dog2',
        //         },
        //         product: {
        //             _id: 'b',
        //             subType: 'detailedReport' ,
        //             type: 'pt',
        //             credits: 20,
        //             currencySymbol: '$',
        //             unitCostPrice: 2
        //         },
        //         isChecked: true
        //     },
        //     {
        //         _id: '1',
        //         data: {
        //             dogName: 'dog3',
        //         },
        //         product: {
        //             _id: 'c',
        //             subType: 'detailedReport' ,
        //             type: 'pt',
        //             credits: 30,
        //             currencySymbol: '$',
        //             unitCostPrice: 3
        //         },
        //         isChecked: false
        //     },
        //     {
        //         _id: '4',
        //         data: {
        //             dogName: 'dog4',
        //         },
        //         product: {
        //             _id: 'd',
        //             subType: 'personalityTest' ,
        //             type: 'pt',
        //             credits: 40,
        //             currencySymbol: '$',
        //             unitCostPrice: 4
        //         },
        //         isChecked: true
        //     }
        // ];
        // return of({data, success: true});
        return this.makeRequest('GET', 'cart', {});
    }

    deleteFromCart(model: string): Observable<ResponseBeanModel> {
        return this.makeRequest('DELETE', 'cart/' + model, {});
    }

    selectedCartChanged(model: { isChecked: boolean, id: string }): Observable<ResponseBeanModel> {
        return this.makeRequest('PATCH', 'cart/' + model.id, { isChecked: model.isChecked });
    }

    purchaseCartItemWithCredit(model: { productsArray: string[] }): Observable<ResponseBeanModel> {
        return this.makeRequest('PATCH', 'cart', model);
    }

    paymentAlertButton(obj: any): Observable<ResponseBeanModel> {
        return this.makeRequest('PATCH', 'popup', obj);
    }
}
