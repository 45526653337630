<!-- coming soon -->
<div class="edit-profile-picture-box  d-none">
    <div class="media">
        <div class="modal-body px-0">
            <div class="p-4">
                <div class="d-flex">
                    <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                        viewBox="0 0 40 40">
                        <g id="close" transform="translate(-1265 -197)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                transform="translate(1265 197)" fill="#fed66b" />
                            <path id="Path_1" data-name="Path 1"
                                d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                                transform="translate(1278 209.984)" fill="#326291" />
                        </g>
                    </svg>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <h4 class="mb-5">Coming Soon</h4>
                    </div>
                    <div class="col-md-7">
                        <p class="fs-12 text-left color-b--60">Die App ist noch in Bearbeitung. Profitiere bald von
                            tollen neuen Funktionen. Mit der App hast du jederzeit schnellen Zugriff auf alles, auch
                            ohne Internet und Netz.</p>
                        <div class="form-check pl-0 themecheck-box mb-4">
                            <input class="form-check-input styled-checkbox" type="checkbox" value=""
                                id="flexCheckChecked" checked>
                            <label class="fs-12 text-left d-flex color-b--60   form-check-label" for="flexCheckChecked">
                                Ja, ich möchte eine E-Mail erhalten, wenn die App installiert werden kann.
                            </label>
                        </div>
                        <form>
                            <div class="form-group form-theme-dog">
                                <input type="email" class="form-control" placeholder=" E-Mail-Adresse">
                            </div>
                        </form>
                    </div>
                    <div class="col-md-5">
                        <img src="assets/img/dogy.png" alt="FairDogs_Hunde">
                    </div>
                </div>
                <div class="row  mt-4">

                    <div class="col-md-12 mt-3 mt-md-0">
                        <button class="btn-theme  mb-0">Speichern</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- coming soon -->


<div class="edit-profile-picture-box edit-profile-details-box profil-bearbeiten">
    <div class="media">
        <div class="modal-body">
            <div class="d-flex pt-4">
                <h4>{{ 'PAGE.SHARED.EDIT_USER_PROFILE.TITLE' | translate }}</h4>
                <svg class="ic_close" (click)="onClose()" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                    viewBox="0 0 40 40">
                    <g id="close" transform="translate(-1265 -197)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                            transform="translate(1265 197)" fill="#fed66b" />
                        <path id="Path_1" data-name="Path 1"
                            d="M8.542,7.016l5.235-5.235a.767.767,0,0,0,0-1.083L13.319.24a.768.768,0,0,0-1.083,0L7,5.474,1.766.24A.767.767,0,0,0,.683.24L.224.7a.766.766,0,0,0,0,1.083L5.459,7.016.224,12.251a.768.768,0,0,0,0,1.083l.459.459a.767.767,0,0,0,1.083,0L7,8.558l5.235,5.235a.76.76,0,0,0,.541.224h0a.76.76,0,0,0,.541-.224l.459-.459a.768.768,0,0,0,0-1.083Z"
                            transform="translate(1278 209.984)" fill="#326291" />
                    </g>
                </svg>
            </div>
            <div class="row user-edit-profile-d" [formGroup]="formGroup">
                <div class="col-12 col-sm-9 order-2 order-sm-1 text-left">
                    <p class="heading-one ">{{ 'PAGE.SHARED.EDIT_USER_PROFILE.USERNAME' | translate }}</p>
                    <p class="descr-one">{{ 'PAGE.SHARED.EDIT_USER_PROFILE.USERNAME_DESC' | translate }}</p>
                    <div class="form-row">
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <input type="text" class="form-control" formControlName="userName"
                                [ngClass]="{'valid-error': fg.userName.touched && fg.userName.invalid}">
                            <ng-container *ngIf="fg.userName.touched && fg.userName.invalid">
                                <p class="error-label" *ngIf="fg.userName.errors?.errMsg">{{fg.userName.errors?.errMsg}}
                                </p>
                                <p class="error-label" *ngIf="fg.userName.errors?.invalidUserName">{{
                                    'PAGE.SHARED.EDIT_USER_PROFILE.USER_NAME_NOT_ALLOWED' | translate}}</p>
                                <p class="error-label" *ngIf="fg.userName.errors?.maxlength">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.MAX_CHAR_ERROR' | translate}}</p>
                                <p class="error-label" *ngIf="fg.userName.errors?.duplicatedUserName">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.USERNAME_EXIST' | translate}}</p>
                                <p class="error-label" *ngIf="fg.userName.errors?.isNumberSymbolError">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.NUMBER_SYMBOL_ERROR' | translate}}</p>
                            </ng-container>
                        </div>
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label [ngClass]="{'error-label': fg.firstName.touched && fg.firstName.invalid}" for="">{{
                                'PAGE.SHARED.EDIT_USER_PROFILE.FIRST_NAME' | translate }} <span *ngIf="fg.firstName.invalid" class="required"></span></label>
                            <input type="text" class="form-control" formControlName="firstName"
                                [ngClass]="{'valid-error': fg.firstName.touched && fg.firstName.invalid}">
                            <ng-container *ngIf="fg.firstName.touched && fg.firstName.invalid">
                                <p class="error-label" *ngIf="fg.firstName.errors?.required">{{'PAGE.REQUIRED' |
                                    translate}}</p>
                                <p class="error-label" *ngIf="fg.firstName.errors?.maxlength">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.MAX_CHAR_ERROR' | translate}}</p>
                                <p class="error-label" *ngIf="fg.firstName.errors?.errMsg">
                                    {{fg.firstName.errors?.errMsg}}</p>
                            </ng-container>
                        </div>
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label [ngClass]="{'error-label': fg.lastName.touched && fg.lastName.invalid}" for="">{{
                                'PAGE.SHARED.EDIT_USER_PROFILE.LAST_NAME' | translate }} <span *ngIf="fg.lastName.invalid" class="required"></span></label>
                            <input type="text" class="form-control" formControlName="lastName"
                                [ngClass]="{'valid-error': fg.lastName.touched && fg.lastName.invalid}">
                            <ng-container *ngIf="fg.lastName.touched && fg.lastName.invalid">
                                <p class="error-label" *ngIf="fg.lastName.errors?.required">{{'PAGE.REQUIRED' |
                                    translate}}</p>
                                <p class="error-label" *ngIf="fg.lastName.errors?.maxlength">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.MAX_CHAR_ERROR' | translate}}</p>
                                <p class="error-label" *ngIf="fg.lastName.errors?.errMsg">{{fg.lastName.errors?.errMsg}}
                                </p>
                            </ng-container>
                        </div>
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 name-error">
                            <p class="error-label" *ngIf="(fg.firstName.touched && fg.firstName.invalid) || (fg.lastName.touched && fg.lastName.invalid)">
                                {{'PAGE.SHARED.EDIT_USER_PROFILE.INVALID_USER_FIRST_LAST_NAME_AFTER_REGISTRATION' | translate}}</p>
                        </div>
                        <p class="descr-two col-12">
                            {{ 'PAGE.SHARED.EDIT_USER_PROFILE.PRIVACY_INFO' | translate }}
                        </p>
                        <div
                            class="form-group drop-down-geschl  geschlecht-drop col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="">{{ 'PAGE.SHARED.EDIT_USER_PROFILE.GENDER' | translate }} <span
                            *ngIf="fg.gender.errors?.required" class="required"></span></label>
                            <ng-select [items]="genderData" bindLabel="name" bindValue="value" formControlName="gender"
                                #genderDD class="custom-dropdownnn" (change)="genderDD.blur()">
                            </ng-select>
                            <ng-container *ngIf="fg.gender.touched && fg.gender.invalid">
                                <p class="error-label" *ngIf="fg.gender.errors?.required">{{'PAGE.REQUIRED' |
                                    translate}}</p>
                            </ng-container>

                        </div>
                        <div class="form-group drop-down-geschl col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label class="w-100 d-block" [ngClass]="{'error-label': fg.date.touched && fg.date.invalid}" for="">{{ 'PAGE.SHARED.EDIT_USER_PROFILE.BIRTHDAY' | translate
                                }} <span *ngIf="fg.date.invalid" class="required"></span></label>

                            <input [matDatepicker]="picker" class="visibilty-calender"
                                placeholder="{{ 'PAGE.SHARED.EDIT_USER_PROFILE.BIRTHDAY_PLACEHOLDER' | translate }}"
                                (dateChange)="dateChange($event.value)" [max]="maxDate" [value]="selectedBirthDay">
                            <input class="form-control"
                                placeholder="{{'PAGE.SHARED.EDIT_USER_PROFILE.BIRTHDAY_PLACEHOLDER' | translate}}"
                                formControlName="date" mask="00.00.0000" [dropSpecialCharacters]="false"
                                [validation]="true" [ngClass]="{'valid-error': fg.date.touched && fg.date.invalid}">

                            <!-- <input (click)="picker.open()" [matDatepicker]="picker" class="form-control" readonly  placeholder="TT/MM/JJJJ" formControlName="birthDay" [max]="maxDate"> -->
                            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                            <span class="cursr-pointr" (click)="picker.open()">
                                <svg class="ic_calendar" xmlns="http://www.w3.org/2000/svg" width="14.365" height="15"
                                    viewBox="0 0 14.365 15">
                                    <path id="Combined_Shape" data-name="Combined Shape"
                                        d="M.887,15A.9.9,0,0,1,0,14.1V2.35a.9.9,0,0,1,.887-.9H3.192v-.9a.532.532,0,1,1,1.064,0v.9h6.03v-.9a.532.532,0,1,1,1.064,0v.9h2.128a.9.9,0,0,1,.887.9V14.1a.9.9,0,0,1-.887.9Zm.177-1.084H13.3V6.145H1.064ZM13.3,5.06V2.53H11.35v.9a.532.532,0,1,1-1.064,0v-.9H4.256v.9a.532.532,0,1,1-1.064,0v-.9H1.064V5.06ZM5.32,11.928a.542.542,0,0,1,0-1.084h3.9a.542.542,0,0,1,0,1.084Zm0-2.53a.542.542,0,0,1,0-1.084h3.9a.542.542,0,0,1,0,1.084Z"
                                        fill="#326291" />
                                </svg>
                            </span>
                            <ng-container *ngIf="fg.date.touched && fg.date.invalid">
                                <p class="error-label"  *ngIf="fg.date.errors?.required">{{'PAGE.REQUIRED' |
                                    translate}}</p>
                                <p class="error-label" *ngIf="fg.date.errors?.wrongDate">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.INVALID_DATE' | translate}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-sm-3 order-1 order-sm-2 h-100 margin-mb">
                    <div class="user-profile-edit">
                        <img [src]="imageName" alt="FairDogs_Hundehalter">
                        <!-- <input #fileInput (change)="onPhotoChange($event)" type="file" accept="image/x-png,image/bmp,image/jpeg" class="cover-image-input d-none"> -->
                        <div class="user-edit-camera" (click)="onEditProfileImage()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
                                <path id="Union_2" data-name="Union 2"
                                    d="M2.75,20A2.805,2.805,0,0,1,0,17.143V5.714A2.805,2.805,0,0,1,2.75,2.857H4.813L5.5,1.429C5.865.681,6.115,0,6.875,0h8.25c.76,0,.97.6,1.375,1.429l.688,1.429H19.25A2.805,2.805,0,0,1,22,5.714V17.143A2.805,2.805,0,0,1,19.25,20ZM5.5,11.429A5.611,5.611,0,0,0,11,17.143a5.61,5.61,0,0,0,5.5-5.714A5.611,5.611,0,0,0,11,5.714,5.611,5.611,0,0,0,5.5,11.429Zm1.375,0A4.207,4.207,0,0,1,11,7.143a4.207,4.207,0,0,1,4.125,4.286A4.207,4.207,0,0,1,11,15.714,4.207,4.207,0,0,1,6.875,11.429Z"
                                    fill="#333" />
                            </svg>
                        </div>

                    </div>
                </div>
                <div class="col-12 order-3 order-sm-3">
                    <div class="form-row">
                        <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0">
                            <label class="label-address" for="">{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS' |
                                translate}} <span *ngIf="!fg.address.value" class="required"></span></label>
                            <p class="descr-three">{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_DESC' | translate}}</p>
                        </div>
                        <div class="form-group drop-down-geschl col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                            <!-- <input type="text" class="form-control input_location mb-1" placeholder="Strasse und Stadt eingeben"> -->
                            <!-- <input #addressInput type="text" id="address" formControlName="address"
                                class="form-control input_location mb-1" placeholder="{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_PLACEHOLDER' | translate}}" floating-label> -->
                            <input type="text" #addressInput
                                placeholder="{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_PLACEHOLDER' | translate}}"
                                class="form-control input_location mb-1 pr-5 text-truncate" formControlName="address"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
                                <mat-option class="d-flex align-items-center location-dd-wrap"
                                    *ngFor="let option of placeOptions" [value]="option.description"
                                    (onSelectionChange)="placeSelect(option.place_id)">
                                    <span class="d-inline-block mr-2 location-dd"><img
                                            src="assets/img/ic_gray-loaction.svg"
                                            alt="FairDogs_Location"></span>{{option.description}}
                                </mat-option>
                            </mat-autocomplete>
                            <svg (click)="addressPopup()" style="top: 11px;" class="ic_locationmap" id="ic_location"
                                xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                                <path id="Shape"
                                    d="M7.5,20a.686.686,0,0,1-.5-.216,44.506,44.506,0,0,1-3.606-4.527C1.141,12,0,9.307,0,7.243A7.383,7.383,0,0,1,7.5,0,7.384,7.384,0,0,1,15,7.243c0,2.064-1.141,4.76-3.392,8.013A44.506,44.506,0,0,1,8,19.784.688.688,0,0,1,7.5,20Zm0-16.4A3.714,3.714,0,0,0,3.727,7.243,3.714,3.714,0,0,0,7.5,10.887a3.715,3.715,0,0,0,3.774-3.644A3.715,3.715,0,0,0,7.5,3.6Z"
                                    fill="#326291" />
                            </svg>
                            <!-- <p class="error-label fs-14">Unbekannte Adresse: Pinne deinen Standort</p> -->
                            <a class="goglemap-hyper-link"
                                (click)="addressPopup()">{{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_PIN' | translate}}</a>
                            <ng-container *ngIf="fg.address.dirty">
                                <p class="error-label" *ngIf="fg.address.value && notFoundAddress">
                                    {{'PAGE.SHARED.EDIT_USER_PROFILE.ADDRESS_NOT_FOUND' | translate}}</p>
                            </ng-container>
                            <p class="error-label" *ngIf="fg.address.value && !notFoundAddress && !isSameCountry">
                                {{'PAGE.SHARED.EDIT_USER_PROFILE.COUNTRY_DIFFERENT' | translate}}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div
                            class="form-group col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 deine-sprachen-multi-dropdwn">
                            <div class="drop-down-geschl pr-0">
                                <label for=""> {{ 'PAGE.SHARED.EDIT_USER_PROFILE.COUNTRY' | translate }}</label>
                                <ng-select [items]="countriesData" bindLabel="name" bindValue="code" placeholder="land"
                                    formControlName="country" #countryDD
                                    (change)="countryChange($event);countryDD.blur()" class="custom-dropdownn">
                                </ng-select>

                                <!-- <p class="error-label fs-14 ">Bitte vervollständige die markierten Felder.</p> -->
                            </div>

                            <!-- <div class="sprachen-dropdown" formArrayName="languagesKnown">
                                   <div class="form-group pl-0 mt-4 mb-0">
                                    <label for="">Deine Sprachen</label>
                                   </div>
                                    <ng-container *ngFor="let language of languagesKnown.controls;let i =index">
                                        <div class="mb-4 position-relative col-md-6 px-0">
                                            
                                                <input type="text" class="form-control" formControlName="{{i}}">
                                                <span class="btn-close" (click)="removeLanguage(i)"><img src="assets/img/close-btn.svg" alt=""></span>
                                        </div>
                                    </ng-container>
                                    <button class="btn-blue-outline" (click)="addNewlanguage()">
                                        + Sprache hinzufügen
                                    </button>
                                </div> -->

                        </div>
                        <div
                            class="form-group drop-down-geschl  geschlecht-drop col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="">Spracheinstellung</label>
                            <ng-select [items]="languageData" bindLabel="name" bindValue="value"
                                formControlName="preferLanguage" #languageDD (change)="languageDD.blur()"
                                class="custom-dropdownn">
                            </ng-select>

                        </div>
                        <div class="col-12 text-center">
                            <button class="btn-fill-blue"
                                [ngClass]="{'disabled' : formGroup.invalid || (fg.address.value && (notFoundAddress || !isSameCountry))}"
                                (click)="onSubmit()">Änderungen speichern</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>