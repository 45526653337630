import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AnswerModel } from 'app/person-test/models/answer.model';
import { IncrementPersonTestPage, SavePersonTestAnswers } from 'app/person-test/store/person-test.actions';
import { PersonTestState } from 'app/person-test/store/person-test.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import testQuestions from '../../shared/personpersonalitytestquestions';
import { Constants, peoplepersonalitytestquestions } from 'app/contants';

@Component({
  selector: 'app-person-test-page-four',
  templateUrl: './person-test-page-four.component.html',
  styleUrls: ['./person-test-page-four.component.scss']
})
export class PersonTestPageFourComponent implements OnInit {

  question8?= peoplepersonalitytestquestions.find(el => el.questionId === 8);
  question9?= peoplepersonalitytestquestions.find(el => el.questionId === 9);
  question10?= peoplepersonalitytestquestions.find(el => el.questionId === 10);
  question11?= peoplepersonalitytestquestions.find(el => el.questionId === 11);
  question12?= peoplepersonalitytestquestions.find(el => el.questionId === 12);

  questions = [
    { ...this.question8, result: 0 },
    { ...this.question9, result: 0 },
    { ...this.question10, result: 0 },
    { ...this.question11, result: 0 },
    { ...this.question12, result: 0 },
  ];

  @Select(PersonTestState.answers) answersState$!: Observable<AnswerModel[]>;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.answersState$.pipe(
      tap(data => {
        this.questions.forEach(element => {
          const find = data.find(el => el.questionId === element.questionId);
          if (find) {
            element.result = find?.result;
          }
        });
      })
    ).subscribe();
  }

  saveResults(form: any): void {
    const answers: AnswerModel[] = [
      { questionId: 8, type: this.question8?.type, result: form.questionId8 },
      { questionId: 9, type: this.question9?.type, result: form.questionId9 },
      { questionId: 10, type: this.question10?.type, result: form.questionId10 },
      { questionId: 11, type: this.question11?.type, result: form.questionId11 },
      { questionId: 12, type: this.question12?.type, result: form.questionId12 },
    ];
    this.store.dispatch(new SavePersonTestAnswers(answers));
  }

  saveAndNext(form: any): void {
    this.saveResults(form);
    this.store.dispatch(new IncrementPersonTestPage(1));
  }

  back(form: any): void {
    this.saveResults(form);
    this.store.dispatch(new IncrementPersonTestPage(-1));
  }

}
