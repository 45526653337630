import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserModel } from '@core/models/user.model';
import { Select, Store } from '@ngxs/store';
import { AuthService } from 'app/auth/services/auth.service';
import { SubscriptionParentComponent } from 'app/profile/shared/components/subscription/subscription-parent/subscription-parent.component';
import { LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { UserState } from 'app/profile/shared/store/user/user.state';
import { IncrementPersonTestPage } from 'app/person-test/store/person-test.actions';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-people-test-info-popup',
  templateUrl: './people-test-info-popup.component.html',
  styleUrls: ['./people-test-info-popup.component.scss']
})
export class PeopleTestInfoPopupComponent implements OnInit {

  isAuthenticated = false;
  @Select(UserState.user) user$ !: Observable<UserModel>;
  currentUser: any;
  @Output() paymentPopupToggle = new EventEmitter();
  constructor(
    private store: Store,
    private router: Router,
    public authService: AuthService,
    protected dialog: MatDialog,
  ) {

    this.user$.subscribe((user: UserModel) => {
      if (user) {
        this.currentUser = user;
      }
    });

  }

  ngOnInit(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  saveAndNext(): void {
    this.store.dispatch(new IncrementPersonTestPage(1));
  }

  back(): void {
    this.store.dispatch(new IncrementPersonTestPage(-1));
  }


  /**
   * check already paid for test or not
   */
  managePersonTestPaymentCheck() {
    if (this.currentUser?.purchasedAllInclusive == true) {
      this.saveAndNext();
    } else {
      this.dialog.closeAll()
      this.router.navigate([`/membership`]);
      // this.paymentPopupToggle.emit(true);
      // this.showPeoplePersonalityPaymentPopup();
    }
  }

  /**
   * people personality test payment popup
   */
  // showPeoplePersonalityPaymentPopup() {
  //   this.dialog.open(SubscriptionParentComponent, {
  //     panelClass: 'subscription-parent-dialog',
  //     disableClose: true,
  //     data: {
  //       type: 'personCare',
  //       subType: 'personPersonalityTest',
  //     }
  //   }).afterClosed()
  //     .subscribe(response => {
  //       if (response?.resp === 'success') {
  //         this.updateUserData();
  //         this.paymentPopupToggle.emit(false);
  //         this.saveAndNext();
  //       } else {
  //         this.paymentPopupToggle.emit(false);
  //       }
  //     }
  //     )
  // }


  /**
   * update user data after purchase credit
   */
  updateUserData() {
    this.store.dispatch(new LoadUserAction());
  }

}
