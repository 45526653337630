import { RegisterEmailModel } from 'app/auth/models/register-email.model';
import { AnswerModel } from '../models/answer.model';

export class SavePersonTestAnswers {
    static readonly type = '[PersonTest] Save Person Test Answers';
    constructor(public answers: AnswerModel[]) { }
}

export class IncrementPersonTestPage {
    static readonly type = '[PersonTest] Increment Person Test Page';
    constructor(public increment: number) { }
}

export class SetPersonTestPage {
    static readonly type = '[PersonTest] Set Person Test Page';
    constructor(public payload: number) { }
}

export class SavePersonTest {
    static readonly type = '[PersonTest] Save Person Test';
    constructor() { }
}

export class SavePersonTestAndRegister {
    static readonly type = '[PersonTest] Save Person Test And Register';
    constructor(public model: RegisterEmailModel) { }
}

export class AddTestPerson {
    static readonly type = '[PersonTest] Add Test Person';
    constructor(public payload: any) { }
}

export class ALLBreedDogAction {
    static readonly type = '[PersonTest] All Dog Breed';
    constructor() { }
}

export class AddRegister {
    static readonly type = '[PersonTest] Add Register';
    constructor(public payload: any) { }
}

export class SetRepeatTestId {
    static readonly type = '[PersonTest] Set Repeat Test Id';
    constructor() { }
}

export class RepeatTest {
    static readonly type = '[PersonTest] Repeat Test';
    constructor() { }
}

export class SetFreeTestFlag {
    static readonly type = '[PersonTest] Set Free Test Flag';
    constructor(public model: boolean) { }
}
export class SetRepeatTestIdNull {
    static readonly type = '[PersonTest] Set Repeat Test Id Null';
    constructor() { }
}
