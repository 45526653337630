import { AddImageGallaryModel } from '@core/models/add-image-gallary.model';
import { EditImageGallaryModel } from '@core/models/edit-image-gallary.model';

export class GetImageGallaryAction {
    static readonly type = '[ImageGallary] Get Images';
    constructor(public model: string) { }
}

export class AddImageGallaryAction {
    static readonly type = '[ImageGallary] Add Image';
    constructor(public model: AddImageGallaryModel) { }
}
export class UpdateImageGallaryAction {
    static readonly type = '[ImageGallary] Update Image';
    constructor(public model: EditImageGallaryModel) { }
}
export class DeleteImageGallaryAction {
    static readonly type = '[ImageGallary] Delete Image';
    constructor(public model: string) { }
}
