import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserModel } from '@core/models/user.model';
import { SpinnerService } from '@core/services/spinner.service';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Select, Store } from '@ngxs/store';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { ProductService } from '../../../services/product.service';
import { GetSubscriptionPlanByType, PurchasePlanUsingGoodies } from '../../../store/product/product.action';
import { ProductState } from '../../../store/product/product.state';
import { LoadUserAction } from '../../../store/user/user.actions';
import { UserState } from '../../../store/user/user.state';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {

  @Input() subscriptionTypeData: any;
  @Output() onActionChange = new EventEmitter();
  @Output() onSubmitResponse = new EventEmitter();
  public frontEndDomain = environment.frontEndDomain;

  subscriptionPlanList: Array<any> = [];
  totalSelectedCredit: number = 0;
  subscriptionDiscount: number = 0;
  discountAmount: number = 0;
  currentUser: any;
  checkboxChecked: Boolean = false;
  @Select(UserState.user) user$ !: Observable<UserModel>;
  @Select(UserState.subscriptionDiscount) subDiscount$ !: Observable<UserModel>;
  planIds: Array<any> = [];
  selectedIndexForshowDetail = 0;
  showProductSection: Boolean = false;

  constructor(
    private store: Store,
    public spinner: SpinnerService,
    public productService: ProductService,
    public toastr: ToastrNotificationService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {

    /**
     * user data get
     */
    this.user$.subscribe((user: UserModel) => {
      if (user) {
        this.currentUser = user;
      }
    });

    /**
     * API for get subscription plan 
     */
    this.store.dispatch(new GetSubscriptionPlanByType({ type: this.subscriptionTypeData?.type, subType: this.subscriptionTypeData?.subType })).subscribe(
      () => {
        this.getSubscriptionPlan();
      },
      () => { }
    );
    this.getSubscriptionDiscount();
  }

  /**
   * get subscription plan from store
   */
  getSubscriptionPlan() {
    const data: any = this.store.selectSnapshot(ProductState.getSubscriptionPlanByType);
    this.subscriptionPlanList = data;

    this.subscriptionPlanList = this.subscriptionPlanList.map(plan => {  // .map
      return {
        ...plan,
        isHidden: true
      }
    });

    if ((this.subscriptionTypeData?.type === 'dogCare' || this.subscriptionTypeData?.type === 'personCare') && this.subscriptionTypeData?.subType === '' && this.subscriptionTypeData?.mode === 'edit') { // dog sharing edit mode
      this.manageDogSharingEditModeOnLoad();
    } else if ((this.subscriptionTypeData?.type === 'dogCare' || this.subscriptionTypeData?.type === 'personCare') && this.subscriptionTypeData?.subType === '' && this.subscriptionTypeData?.mode === 'add') {// dog sharing add mode
      this.planIds = this.subscriptionTypeData.selectedIds;
      for (const plan of this.subscriptionPlanList) { // make total of new selected plan
        if (this.subscriptionTypeData.selectedIds.indexOf(plan?._id) !== -1) {
          this.totalSelectedCredit += plan?.credits;
        }
      }
      this.manageDiscountAmount(); // use when publish add with payment it calculate discount
    }

    // only for people personality test payment popup for show default selected

    if (this.subscriptionTypeData?.type === 'personCare' && this.subscriptionTypeData?.subType === 'personPersonalityTest') {
      this.manageSelectedPlanTotal({ checked: true }, this.subscriptionPlanList[0]?.credits, this.subscriptionPlanList[0]);
    }
    this.showProductSection = true;
    this.spinner.hide();
  }

  /**
   * below code is for dog sharing edit mode
   */
  manageDogSharingEditModeOnLoad() {
    this.planIds = this.subscriptionTypeData.selectedIds;
    let newSelectedIds = [];
    for (const id of this.subscriptionTypeData.selectedIds) { // find new selected ids
      if (this.subscriptionTypeData?.existingIds.indexOf(id) == -1) {
        newSelectedIds.push(id);
      }
    }

    for (const plan of this.subscriptionPlanList) { // make total of new selected plan
      if (newSelectedIds.indexOf(plan?._id) !== -1) {
        this.totalSelectedCredit += plan?.credits;
      }
    }

    this.manageDiscountAmount();
  }

  /***
   * Manage credit total which selected
   */
  manageSelectedPlanTotal(event: any, credit: number, plan: any) {
    // if(this.subscriptionTypeData?.subType != 'dogShareMatchingResult'){
      if (event?.checked) {
        if ((this.subscriptionTypeData?.type === 'dogCare' || this.subscriptionTypeData?.type === 'personCare') && this.subscriptionTypeData?.subType === '' && this.subscriptionTypeData?.mode === 'edit') {
          if (this.subscriptionTypeData?.existingIds.indexOf(plan?._id) == -1) {
            this.totalSelectedCredit += credit;
          }
        } else {
          this.totalSelectedCredit += credit;
        }
        this.planIds.push(plan?._id);
      } else {
        if ((this.subscriptionTypeData?.type === 'dogCare' || this.subscriptionTypeData?.type === 'personCare') && this.subscriptionTypeData?.subType === '' && this.subscriptionTypeData?.mode === 'edit') {
          if (this.subscriptionTypeData?.existingIds.indexOf(plan?._id) == -1) {
            this.totalSelectedCredit -= credit;
          }
        } else {
          this.totalSelectedCredit -= credit;
        }
  
        let planIdIndex = this.planIds.findIndex((id: string) => id === plan?._id);
        this.planIds.splice(planIdIndex, 1);
      }
    // }
    // else{
    //   this.planIds.push(plan?._id);
    // }

    this.manageDiscountAmount();
  }

  /**
   * subscription discount
   */
  getSubscriptionDiscount() {
    this.subDiscount$.subscribe((discount: any) => {
      if (discount) {
        this.subscriptionDiscount = discount?.discount;
        this.manageDiscountAmount();
      }
    });
  }

  /**
   * calculate discount amount
   */
  manageDiscountAmount() {
    if (this.subscriptionDiscount > 0) {
      this.discountAmount = (this.totalSelectedCredit * this.subscriptionDiscount) / 100;
    }
  }

  /**
   * purchase plan using its credit
   */
  purchasePlanUsingGoodies() {
    let data = {};
    if ((this.subscriptionTypeData?.type === 'dogCare' || this.subscriptionTypeData?.type === 'personCare') && this.subscriptionTypeData?.subType === 'Kontaktaufnahme') {
      data = { products: this.planIds, data: { user: this.subscriptionTypeData?.userId } };

    } else if ((this.subscriptionTypeData?.type === 'dogCare' || this.subscriptionTypeData?.type === 'personCare') && this.subscriptionTypeData?.subType === '') {
      data = {
        products: this.planIds, 
        _id: this.subscriptionTypeData?.dogOrPersonId, 
        type: this.subscriptionTypeData?.type, 
        subType: this.subscriptionTypeData?.subType 
      };

    } else {
      data = { products: this.planIds };
    }

    if (this.subscriptionTypeData?.aditionalData) {
      data = { ...data, ...this.subscriptionTypeData?.aditionalData }
    }

    if(this.subscriptionTypeData?.subType != 'dogShareMatchingResult'){
      this.store.dispatch(new PurchasePlanUsingGoodies(data)).subscribe(
        () => {
          this.store.dispatch(new LoadUserAction());
          this.onSubmitResponse.emit({ resp: 'success', productSelectedIds: this.planIds, type: this.subscriptionTypeData?.type, subType: this.subscriptionTypeData?.subType });
        },
        () => {
          this.onSubmitResponse.emit('failed');
        }
      );
    }
    else{
      this.productService.purchaseFreeMatching(data).subscribe( (res : any) => {
        if(res['success']){
          this.toastr.showSuccess('', res?.message || '');
          // this.store.dispatch(new LoadUserAction());
          this.onSubmitResponse.emit({ resp: 'success', productSelectedIds: this.planIds, type: this.subscriptionTypeData?.type, subType: this.subscriptionTypeData?.subType });
        }        
      },(error : any) => {
        this.toastr.showHttpError(error);
        return throwError(error);
      })
    }
  }

}
