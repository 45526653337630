import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from 'app/auth/services/auth.service';
import { LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { PersonTestComponent } from 'app/person-test/person-test.component';
import { SetPersonTestPage } from 'app/person-test/store/person-test.actions';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { PersonTestService } from '../services/person-test.service';

@Component({
  template: ''
})
// tslint:disable-next-line: component-class-suffix
export class EntryDialogPerson {
  public dialogRef!: MatDialogRef<any, any>;
  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private personTestService: PersonTestService
  ) {
    authService.isAuthenticated() ? this.store.dispatch(new SetPersonTestPage(1)) : this.store.dispatch(new SetPersonTestPage(1));
    this.store.dispatch(new LoadUserAction()).subscribe(
      () => {
        this.openDialog();
      },
      () => { },
    );
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(PersonTestComponent, {
      width: '90%',
      height: 'auto',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: ['dialog-test-container', 'dialog-test-border-radius'], // css klasa sa stylingom,
      data: {
        showUI: true
      }
    });

    this.resizeEvent();

    const route = '../';
    // this.dialogRef.afterClosed().subscribe(result => {
    //     this.router.navigate([route], { relativeTo: this.route });
    //     // this.resizeSubscription$.unsubscribe();
    //   });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (!this.personTestService.getRedirectToResultFlag()) {
        this.router.navigate([route], { relativeTo: this.route });
      } else {
        this.personTestService.setRedirectToResultFlag(false);
      }
      // this.resizeSubscription$.unsubscribe();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  resizeEvent(): void {
    this.checkSize();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.checkSize();
    });
  }

  checkSize = () => {
    if (window.innerWidth < 1000) {
      this.dialogRef.updateSize('100vw', '100vh');
      this.dialogRef.addPanelClass('full-width-dialog');
    } else {
      this.dialogRef.updateSize('90%', 'auto');
      this.dialogRef.removePanelClass('full-width-dialog');
    }
  }

}
