import { Inject, Injectable } from '@angular/core';
// tslint:disable-next-line: ban-types
declare let gtag: Function;
@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    public emmitEventOfGATracking(
        eventName: string,
        eventCategory: string,
        eventAction: string,
        eventLabel: string = '',
        eventValue: number = 0): void {
        gtag('event', eventName, {
            eventCategory,
            eventLabel,
            eventAction,
            eventValue
        });
    }
}
