import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-last-ad-delete-conf-popup',
  templateUrl: './last-ad-delete-conf-popup.component.html',
  styleUrls: ['./last-ad-delete-conf-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LastAdDeleteConfPopupComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<LastAdDeleteConfPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { }

  ngOnInit(): void {
  }

}
