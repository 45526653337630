import * as Sentry from "@sentry/angular";
import { NgxsModule } from '@ngxs/store';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { Router } from "@angular/router";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCoreModule } from '@core/app-core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'environments/environment';
import { WebpackTranslateLoader } from './app.translate.loader';
import { AuthTokenInterceptor } from '@core/interceptor/auth-token.interceptor';
import { CookieModule } from 'ngx-cookie';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { SEOGlobalData } from './SEO-global/seo-global-data';
import { Constants } from './contants';
import { PersonTestModule } from './person-test/person-test.module';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { SentryErrorLogger } from "@core/services/sentry-logger.service";
// import { IvyCarouselModule } from 'angular-responsive-carousel';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    AppRoutingModule,
    AppCoreModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    HttpClientModule,
    PersonTestModule,
    LazyLoadImageModule
  ],
  providers: [
    {
      provide: SentryErrorLogger,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    GoogleAnalyticsService,
    SEOGlobalData,
    Constants
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
