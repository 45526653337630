import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Constants } from './contants';
// tslint:disable-next-line: ban-types
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  static isBrowser = new BehaviorSubject<any>(null);
  isBrowserForBlink!: boolean; // prevent blinking because of ssr
  title = 'fair-dogs-web';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    public router: Router,
    private toastr: ToastrNotificationService,
    public _constants: Constants
  ) {
    this.isBrowserForBlink = isPlatformBrowser(this.platformId); // prevent blinking because of ssr

    this._constants.getDogPersonalityQuestions(); // get dog personality questions from JSON
    this._constants.getPeoplePersonalityQuestions(); // get people personality questions from JSON
    this._constants.getDogPersonalityGraphText(); // get dog personality test graph text
    this._constants.getPeoplePersonalityGraphText(); // get people personality test graph text

    // Smartlook Script Tag Integration
    // if (environment.allowSmartlook) {
    //   // Smartlook script tag add
    //   const smartLookScript = `window.smartlook||(function(d) {
    //     var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    //     var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    //     c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
    //     })(document);
    //     smartlook('init', '55b61755cf05db8e94992b45634f2566592a2961')`;
    //   const documentBody = document.body;
    //   const scriptTag = document.createElement('script');
    //   scriptTag.type = 'text/javascript';
    //   scriptTag.id = 'smartlook_script';
    //   scriptTag.text = smartLookScript;
    //   documentBody.appendChild(scriptTag);
    // }

  }

  ngOnInit(): void {
    this.createOnline$().
      pipe(
        debounceTime(500)).
      subscribe((isOnline: boolean) => {
        if (!isOnline) {
          this.toastr.showError('', 'Please check your connection');
        }
      });
  }
  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // gtag({
        //   page_path: event.urlAfterRedirects
        // });
      }
    });
  }

  ngOnDestroy(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const element = document.querySelector('#app-row-header') as HTMLElement;
    if (element && window.pageYOffset + 0 > element.clientHeight) {
      document.getElementById('app-row-header')?.classList.add('sticky-global');
    } else {
      document.getElementById('app-row-header')?.classList.remove('sticky-global');
    }
  }

  createOnline$(): Observable<boolean> {
    return merge<any>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
